import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAdmins, sendAdminInviteEmail } from "../../apis/v1/UserManagement";
import AdminsListTable from "../../components/Admins/v1/AdminsListTable";
import { User } from "../../interfaces/User";
import { showSnackbar } from "../../utilis/snackbar";

export default function AdminsList() {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState<User[]>([]);
  useEffect(() => {
    getAdmins().then((res) => res && setAdmins(res.data));
  }, []);

  const resendInviteEmail = (id) => {
    sendAdminInviteEmail(id).then((res) => {
      if (res) {
        showSnackbar("Email sent successfully", "success");
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Admins List"
        subheader="A list of admins which can use this dashboard"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() => navigate("/admin/add")}
          data-testid="addAdminBtn"
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <AdminsListTable
          admins={admins}
          setAdmins={setAdmins}
          resendInviteEmail={resendInviteEmail}
        />
      </CardContent>
    </Card>
  );
}
