import React from "react";
import { Box, CircularProgress } from "@mui/material";
import logoURL from "../../assets/images/Ocucon_Black_Logo.svg";

interface LoaderProps {
  content: string;
  height?: string;
  showLogo?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ content, height, showLogo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      data-testid="loading-container"
      height={height ? height : "100vh"}
    >
      <Box textAlign="center">
        <CircularProgress />
        {showLogo && (
          <Box
            src={logoURL}
            component="img"
            alt="Ocucon Logo"
            sx={{
              height: "35px",
              display: "block",
              marginTop: "1em",
            }}
          />
        )}
        <p>{content}</p>
      </Box>
    </Box>
  );
};

export default Loader;
