import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MUITable from "../../Table";
import DeleteAdmin from "./DeleteAdmin";
import { User } from "../../../interfaces/User";
import EmailIcon from "@mui/icons-material/Email";

interface AdminsListTableProps {
  admins: User[];
  setAdmins: React.Dispatch<User[]>;
  resendInviteEmail: (id: string) => void;
}

export default function AdminsListTable({
  admins,
  setAdmins,
  resendInviteEmail,
}: AdminsListTableProps) {
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "username",
          name: "Username",
        },
        {
          id: "email",
          name: "Email",
        },
        {
          id: "emailVerified",
          name: "Verified email",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={admins}
      tableBodyRow={(row: any) => (
        <TableRow key={row.id} data-testid="admin-row">
          <TableCell>{row.username}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell data-testid="emailVerified">
            {row.emailVerified ? "Yes" : "No"}
          </TableCell>
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {row.emailVerified === false && (
              <Tooltip title="Resend invite email" placement="top">
                <IconButton
                  data-testid="resend_email"
                  size="small"
                  onClick={() => resendInviteEmail(row.id)}
                  sx={{ mr: 0.5 }}
                >
                  <EmailIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <DeleteAdmin id={row.id} admins={admins} setAdmins={setAdmins} />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
