import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import AddGlobalIncidentClassForm from "../../components/Labels/AddGlobalIncidentClassForm";
import { addGlobalIncidentClass } from "../../apis/v1/DetectionZone/GlobalIncidentClasses";
import { GIncidentClass } from "../../interfaces/IncidentClass";

export default function AddGlobalIncidentClass() {
  const navigate = useNavigate();

  const handleSubmitForm = (values: Omit<GIncidentClass, "uuid">) => {
    addGlobalIncidentClass(values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(`/global-incident-classes`);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Global Incident Class" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddGlobalIncidentClassForm handleSubmitForm={handleSubmitForm} />
        </Grid>
      </Grid>
    </>
  );
}
