/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import PagesHeader from "../../components/PagesHeader";
import { createSite } from "../../apis/v1/Sites";
import AddSiteForm from "../../components/Sites/v1/AddSiteForm";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useEffect, useState } from "react";
import { getCountries } from "../../apis/v1/Countries";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { getChildren, resetList } from "../../utilis/format-child-nodes";
import { Country } from "../../interfaces/Address";
import { OrgStructureNode } from "../../interfaces/OrgStructure";

export default function AddSite() {
  const navigate = useNavigate();
  const params = useParams();
  const [countries, setCountries] = useState<Array<Country>>();
  const [parentNodes, setParentNodes] = useState<OrgStructureNode[]>();

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res.data);
    });

    resetList();
    getCompanyRootNodes(params.company_uuid, params.node_uuid).then((res) => {
      const nodes = getChildren(res.data);
      setParentNodes(nodes);
    });

    return () => {
      resetList();
    };
  }, []);

  const handleSubmitSite = (values) => {
    createSite(params.company_uuid, {
      ...values,
      phones: [
        {
          number: values.phone,
          country_code: values.country_code,
        },
      ],
      address: {
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        postcode: values.postcode,
        county: values.county,
        country_uuid: values.country,
      },
    }).then((res) => {
      if (res) {
        showSnackbar("Site added successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Site" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddSiteForm
            parentNodes={parentNodes}
            countries={countries}
            handleSubmitSite={handleSubmitSite}
          />
        </Grid>
      </Grid>
    </>
  );
}
