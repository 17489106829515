import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import ListTable from "../../../components/Settings/v1/CameraDefaultSettings/ListTable";
import { getCameraDefaultSettings } from "../../../apis/v1/Settings/CameraDefaults";

export default function DefaultCameraSettingsList() {
  const [defaultSettings, setDefaultSettings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCameraDefaultSettings().then((res) => {
      if (res) {
        setDefaultSettings(res.data);
      }
    });
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Camera Default Settings"
        subheader="List of camera default settings"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() => navigate("/settings/camera/default-settings/add")}
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <ListTable
          defaultSettings={defaultSettings}
          setDefaultSettings={setDefaultSettings}
        />
      </CardContent>
    </Card>
  );
}
