export default function AlertOverrides() {
  return {
    MuiAlert: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "white",
          // shared Alert CSS
          "& .MuiAlert-message": {
            color: "white",
            fontWeight: "600",
          },
          "& .MuiAlert-filledError": {
            backgroundColor: "#f94b3f !important",
          },
        },
      },
    },
  };
}
