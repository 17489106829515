import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getURLParams } from "../utilis/shared";
import { tokenExchange } from "../apis/v1/Auth";
import { useAppDispatch, useAppSelector } from "../hooks/useStoreHook";
import { isUserAuthenticated } from "../store/slices/authSlice";

export default function TokenExchange() {
  const isAuth = useAppSelector((state) => state.auth.isLoggedIn);
  const queryParams = window.location.search;
  const userState = getURLParams(queryParams, "state");
  const userCode = getURLParams(queryParams, "code");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    tokenExchange(userCode, userState).then((response) => {
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      dispatch(isUserAuthenticated(true));
    });
  }, [dispatch, userCode, userState]);

  useEffect(() => {
    isAuth && navigate("/companies");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return <Loader content="Loading" showLogo={true}/>;
}
