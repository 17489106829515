import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";

export default function MUISelect({
  error,
  value,
  label,
  labelId,
  id,
  name,
  handleChange,
  handleBlur,
  keyItem,
  keyValue,
  list,
  required,
  styles,
  dataTestid,
  disabled,
  renderValue,
  keyAppend,
  keyPreAppend,
  margin,
  setFieldValue,
}: any) {
  const handleClearClick = () => {
    setFieldValue(name, null);
  };
  return (
    <FormControl
      size="small"
      disabled={disabled}
      fullWidth
      error={Boolean(error)}
      margin={margin}
      sx={styles}
    >
      <InputLabel id={labelId}>{`${label}${required ? "*" : ""}`}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={`${label}${required ? "*" : ""}`}
        fullWidth
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || ""}
        data-testid={dataTestid}
        size="small"
        endAdornment={
          setFieldValue ? (
            <IconButton
              sx={{ display: value ? "flex" : "none", mx: 1 }}
              onClick={handleClearClick}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
        renderValue={renderValue}
      >
        {list?.map((item: any) => {
          if (keyItem && keyValue) {
            return (
              <MenuItem key={item[keyItem]} value={item[keyItem]}>
                {keyPreAppend ? item[keyPreAppend] : ""} {item[keyValue]}{" "}
                {keyAppend ? item[keyAppend] : ""}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          }
        })}
        {list?.length <= 0 && <Box textAlign="center">No options</Box>}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
