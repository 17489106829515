import { Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";

export default function CompanyFilters({ setFilters }) {
  const [showReset, setShowReset] = useState<boolean>(false);

  const formInitialValues = {
    account_number: "",
  };

  const schema = yup.object().shape({
    account_number: yup
      .number()
      .typeError("Account number must be a number")
      .required("Account number is required"),
  });

  const handleFilter = async (values, { setTouched, validateForm }) => {
    setTouched({
      account_number: true,
    });

    const errors = await validateForm();

    if (!Object.keys(errors).length) {
      setShowReset(true);
      setFilters({
        account_number: values.account_number,
      });
    }
  };

  const handleReset = (resetForm) => {
    resetForm();
    setFilters({
      account_number: "",
    });
    setShowReset(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={schema}
      onSubmit={(values, actions) => handleFilter(values, actions)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        setTouched,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Account number"
                id="account_number"
                name="account_number"
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                margin="normal"
                value={values.account_number || ""}
                type="number"
                error={Boolean(errors.account_number && touched.account_number)}
                helperText={
                  errors.account_number &&
                  touched.account_number &&
                  errors.account_number
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid
              display={"flex"}
              gap={2}
              alignItems={"center"}
              justifyContent={"end"}
              item
              xs={12}
              sm={3}
            >
              <Button variant="outlined" type="submit">
                Filter
              </Button>
              {showReset && (
                <Button onClick={() => handleReset(resetForm)}>
                  Reset
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
