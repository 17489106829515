import { AxiosPromise } from "axios";
import { axiosInstance } from "../config/axiosInstance";
import {
  PermissionPayload,
  PermissionsResponse,
} from "../../interfaces/Permissions";

const paymentBaseURL = `/permission-management/v1/permissions`;

export const getPermissions = (
  user_uuid?: string,
): AxiosPromise<PermissionsResponse[]> => {
  return axiosInstance.get(`${paymentBaseURL}`, {
    params: {
      ...(user_uuid && { user_uuid }),
    },
  });
};

export const updateUserPermission = (
  data: PermissionPayload,
): AxiosPromise<void> => {
  return axiosInstance.post(`${paymentBaseURL}/update`, data);
};