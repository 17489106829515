/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { getTierDetails } from "../../apis/v1/Services/Tiers";
import TierDetailsCard from "../../components/Tiers/TierDetailsCard";
import { getCompanies } from "../../apis/v1/Companies";

export default function TierDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [tier, setTier] = useState<any>();
  const [companies, setCompanies] = useState<any>();


  useEffect(() => {
    getCompanies().then((res) => setCompanies(res.data))
    getTierDetails(params.service_uuid, params.tier_uuid).then((res) =>
      setTier(res.data),
    );
  }, []);

  return (
    <>
      {tier && (
        <Card variant="outlined">
          <CardHeader
            title="Tier Details"
            subheader="Details about this tier"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/tier/${params.tier_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <TierDetailsCard tier={tier} companies={companies}/>
          </CardContent>
        </Card>
      )}
    </>
  );
}
