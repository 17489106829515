import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { hideSnackbar } from "../../utilis/snackbar";
import { useAppSelector } from "../../hooks/useStoreHook";
import { AlertColor } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return (
      <MuiAlert
        data-testid="alert"
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  },
);

interface SnackProps {
  isOpen: boolean;
  message: string;
  type: AlertColor;
}

export default function MUISnackbar() {
  const snackbar = useAppSelector<SnackProps>((state) => state.alert);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    hideSnackbar(snackbar.type);
  };

  // Hide snackbar after 4 seconds
  setTimeout(() => {
    snackbar.isOpen && handleClose();
  }, 4000);

  return (
    <Snackbar
      open={snackbar.isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert onClose={handleClose} severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}
