/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCameraConnectionDetails } from "../../../apis/v1/Camera/CameraConnection";
import EditCameraConnectionStep1 from "../../../components/Cameras/v1/CameraConnection/EditTemplateForm/Step1";
import PagesHeader from "../../../components/PagesHeader";
import { getAuthTypes } from "../../../apis/v1/Camera/Camera";

export default function EditCameraConnectionTemplate() {
  const [template, setTemplate] = useState({});
  const params = useParams();
  const [authTypes, setAuthTypes] = useState([]);
  useEffect(() => {
    getAuthTypes().then((res) => res && setAuthTypes(res.data));
    getCameraConnectionDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
  }, []);
  return (
    <>
      <PagesHeader title="Edit Camera Connection" />

      <EditCameraConnectionStep1 authTypes={authTypes} template={template} />
    </>
  );
}
