import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createTemplate,
  getOcuconTemplateList,
} from "../../apis/v1/Camera/OcuconCameraTemplates";
import AddOcuconCameraConnectionTemplateForm from "../../components/Cameras/v1/OcuconCameraConnectionTemplate/AddOcuconCameraConnectionTemplateForm";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import { getAuthTypes } from "../../apis/v1/Camera/Camera";

export default function AddOcuconTemplate() {
  const navigate = useNavigate();
  const [base_templates, set_base_templates] = useState([]);
  const [authTypes, setAuthTypes] = useState([]);
  useEffect(() => {
    const is_active = true;
    getOcuconTemplateList(is_active).then(
      (res) => res && set_base_templates(res.data),
    );
    getAuthTypes().then((res) => res && setAuthTypes(res.data));
  }, []);

  const handleSubmitForm = (values) => {
    createTemplate(values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(`/connection/ocucon-camera-connection-templates`);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Ocucon Camera Connection Template" />

      <AddOcuconCameraConnectionTemplateForm
        authTypes={authTypes}
        handleSubmitForm={handleSubmitForm}
        base_templates={base_templates}
      />
    </>
  );
}
