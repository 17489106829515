import { axiosInstance } from "../../config/axiosInstance";
import {
  CameraSettingsOverridesPayload,
  SiteCameraSettingsOverridesResponse,
} from "../../../interfaces/Settings";
import { AxiosPromise } from "axios";

const generateCameraOverridesURL = (
  company_uuid: string,
  site_uuid: string,
  camera_override_uuid?: string,
) => {
  let url = `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/settings/camera-overrides`;
  if (camera_override_uuid) {
    url += `/${camera_override_uuid}`;
  }
  return url;
};

export const getSiteCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
): AxiosPromise<SiteCameraSettingsOverridesResponse[]> => {
  return axiosInstance.get(generateCameraOverridesURL(company_uuid, site_uuid));
};

export const addSiteCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  data: CameraSettingsOverridesPayload,
): AxiosPromise<SiteCameraSettingsOverridesResponse> => {
  return axiosInstance.post(
    generateCameraOverridesURL(company_uuid, site_uuid),
    data,
  );
};

export const editSiteCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_override_uuid: string,
  data: Omit<CameraSettingsOverridesPayload, "default_camera_setting_uuid">,
): AxiosPromise<SiteCameraSettingsOverridesResponse> => {
  return axiosInstance.patch(
    generateCameraOverridesURL(company_uuid, site_uuid, camera_override_uuid),
    data,
  );
};

export const deleteSiteCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_override_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    generateCameraOverridesURL(company_uuid, site_uuid, camera_override_uuid),
  );
};
