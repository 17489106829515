import { mapToConvertPointsToString, relativePoints } from "../Utilis";

export default function Polygon({
  index,
  polygonRef,
  shape,
  svgWidth,
  svgHeight,
  setActiveShape,
  shapes,
  isActiveShape,
  isDrawingActive,
}) {
  const CLOSED_SHAPE_STORKE_DASH_ARRAY = 0;
  const NOT_CLOSED_SHAPE_STORKE_DASH_ARRAY = 6;

  // Start adding new shape when click on label
  if (polygonRef.current.length !== shapes.length) {
    // add or remove refs
    polygonRef.current = Array(shapes.length).map(
      (_, i) => polygonRef.current[i],
    );
  }

  const points = mapToConvertPointsToString(
    relativePoints(shape.points, svgWidth, svgHeight),
  );

  return (
    <polygon
      id={`polygon-${index}`}
      ref={(el) => polygonRef && (polygonRef.current[index] = el)}
      points={points}
      strokeDasharray={
        shapes[index].uuid
          ? CLOSED_SHAPE_STORKE_DASH_ARRAY
          : NOT_CLOSED_SHAPE_STORKE_DASH_ARRAY
      }
      stroke={shape.incident_class.colour}
      strokeWidth={2}
      strokeOpacity={1}
      fill={shape.incident_class.colour}
      fillOpacity={!shapes[index].uuid ? "0.1" : isActiveShape ? "0.6" : "0.3"}
      onMouseDown={() => {
        !isDrawingActive &&
          setActiveShape({
            index,
            ...shapes[index],
          });
      }}
      onClick={() =>
        !isDrawingActive &&
        setActiveShape({
          index,
          ...shapes[index],
        })
      }
    ></polygon>
  );
}
