/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getUserLicenses } from "../../../../apis/v1/Licenses";
import { useEffect, useState } from "react";
import { getServices } from "../../../../apis/v1/Services/Services";

export default function UserLicenses() {
  const [licenses, setLicenses] = useState([]);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  const fetchLicenses = async () => {
    try {
      const response = await getUserLicenses(params.user_id, params.company_uuid);
      setLicenses(response.data);
    } catch (err) {
      console.log("error fetching licenses", err);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await getServices();
      setServices(response.data);
    } catch (err) {
      console.log("error fetching services", err);
    }
  };

  useEffect(() => {
    fetchServices();
    if(params.user_id) {
      fetchLicenses();
    }
  }, [params.user_id]);

  const getServiceName = (service_uuid) => {
    const service = services.find((service) => service.uuid === service_uuid);
    return service?.name;
  };

  return (
    <>
      <Box component={"h4"} mb={0}>
        Assigned Licenses
      </Box>
      {licenses?.length > 0 ? (
        <Box component={"ul"} pl={3} my={0}>
          {licenses?.map((license) => (
            <Box
              component={"li"}
              mb={0}
              data-testid="license-item"
              key={license.service_uuid}
            >
              {getServiceName(license.service_uuid)}
            </Box>
          ))}
        </Box>
      ) : (
        <Box data-testid="no-licenses">-</Box>
      )}
      <Button
        variant={"outlined"}
        sx={{ my: 1, mb: 3 }}
        data-testid="edit-licenses-btn"
        onClick={() =>
          navigate(
            `/company/${params.company_uuid}/node/${params.node_uuid}/user/${params.user_id}/update-licenses`,
          )
        }
      >
        Edit
      </Button>
    </>
  );
}
