import { Site, SiteResponse } from "../../interfaces/Site";
import { axiosInstance } from "../config/axiosInstance";
import { AxiosPromise } from "axios";

const generateSiteBaseURL = (company_uuid: string) => {
  return `/organisation-management/v1/companies/${company_uuid}/sites`;
};

export const createSite = (company_uuid: string, data: Site) => {
  return axiosInstance.post(generateSiteBaseURL(company_uuid), data);
};

export const getSites = (
  company_uuid: string,
): AxiosPromise<SiteResponse[]> => {
  return axiosInstance.get(generateSiteBaseURL(company_uuid));
};

export const getSiteDetails = (
  site_uuid: string,
  company_uuid: string,
): AxiosPromise<SiteResponse> => {
  return axiosInstance.get(`${generateSiteBaseURL(company_uuid)}/${site_uuid}`);
};

export const editSite = (
  company_uuid: string,
  site_uuid: string,
  data: Site,
): AxiosPromise<SiteResponse> => {
  return axiosInstance.patch(
    `${generateSiteBaseURL(company_uuid)}/${site_uuid}`,
    data,
  );
};

export const deleteSite = (
  site_uuid: string,
  company_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateSiteBaseURL(company_uuid)}/${site_uuid}`,
  );
};
