/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import ConsumablesAddonsListTable from "../../components/ConsumablesAddons/ConsumablesAddonsListTable";
import {
  getConsumablesAddons,
  hideConsumableAddon,
  unHideConsumableAddon,
} from "../../apis/v1/Services/ConsumablesAddons";

export default function ConsumablesAddonsList() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumableAddons, setConsumableAddons] = useState<any[]>([]);
  useEffect(() => {
    getConsumablesAddonsRequest();
  }, []);

  const getConsumablesAddonsRequest = () => {
    getConsumablesAddons(params.service_uuid, params.consumable_uuid).then(
      (res) => setConsumableAddons(res.data),
    );
  };

  const handleChangeHiddenStatus = (
    consumable_addon_uuid: string,
    is_hidden: boolean,
  ) => {
    if (is_hidden) {
      unHideConsumableAddon(
        params.service_uuid,
        params.consumable_uuid,
        consumable_addon_uuid,
      ).then(() => {
        getConsumablesAddonsRequest();
      });
    } else {
      hideConsumableAddon(
        params.service_uuid,
        params.consumable_uuid,
        consumable_addon_uuid,
      ).then(() => {
        getConsumablesAddonsRequest();
      });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Consumables Addons"
        subheader="List of consumables addons that user purchase"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/service/${params.service_uuid}/consumable/${params.consumable_uuid}/consumable_addon/add`,
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <ConsumablesAddonsListTable
          consumableAddons={consumableAddons}
          setConsumableAddons={setConsumableAddons}
          handleChangeHiddenStatus={handleChangeHiddenStatus}
        />
      </CardContent>
    </Card>
  );
}
