/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import ServiceDetailsCard from "../../components/Services/ServiceDetailsCard";
import TiersList from "../Tiers/TiersList";
import FeaturesList from "../Features/FeaturesList";
import ConsumablesList from "../Consumables/ConsumablesList";
import { Service } from "../../interfaces/Service";
import { getServiceDetails } from "../../apis/v1/Services/Services";

export default function ServiceDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [service, setService] = useState<Service>();

  useEffect(() => {
    getServiceDetails(params.service_uuid).then((res) => setService(res.data));
  }, []);

  return (
    <>
      {service && (
        <Card variant="outlined">
          <CardHeader
            title="Service Details"
            subheader="Details about this service"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() => navigate(`/service/edit/${params.service_uuid}`)}
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <ServiceDetailsCard service={service} />
          </CardContent>
        </Card>
      )}

      <TiersList />
      <FeaturesList />
      <ConsumablesList />
    </>
  );
}
