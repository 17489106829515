import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../../Table";
import DeleteSite from "./DeleteSite";
import { SiteResponse } from "../../../interfaces/Site";
import { RouterHistoryState } from "../../../interfaces/RouterHistoryState";

interface SitesListTableProps {
  sites: SiteResponse[];
  setSites: (sites: SiteResponse[]) => void;
  currentRouteState: RouterHistoryState;
  root_node_uuid: string;
  company_uuid: string;
}

export default function SitesListTable({
  sites,
  setSites,
  company_uuid,
  currentRouteState,
  root_node_uuid,
}: SitesListTableProps) {
  const navigate = useNavigate();
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "phone",
          name: "Phone",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={sites}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.phones[0].number}</TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="view_icon_btn"
              onClick={() =>
                navigate(
                  `/company/${company_uuid}/node/${root_node_uuid}/site/${row.uuid}`,
                  {
                    state: {
                      ...currentRouteState,
                    },
                  },
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              data-testid="edit_icon_btn"
              onClick={() =>
                navigate(
                  `/company/${company_uuid}/node/${root_node_uuid}/site/${row.uuid}/edit`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteSite
              site_uuid={row.uuid}
              company_uuid={company_uuid}
              sites={sites}
              setSites={setSites}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
