import { Consumable } from "../../../interfaces/Consumable";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const generateConsumablesAddonsURL = (
  service_uuid: string,
  consumable_uuid: string,
) => {
  return `payment/v1/services/${service_uuid}/consumables/${consumable_uuid}/consumable_addons`;
};

export const createConsumableAddons = (
  service_uuid: string,
  consumable_uuid: string,
  data: any,
): AxiosPromise<Consumable> => {
  return axiosInstance.post(
    generateConsumablesAddonsURL(service_uuid, consumable_uuid),
    data,
  );
};

export const getConsumablesAddons = (
  service_uuid: string,
  consumable_uuid: string,
): AxiosPromise<Consumable[]> => {
  return axiosInstance.get(
    generateConsumablesAddonsURL(service_uuid, consumable_uuid),
  );
};

export const getConsumableAddonsDetails = (
  service_uuid: string,
  consumable_uuid: string,
  consumable_addon_uuid: string,
): AxiosPromise<Consumable> => {
  return axiosInstance.get(
    `${generateConsumablesAddonsURL(service_uuid, consumable_uuid)}/${consumable_addon_uuid}`,
  );
};

export const editConsumableAddon = (
  service_uuid: string,
  consumable_uuid: string,
  consumable_addon_uuid: string,
  data: any,
): AxiosPromise<Consumable> => {
  return axiosInstance.patch(
    `${generateConsumablesAddonsURL(service_uuid, consumable_uuid)}/${consumable_addon_uuid}`,
    data,
  );
};

export const deleteConsumableAddon = (
  service_uuid: string,
  consumable_uuid: string,
  consumable_addon_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateConsumablesAddonsURL(service_uuid, consumable_uuid)}/${consumable_addon_uuid}`,
  );
};

export const unHideConsumableAddon = (
  service_uuid: string,
  consumable_uuid: string,
  consumable_addon_uuid: string,
): AxiosPromise<any> => {
  return axiosInstance.post(
    `${generateConsumablesAddonsURL(service_uuid, consumable_uuid)}/${consumable_addon_uuid}/unhide`,
    {},
  );
};

export const hideConsumableAddon = (
  service_uuid: string,
  consumable_uuid: string,
  consumable_addon_uuid: string,
): AxiosPromise<any> => {
  return axiosInstance.post(
    `${generateConsumablesAddonsURL(service_uuid, consumable_uuid)}/${consumable_addon_uuid}/hide`,
    {},
  );
};
