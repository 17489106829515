/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { getSubscriptions } from "../../apis/v1/Subscriptions/Subscriptions";
import { Subscription } from "../../interfaces/Subscription";
import { Card, CardContent, CardHeader } from "@mui/material";
import AllSubscriptionsListTable from "../../components/Subscriptions/AllSubscriptionsListTable";
import { getCompanies } from "../../apis/v1/Companies";
import { getAllRootNodes } from "../../apis/v1/OrgStructure";
import Loader from "../../components/Loader";
import AllSubscriptionsFilter from "../../components/Subscriptions/AllSubscriptionsFilters";
import { getServices } from "../../apis/v1/Services/Services";
import CreditsListCard from "../../components/Credits/CreditsListCard";
import SubscriptionsAuditHistoryCard from "../../components/Subscriptions/SubscriptionsAuditHistoryCard";

const fetchAllData = async (setRootNodes, setCompanies, setServices) => {
  try {
    const [rootNodesResponse, companiesResponse, servicesResponse] =
      await Promise.all([getAllRootNodes(), getCompanies(), getServices()]);
    setRootNodes(rootNodesResponse.data);
    setCompanies(companiesResponse.data);
    setServices(servicesResponse.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default function SubscriptionsList() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [rootNodes, setRootNodes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscriptionsFilters, setSubscriptionsFilters] = useState({
    company_uuid: null,
    service_uuid: null,
    active: null,
  });

  const fetchSubscriptions = () => {
    getSubscriptions(subscriptionsFilters).then((res) => {
      if (res) {
        setSubscriptions(res.data);
      }
    });
  };
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await fetchAllData(setRootNodes, setCompanies, setServices);
    } catch (err) {
      console.log("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [subscriptionsFilters]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Subscriptions" subheader="List of subscriptions" />
        <CardContent>
          <AllSubscriptionsFilter
            setSubscriptionsFilters={setSubscriptionsFilters}
            companies={companies}
            services={services}
          />
        </CardContent>
        <CardContent>
          {loading && (
            <Loader
              height="30vh"
              content="Loading subscriptions, please wait"
            />
          )}
          {subscriptions && companies && !loading && (
            <AllSubscriptionsListTable
              subscriptions={subscriptions}
              rootNodes={rootNodes}
              companies={companies}
            />
          )}
        </CardContent>
      </Card>
      <SubscriptionsAuditHistoryCard
        rootNodes={rootNodes}
        companies={companies}
        services={services}
      />
      <CreditsListCard />
    </>
  );
}
