import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getGlobalIncidentClasses } from "../../apis/v1/DetectionZone/GlobalIncidentClasses";
import { GIncidentClass } from "../../interfaces/IncidentClass";
import GlobalIncidentClassesTable from "../../components/Labels/GlobalIncidentClassesTable";

export default function GlobalIncidentClassesList() {
  const navigate = useNavigate();
  const [incidentClasses, setIncidentClasses] = useState<GIncidentClass[]>([]);
  useEffect(() => {
    getGlobalIncidentClasses().then(
      (res) => res && setIncidentClasses(res.data),
    );
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Global Incident Classes"
        subheader="A list of incident classes. Incident classes are not available for use until assigned to a company"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() => navigate("/global-incident-classes/add")}
          data-testid="addLabelBtn"
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <GlobalIncidentClassesTable
          incidentClasses={incidentClasses}
          setIncidentClasses={setIncidentClasses}
        />
      </CardContent>
    </Card>
  );
}
