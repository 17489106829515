import IncidentCauseLegacy from "../interfaces/IncidentCause";

export const sortedByOrder = (arr: IncidentCauseLegacy[]) => {
  return arr?.sort((a: IncidentCauseLegacy, b: IncidentCauseLegacy) => {
    if (a.order === null) {
      return 1;
    }

    if (b.order === null) {
      return -1;
    }

    if (a.order === b.order) {
      return 0;
    }

    return a.order < b.order ? -1 : 1;
  });
};
