import { relativePoints } from "../Utilis";

export default function Circles({
  isActiveShape,
  shape,
  svgWidth,
  svgHeight,
  index,
  activePointIndex,
  handleMouseDownOnCircle,
  handleMouseMoveOnCircle,
  handleMouseUpOnCircle,
  handleDeletePoint,
  isPointDragging,
  shapes,
  handleSubmitShape,
  setActivePointIndex,
  setActiveShape,
  handleCloseShape,
}) {
  return (
    <g id={"circles-group"}>
      {isActiveShape &&
        relativePoints(shape.points, svgWidth, svgHeight).map(
          (circle: { x: number; y: number }, circleIndex: number) => {
            const isCurrentActiveCircle =
              isActiveShape && activePointIndex === circleIndex;
            const isInitialCircleIndex = circleIndex === 0;
            return (
              <g
                key={circleIndex}
                onMouseDown={() => handleMouseDownOnCircle(index, circleIndex)}
                onMouseMove={(e) => handleMouseMoveOnCircle(e, circleIndex)}
                onMouseUp={handleMouseUpOnCircle}
                style={{ cursor: "move" }}
              >
                <circle
                  id={`circle-${circleIndex}`}
                  cx={circle.x}
                  cy={circle.y}
                  r={"5"}
                  fill={
                    !shape.uuid &&
                    (isInitialCircleIndex || isCurrentActiveCircle)
                      ? "white"
                      : shape.incident_class.colour
                  }
                  stroke={
                    !shape.uuid &&
                    (isInitialCircleIndex || isCurrentActiveCircle)
                      ? shape.incident_class.colour
                      : "white"
                  }
                  strokeWidth={1}
                  onDoubleClick={() => {
                    if (shape.uuid && shape.points.length > 3) {
                      handleDeletePoint(index, circleIndex);
                    }
                  }}
                  onMouseUp={() => {
                    if (shape.uuid && isPointDragging) {
                      handleSubmitShape(shapes[index]);
                    }
                  }}
                  onMouseEnter={() => {
                    if (isActiveShape && !isPointDragging) {
                      setActivePointIndex(circleIndex);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isPointDragging) {
                      setActivePointIndex(null);
                    }
                  }}
                  onClick={() => {
                    if (isInitialCircleIndex) {
                      setActiveShape(shapes[index]);
                      handleCloseShape();
                    }
                  }}
                ></circle>
              </g>
            );
          },
        )}
    </g>
  );
}
