import { useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MUITable from "../Table";
import DeleteConsumableAddons from "./DeletConsumableAddons";
import { IS_HIDDEN_TOOLTIP_TITLE } from "../../utilis/shared";

interface ConsumablesAddonsListTableProps {
  consumableAddons: any[];
  setConsumableAddons: (consumables: any[]) => void;
  handleChangeHiddenStatus: (
    consumable_addon_uuid: string,
    hidden: boolean,
  ) => void;
}

export default function ConsumablesAddonsListTable({
  consumableAddons,
  setConsumableAddons,
  handleChangeHiddenStatus,
}: Readonly<ConsumablesAddonsListTableProps>) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "amount",
          name: "Amount",
        },
        {
          id: "tier",
          name: "Tier",
        },
        {
          id: "default_price.unit_amount",
          name: "Default Price",
        },
        {
          id: "is_hidden",
          name: "Is Hidden?",
          tooltip_title:IS_HIDDEN_TOOLTIP_TITLE,
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={consumableAddons}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.amount}</TableCell>
          <TableCell>{row.tier?.name || '-' }</TableCell>
          <TableCell>
            {row.default_price.unit_amount / 100} {row.default_price.currency.toUpperCase()}
          </TableCell>
          <TableCell>
            {row.hidden ? 'Yes' : "No"}
            <Switch
              checked={row.hidden}
              onChange={() => handleChangeHiddenStatus(row.uuid, row.hidden)}
              aria-label="switch"
              size="small"
            />
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="view_icon_btn"
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${params.consumable_uuid}/consumable_addon/${row.uuid}`,
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${params.consumable_uuid}/consumable_addon/${row.uuid}/edit`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteConsumableAddons
              service_uuid={params.service_uuid}
              consumable_uuid={params.consumable_uuid}
              consumable_addon_uuid={row.uuid}
              consumableAddons={consumableAddons}
              setConsumableAddons={setConsumableAddons}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
