/* eslint-disable react-hooks/exhaustive-deps */
import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { getPolicy, getPolicyFile, updatePolicy } from "../../apis/v1/Policies";
import { useEffect, useState } from "react";
import EditPolicyForm from "../../components/Policies/EditPolicyForm";
import { getFileName } from "../../utilis/downloadFile";

export default function EditPolicy() {
  const params = useParams();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState();
  const [policyFile, setPolicyFile] = useState<string>();

  const fetchPolicy = async () => {
    try {
      const response = await getPolicy(params.policy_uuid);
      setPolicy(response.data);
    } catch (error) {
      console.error("Error fetching policy:", error);
    }
  };

  const fetchPolicyFile = async () => {
    try {
      const response = await getPolicyFile(params.policy_uuid);
      const policyFileName = getFileName(
        response.headers["content-disposition"],
      );
      setPolicyFile(policyFileName);
    } catch (error) {
      console.error("Error fetching policy:", error);
    }
  };

  useEffect(() => {
    fetchPolicy();
    fetchPolicyFile();
  }, [params]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    values.file && formData.append("file", values.file);
    updatePolicy(params.policy_uuid, formData).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate("/services");
    });
  };

  return (
    <>
      <PagesHeader title="Edit Policy" />
      <Grid container>
        <Grid item sm={12} md={6} lg={4}>
          <EditPolicyForm
            policy={policy}
            policyFile={policyFile}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}
