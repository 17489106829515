/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { getCredits } from "../../apis/v1/Credits/Credits";
import Loader from "../Loader";
import CreditsListTable from "./CreditsListTable";

interface CreditsListCardProps {
  is_subscription_active?: boolean
}

export default function CreditsListCard({ is_subscription_active } : Readonly<CreditsListCardProps>) {
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();

  const fetchCredits = async () => {
    try {
      setLoading(true);
      const response = await getCredits(
        params?.service_uuid,
        params?.company_uuid,
      );
      setCredits(response.data);
    } catch (error) {
      console.error("Error fetching credits:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCredits();
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        title={params?.company_uuid? "Credits" : "Credits Audit"}
        subheader={
          params?.company_uuid
            ? "List of credits for company subscriptions"
            : "List of all credits"
        }
      />
      {(params?.company_uuid && is_subscription_active) && (
        <CardContent>
          <Button
            variant={"outlined"}
            onClick={() =>
              navigate(
                `/company/${params?.company_uuid}/service/${params?.service_uuid}/add-credit`,
              )
            }
          >
            Add
          </Button>
        </CardContent>
      )}
      <CardContent>
        {loading && (
          <Loader height="30vh" content="Loading credits, please wait" />
        )}
        {credits && !loading && <CreditsListTable credits={credits} />}
      </CardContent>
    </Card>
  );
}
