import { Box, Grid } from "@mui/material";
import { Consumable } from "../../interfaces/Consumable";

export default function ConsumableDetailsCard({ consumable }: { consumable: Consumable }) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="consumable-details" item xs={12} sm={12} md={12}>
        <Box component={"p"} my={0}>
          Name: {consumable.name}
        </Box>
        <Box component={"p"} mt={0}>
          Description: {consumable.description}
        </Box>
      </Grid>
    </Grid>
  );
}
