import { Consumable } from "../../../interfaces/Consumable";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const generateConsumablesURL = (service_uuid: string) => {
  return `payment/v1/services/${service_uuid}/consumables`;
};

export const createConsumable = (
  service_uuid: string,
  data: Omit<Consumable, "uuid">,
): AxiosPromise<Consumable> => {
  return axiosInstance.post(
    generateConsumablesURL(service_uuid),
    data,
  );
};

export const getConsumables = (
  service_uuid: string,
): AxiosPromise<Consumable[]> => {
  return axiosInstance.get(generateConsumablesURL(service_uuid));
};

export const getConsumableDetails = (
  service_uuid: string,
  consumable_uuid: string,
): AxiosPromise<Consumable> => {
  return axiosInstance.get(
    `${generateConsumablesURL(service_uuid)}/${consumable_uuid}`,
  );
};

export const editConsumable = (
  service_uuid: string,
  consumable_uuid: string,
  data: Omit<Consumable, "uuid">,
): AxiosPromise<Consumable> => {
  return axiosInstance.patch(
    `${generateConsumablesURL(service_uuid)}/${consumable_uuid}`,
    data,
  );
};

export const deleteConsumable = (
  service_uuid: string,
  consumable_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateConsumablesURL(service_uuid)}/${consumable_uuid}`,
  );
};
