import { useEffect, useState } from "react";
import { getTemplateList } from "../../apis/v1/Camera/CompanyCameraTemplates";
import { getOcuconTemplateList } from "../../apis/v1/Camera/OcuconCameraTemplates";
import PagesHeader from "../../components/PagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import AddConnectionForm from "../../components/Cameras/v1/CameraConnection/AddConnectionForm";
import { createCameraConnection } from "../../apis/v1/Camera/CameraConnection";
import { showSnackbar } from "../../utilis/snackbar";
import { getAuthTypes } from "../../apis/v1/Camera/Camera";
import { removeWhiteSpace } from "../../utilis/shared";
import {
  AuthType,
  CreateCameraConnectionFormValues,
} from "../../interfaces/Camera";

export default function AddCameraConnection() {
  const params = useParams();
  const navigate = useNavigate();

  const [baseTemplates, setBaseTemplates] = useState({});
  const [authTypes, setAuthTypes] = useState<Array<AuthType>>([]);
  useEffect(() => {
    const fetchData = async () => {
      const authTypesResponse = await getAuthTypes();
      setAuthTypes(authTypesResponse?.data ?? []);
      const companyTemplatesResponse = await getTemplateList(
        params.company_uuid,
        true,
      );
      const ocuconTemplatesResponse = await getOcuconTemplateList(true);
      const ocuconTemplates = ocuconTemplatesResponse?.data?.map(
        (ocucon_temp) => ({
          ...ocucon_temp,
          type: "ocucon_template",
        }),
      );
      const companyTemplates = companyTemplatesResponse?.data?.map(
        (comp_temp) => ({
          ...comp_temp,
          type: "company_template",
        }),
      );

      setBaseTemplates({
        ocucon_templates: ocuconTemplates ?? [],
        company_templates: companyTemplates ?? [],
      });
    };

    fetchData();
  }, []);

  const handleSubmitForm = async (values: CreateCameraConnectionFormValues) => {
    const { camera_uuid, company_uuid, site_uuid } = params;
    const res = await createCameraConnection({
      camera_uuid,
      company_uuid,
      site_uuid,
      name: values.name,
      is_http: values.is_http,
      url_template: values.url_template,
      auth_type: values.auth_type,
      username: values.username ? removeWhiteSpace(values.username) : null,
      password: values.password ? removeWhiteSpace(values.password) : null,
      attributes: values.is_http
        ? values.attributes
        : values.attributes.filter((attr) => attr.type === "settings"),
    });

    if (res) {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    }
  };

  return (
    <>
      <PagesHeader title="Add Camera Connection" />
      <AddConnectionForm
        handleSubmitForm={handleSubmitForm}
        baseTemplates={baseTemplates}
        authTypes={authTypes}
      />
    </>
  );
}
