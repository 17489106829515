/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateCameraConnection,
  getCameraConnectionAttributes,
  getCameraConnectionDetails,
} from "../../../apis/v1/Camera/CameraConnection";
import EdiCameraConnectionStep2 from "../../../components/Cameras/v1/CameraConnection/EditTemplateForm/Step2";
import PagesHeader from "../../../components/PagesHeader";
import {
  Attribute,
  CameraConnectionResponse,
} from "../../../interfaces/Camera";

export default function EditCameraConnectionAttributes() {
  const [template, setTemplate] = useState<CameraConnectionResponse>();
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const params = useParams();
  const navigate = useNavigate();

  const activateConnection = () => {
    activateCameraConnection(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      res && navigate(-2);
    });
  };

  useEffect(() => {
    getCameraConnectionDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
    getCameraConnectionAttributes(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setAttributes(
          res.data.attributes.map((attr, index) => {
            return {
              ...attr,
              id: index.toString(),
            };
          }),
        );
      }
    });
  }, []);
  return (
    <>
      <PagesHeader title="Edit Camera Connection Attributes" />
      {template && (
        <EdiCameraConnectionStep2
          activateConnection={activateConnection}
          attributes={attributes}
          template={template}
        />
      )}
    </>
  );
}
