import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { Service } from "../../interfaces/Service";
import { showSnackbar } from "../../utilis/snackbar";
import { deleteService } from "../../apis/v1/Services/Services";
import ConfirmationPopup from "../ConfirmationPopup";

interface DeleteServiceProps {
  service_uuid: string;
  services: Service[];
  setServices: React.Dispatch<Service[]>;
}

export default function DeleteService({
  service_uuid,
  services,
  setServices,
}: DeleteServiceProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getServicesListWithoutDeleted = () => {
    return services.filter((service) => service.uuid !== service_uuid);
  };

  const submitDeleteSite = () => {
    deleteService(service_uuid).then(() => {
      const servicesListWithoutDeleted = getServicesListWithoutDeleted();
      showSnackbar("Deleted Successfully", "success");
      setServices(servicesListWithoutDeleted);
      setOpenDialog(false);
    });
  };
  return (
    <>
      <IconButton size="small" onClick={() => setOpenDialog(true)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Service"
        dialogBody="Are you sure you want to delete this service?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
