import { orderType } from "../enums/Order";


function getNestedValue<T>(obj: T, path: string): any {
  return path?.split('.')?.reduce((value, key) => value?.[key], obj);
}
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = getNestedValue(a, orderBy as string);
  const bValue = getNestedValue(b, orderBy as string);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

type Order = orderType.ASC | orderType.DESC;

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === orderType.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const getStartIndex = (currentPage, rowsPerPage) => {
  return currentPage * rowsPerPage;
};

export const getEndIndex = (currentPage, rowsPerPage) => {
  return currentPage * rowsPerPage + rowsPerPage;
};

export const sortData = (data, order, orderBy) => {
  return data?.sort(getComparator(order, orderBy));
};

export const sliceSortedData = (data, currentPage, rowsPerPage) => {
  return data?.slice(
    getStartIndex(currentPage, rowsPerPage),
    getEndIndex(currentPage, rowsPerPage),
  );
};
