import { FC } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Checkbox, FormHelperText, ListItemText } from "@mui/material";

const MultiSelect: FC<any> = ({
  list,
  uuid,
  value,
  label,
  field,
  form,
  meta,
  required,
  sx,
  dataTestid,
}) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        ...sx,
      }}
      size="small"
      error={Boolean(meta.touched && meta.error)}
    >
      <InputLabel id="incident-type-label">{`${label}${required ? "*" : ""}`}</InputLabel>
      <Select
        labelId="incident-type-label"
        id="incident-type-select"
        data-testid={dataTestid}
        multiple
        value={field.value || []}
        onChange={handleChange}
        size="small"
        {...field}
        renderValue={() => (
          <span data-testid="selected-options">
            {field?.value
              ?.map((uuid: any) =>
                list?.length > 0
                  ? list?.filter((item: any) => item.uuid === uuid)[0][value]
                  : "",
              )
              .join(", ")}
          </span>
        )}
        input={<OutlinedInput label={`${label}${required ? "*" : ""}`} />}
      >
        {list?.length > 0 ? (
          list?.map((item: any, index: number) => (
            <MenuItem
              sx={{ py: 0 }}
              key={index}
              value={item[uuid]}
              data-testid="select-option"
            >
              <Checkbox checked={field.value.indexOf(item[uuid]) > -1} />
              <ListItemText primary={item[value]} />
            </MenuItem>
          ))
        ) : (
          <Box color={"grey"} textAlign="center">
            No options
          </Box>
        )}
      </Select>
      <FormHelperText error>
        {meta.touched && meta.error && meta.error}
      </FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
