import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getCameraDefaultSettings } from "../../apis/v1/Settings/CameraDefaults";
import AddSettingsOverrides from "../../components/Settings/v1/CompanyOverrides/AddSettingsOverrides";
import { addCameraSettingsOverrides } from "../../apis/v1/Settings/CameraOverrides";

export default function AddCameraSettingsOverrides() {
  const navigate = useNavigate();
  const params = useParams();
  const [defaultSettings, setDefaultSettings] = useState([]);

  useEffect(() => {
    getCameraDefaultSettings().then((res) => {
      res && setDefaultSettings(res.data);
    });
  }, []);

  const handleSubmit = (values: any) => {
    addCameraSettingsOverrides(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      values,
    ).then((res) => {
      if (res) {
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Camera Overrides" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddSettingsOverrides
            defaultSettings={defaultSettings}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
}
