import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Grid, TextField } from "@mui/material";
import { Reason } from "../../interfaces/Credits";
import { Consumable } from "../../interfaces/Consumable";
import MUISelect from "../Controls/Select";

interface AddCreditFormProps {
  handleSubmitForm: (values: any) => void;
  reasons: Reason[];
  consumables: Consumable[];
}
export default function AddCreditForm({
  handleSubmitForm,
  reasons,
  consumables,
}: Readonly<AddCreditFormProps>) {
  const navigate = useNavigate();
  const schema: any = yup.object().shape({
    reason_uuid: yup.string().required("Required"),
    quantity: yup.number().required("Required"),
    consumable_uuid: yup.string().required("Required"),
  });
  const initialValues = {
    reason_uuid: "",
    quantity: "",
    consumable_uuid: "",
  };

  console.log(reasons, consumables)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitForm(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="number"
            id="quantity"
            label={"Quantity*"}
            fullWidth
            name="quantity"
            error={Boolean(errors.quantity && touched.quantity)}
            helperText={errors.quantity && touched.quantity && errors.quantity}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.quantity}
            size="small"
          />
          <MUISelect
            dataTestid="reason_uuid"
            label="Reason"
            required
            labelId="reason_uuid"
            id="reason_uuid"
            name="reason_uuid"
            handleChange={handleChange}
            list={reasons}
            margin="normal"
            keyItem="uuid"
            keyValue="reason"
            value={values.reason_uuid}
            error={
              errors.reason_uuid && touched.reason_uuid && errors.reason_uuid
            }
          />
          <MUISelect
            dataTestid="consumable_uuid"
            label="Consumable"
            required
            labelId="consumable_uuid"
            id="consumable_uuid"
            name="consumable_uuid"
            handleChange={handleChange}
            list={consumables}
            margin="normal"
            value={values.consumable_uuid}
            error={
              errors.consumable_uuid &&
              touched.consumable_uuid &&
              errors.consumable_uuid
            }
            keyItem="uuid"
            keyValue="name"
            styles={{ mt:1 }}
          />
          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
