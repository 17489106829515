import { axiosInstance } from "../../config/axiosInstance";
import {
  CameraImageResponse,
  CompanyCameraDetectionResponse,
} from "../../../interfaces/Detections";
import { AxiosPromise } from "axios";

const cameraAPI = (company_uuid: string, camera_uuid: string) =>
  `/detection-zone/v1/companies/${company_uuid}/cameras/${camera_uuid}`;

export const getCompanyCameraDetections = (
  company_uuid: string,
  camera_uuid: string,
): AxiosPromise<CompanyCameraDetectionResponse> => {
  return axiosInstance.get(cameraAPI(company_uuid, camera_uuid));
};

export const addNewCameraImage = (
  company_uuid: string,
  camera_uuid: string,
  data: FormData,
): AxiosPromise<CameraImageResponse> => {
  return axiosInstance.post(
    `${cameraAPI(company_uuid, camera_uuid)}/images`,
    data,
  );
};

export const getCameraImage = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
): AxiosPromise<Blob> => {
  return axiosInstance.get(
    `${cameraAPI(company_uuid, camera_uuid)}/images/${image_uuid}`,
    {
      responseType: "blob",
    },
  );
};

export const deleteCameraImage = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${cameraAPI(company_uuid, camera_uuid)}/images/${image_uuid}`,
  );
};

export const editCameraImage = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
  data: FormData,
): AxiosPromise<CameraImageResponse> => {
  return axiosInstance.patch(
    `${cameraAPI(company_uuid, camera_uuid)}/images/${image_uuid}`,
    data,
  );
};
