import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useStoreHook";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface EditPolicyFormProps {
  policy: any;
  policyFile: string;
  handleSubmit: (values: any) => void;
}
export default function EditPolicyForm({
  policy,
  policyFile,
  handleSubmit,
}: Readonly<EditPolicyFormProps>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const initialValue = {
    file: {
      name: policyFile,
    },
  };

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .required("Required")
      .test(
        "fileType",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf",
      ),
  });

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("file", file);
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Box component={"p"} mb={0} fontWeight={"bold"}>
              Policy Type:
            </Box>
            <Box component={"p"} mt={0}>
              {policy?.type}
            </Box>
            <Typography fontWeight={"bold"}>Policy File</Typography>

            <Box display={"flex"} alignItems={"center"} mt={2}>
              <Button
                variant="outlined"
                color={touched.file && errors.file ? "error" : "primary"}
                component="label"
              >
                Choose File{' '}
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  onChange={(event) => {
                    handleFileChange(event, setFieldValue);
                  }}
                />
              </Button>

              {values.file ? (
                <Typography variant="body2" ml={2} mb={0}>
                  {values.file.name}
                </Typography>
              ) : null}
            </Box>

            {touched.file && errors.file ? (
              <Typography
                sx={{ fontSize: "0.75rem", ml: 2, mt: 1 }}
                color="error"
              >
                {errors.file}
              </Typography>
            ) : null}
          </Box>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate("/services")}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
