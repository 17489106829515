import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import MUISelect from "../../../Controls/Select";
import {
  CameraDefaultSettings,
  CameraSettingsOverrideBase,
} from "../../../../interfaces/Settings";

interface AddSettingsOverridesProps {
  defaultSettings: CameraDefaultSettings[];
  handleSubmit: (values: CameraSettingsOverrideBase) => void;
}

export default function AddSettingsOverrides({
  defaultSettings,
  handleSubmit,
}: AddSettingsOverridesProps) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    value: yup.string().required("Required"),
    default_camera_setting_uuid: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        value: "",
        default_camera_setting_uuid: "",
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <MUISelect
            error={
              errors.default_camera_setting_uuid &&
              touched.default_camera_setting_uuid &&
              errors.default_camera_setting_uuid
            }
            value={values.default_camera_setting_uuid}
            label={"Default Camera Settings"}
            required
            name="default_camera_setting_uuid"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            list={defaultSettings}
            labelId={"default_camera_setting_uuid"}
            id={"default_camera_setting_uuid"}
            styles={{
              mt: 3,
            }}
            dataTestid="default_camera_setting_uuid"
          />
          <TextField
            type="text"
            id="value"
            label="Value*"
            fullWidth
            margin="normal"
            name="value"
            error={Boolean(errors.value && touched.value)}
            helperText={errors.value && touched.value && errors.value}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.value}
            data-testid="value"
          />

          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
