/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { getCompanyIncidentClasses } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";
import { useNavigate, useParams } from "react-router-dom";
import CompanyIncidentClassListTable from "../CompanyIncidentClass/v1/CompanyIncidentClassListTable";

export default function CompanyIncidentClassList() {
  const [companyIncidentClasses, setCompanyIncidentClasses] = useState(null);
  const [companyIncidentClassesFetchingProblem, setCompanyIncidentClassesFetchingProblem] =
    useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCompanyIncidentClassesRequest();
  }, [params.company_uuid]);

  const getCompanyIncidentClassesRequest = () => {
    getCompanyIncidentClasses(params.company_uuid)
      .then((res) => {
        if (res) {
          setCompanyIncidentClasses(res.data);
          setCompanyIncidentClassesFetchingProblem(false);
        }
      })
      .catch((err) => {
        if (!err.response.status) {
          setCompanyIncidentClasses(null);
          setCompanyIncidentClassesFetchingProblem(true);
        }
      });
  };
  return (
    <Card variant="outlined">
      <CardHeader
        title="Incident Classes"
        subheader="Available incident class used on the spill dashboard for filtering, and in detection zones do specify the detection zone type"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/company/${params.company_uuid}/add-company-incident-class`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <CompanyIncidentClassListTable
          companyIncidentClasses={companyIncidentClasses}
          setCompanyIncidentClasses={setCompanyIncidentClasses}
          company_uuid={params.company_uuid}
          showFetchingDataProblemText={companyIncidentClassesFetchingProblem}
        />
      </CardContent>
    </Card>
  );
}
