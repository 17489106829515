import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { Reason } from "../../interfaces/Credits";
import { getReasons } from "../../apis/v1/Credits/Reasons";
import ReasonsListTable from "./ReasonsListTable";
import { useNavigate } from "react-router-dom";

export default function ReasonsListCard() {
  const navigate = useNavigate();
  const [reasons, setReasons] = useState<Reason[]>([]);

  const fetchReasons = async () => {
    try {
      const response = await getReasons();
      setReasons(response.data);
    } catch (error) {
      console.log("Error fetch reasons", error);
    }
  };

  useEffect(() => {
    fetchReasons();
  }, []);
  return (
    <Card variant="outlined">
      <CardHeader
        title="Credit Reasons"
        subheader="List of reasons for the credits will be given to companies"
      />
      <CardContent>
        <Button variant={"outlined"} onClick={() => navigate("/reasons/add")}>
          Add
        </Button>
      </CardContent>
      <CardContent>
        <ReasonsListTable reasons={reasons} setReasons={setReasons} />
      </CardContent>
    </Card>
  );
}
