import { Box, Grid } from "@mui/material";
import { SiteResponse } from "../../../interfaces/Site";

export default function DetailsCard({ site }: { site: SiteResponse }) {
  const checkIfArrayHasValues = (array) => {
    return site && Boolean(site[array] && site[array]?.length > 0);
  };
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="site-details-title" item xs={12}>
        <Box component={"h4"} m={0}>
          Site Details
        </Box>
      </Grid>
      <Grid data-testid="site-details" item xs={12} sm={6} md={4}>
        <Box component={"p"} data-testid="site_name" my={0}>
          Name: {site?.name}
        </Box>
        <Box component={"p"} data-testid="site_phone" my={0}>
          Phone: {checkIfArrayHasValues("phones") && site?.phones[0]?.number}
        </Box>
      </Grid>
      <Grid data-testid="site-addres" item xs={12}>
        <Box component={"h4"} mb={0}>
          Address Details
        </Box>
      </Grid>
      <Grid data-testid="site-address-details" item xs={12} sm={6} md={4}>
        <Box component={"p"} data-testid="site_address_1" my={0}>
          {site?.address?.address_1}
        </Box>
        <Box component={"p"} data-testid="site_address_2" my={0}>
          {site?.address?.address_2}
        </Box>
        <Box component={"p"} data-testid="site_address_city" my={0}>
          {site?.address?.city}
        </Box>
        <Box component={"p"} data-testid="site_address_county" my={0}>
          {site?.address?.county}
        </Box>
        <Box component={"p"} data-testid="site_address_postcode" my={0}>
          {site?.address?.postcode}
        </Box>
        <Box component={"p"} data-testid="site_address_country" mt={0}>
          {site?.address?.country.name}
        </Box>
      </Grid>
    </Grid>
  );
}
