import { useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationPopup from "../ConfirmationPopup";
import { showSnackbar } from "../../utilis/snackbar";
import { deleteRuleIntegration } from "../../apis/v1/Notifications/Integrations";

export default function DeleteRuleIntegration({
  company_uuid,
  integration_uuid,
  integrations,
  rule_uuid,
  setIntegrations,
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const getIntegrationsListWithoutDeleted = () => {
    return integrations.filter(
      (integration) => integration.uuid !== integration_uuid,
    );
  };

  const submitDeleteSite = () => {
    deleteRuleIntegration(company_uuid, rule_uuid, integration_uuid).then(
      (res) => {
        if (res) {
          const rulesListWithoutDeleted = getIntegrationsListWithoutDeleted();
          setIntegrations(rulesListWithoutDeleted);
          setOpenDialog(false);
          showSnackbar("Deleted Successfully", "success");
        }
      },
    );
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Notification Rule Integration"
        dialogBody="Are you sure you want to delete this notification rule integration?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
