import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../../../Table";
import Switch from "@mui/material/Switch";
import DeleteTemplate from "./DeleteOcuconTemplate";
import { IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE } from "../../../../utilis/shared";

export default function OcuconCameraConnectionTemplates({
  templates,
  setTemplates,
  handleChangeStatus,
}) {
  const navigate = useNavigate();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "is_http",
          name: "Is Http",
        },
        {
          id: "status",
          name: "status",
          tooltip_title: IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE,
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={templates}
      tableBodyRow={(row: any) => (
        <TableRow
          key={row.uuid}
          data-testid="ocucon-camera-connection-template-row"
        >
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.is_http ? "Yes" : "No"}</TableCell>
          <TableCell>
            {row.is_active ? "Active" : "Deactive"}
            <Switch
              checked={row.is_active}
              onChange={() => handleChangeStatus(row.uuid, row.is_active)}
              aria-label="switch"
              data-testid="switch"
              size="small"
            />
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="detailsBtn"
              onClick={() =>
                navigate(
                  `/connection/ocucon-camera-connection-template/${row.uuid}`,
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              data-testid="editBtn"
              disabled={row.is_active}
              onClick={() =>
                navigate(
                  `/connection/ocucon-camera-connection-template/edit/${row.uuid}`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteTemplate
              is_active={row.is_active}
              template_uuid={row.uuid}
              templates={templates}
              setTemplates={setTemplates}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
