import { Button, Grid } from "@mui/material";
import { useState } from "react";
import MUISelect from "../Controls/Select";

export default function AllSubscriptionsHistoriesFilter({
  setSubscriptionsHistoryFilters,
  companies,
  services,
  subscriptionActions
}) {
  const [showReset, setShowReset] = useState<boolean>(false);
  const [companyUUID, setCompanyUUID] = useState<string>(null);
  const [serviceUUID, setServiceUUID] = useState<string>(null);
  const [type, setType] = useState<string>(null);

  const handleFilter = () => {
    setShowReset(true);
    setSubscriptionsHistoryFilters({
      company_uuid: companyUUID,
      service_uuid: serviceUUID,
      type
    });
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Company"
          labelId="label-company-uuid"
          id={"select-company-uuid"}
          name="company_uuid"
          handleChange={(e) => {
            setCompanyUUID(e.target.value);
          }}
          list={companies}
          margin="normal"
          value={companyUUID || ""}
          keyItem="uuid"
          keyValue="name"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Service"
          labelId="label-service-uuid"
          id={"select-service-uuid"}
          name="service_uuid"
          handleChange={(e) => {
            setServiceUUID(e.target.value);
          }}
          list={services}
          margin="normal"
          value={serviceUUID || ""}
          keyItem="uuid"
          keyValue="name"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Action"
          labelId="label-action"
          id={"select-action"}
          name="action"
          handleChange={(e) => {
            setType(e.target.value);
          }}
          list={subscriptionActions}
          margin="normal"
          value={type || ""}
        />
      </Grid>
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={3}
      >
        <Button
          variant="outlined"
          data-testid="filter-btn"
          onClick={handleFilter}
        >
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setSubscriptionsHistoryFilters({
                company: null,
                service: null,
                type: null,
              });
              setShowReset(false);
              setCompanyUUID(null);
              setServiceUUID(null);
              setType(null);
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
