import { IShape } from "../Interface/Shape";
import { normalizedPoints, relativePoints } from "../Utilis";

export default function useDrawer() {
  const getMousePoisition = (e, svg, setMousePos) => {
    const svgBoundries = svg.current.getBoundingClientRect();
    const offsetX = svgBoundries.left;
    const offsetY = svgBoundries.top;
    const mouseX = parseInt(JSON.stringify(e.clientX - offsetX));
    const mouseY = parseInt(JSON.stringify(e.clientY - offsetY));
    setMousePos({
      mouseX,
      mouseY,
    });
    return { mouseX, mouseY };
  };

  const shapeDrawerHandler = (
    e,
    mouseX,
    mouseY,
    isDrawingActive,
    shapes,
    svgWidth,
    svgHeight,
  ) => {
    const IS_ELEMENT_TARGET_TYPE_IMAGE = e.target.nodeName === "svg";
    const IS_ELEMENT_TARGET_TYPE_POLYGON = e.target.nodeName === "polygon";
    if (
      isDrawingActive &&
      (IS_ELEMENT_TARGET_TYPE_IMAGE || IS_ELEMENT_TARGET_TYPE_POLYGON)
    ) {
      const updatedShape = shapes.map((shape: IShape) => {
        if (!shape.uuid) {
          return {
            ...shape,
            points: [
              ...shape.points,
              {
                x: mouseX / svgWidth,
                y: mouseY / svgHeight,
              },
            ],
          };
        } else {
          return shape;
        }
      });
      return updatedShape;
    }
    return shapes;
  };

  const dragPointHandler = (
    initialPos,
    activeShape,
    svgHeight,
    svgWidth,
    circleIndex,
    mouseX,
    mouseY,
  ) => {
    let pointsArr = relativePoints(activeShape.points, svgWidth, svgHeight);
    const dragX = mouseX - initialPos.startX;
    const dragY = mouseY - initialPos.startY;

    const pointX = pointsArr[circleIndex].x + dragX;
    const pointY = pointsArr[circleIndex].y + dragY;

    pointsArr[circleIndex].x = pointX;
    pointsArr[circleIndex].y = pointY;

    const updatedNormalizedPoints = normalizedPoints(
      pointsArr,
      svgWidth,
      svgHeight,
    );
    return updatedNormalizedPoints;
  };

  const deletePointHandler = (activeShapeIndex, activePointIndex, shapes) => {
    const updatedShapes = shapes.map((shape, index) => {
      if (index === activeShapeIndex) {
        return {
          ...shape,
          points: shape.points.filter(
            (point, index) => index !== activePointIndex,
          ),
        };
      } else {
        return shape;
      }
    });
    return updatedShapes;
  };

  return {
    getMousePoisition,
    shapeDrawerHandler,
    dragPointHandler,
    deletePointHandler,
  };
}
