import { IconButton, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MUITable from "../Table";
import DeleteIncidentCause from "./DeleteIncidentCause";
import EditIcon from "@mui/icons-material/Edit";
import { sortedByOrder } from "../../utilis/order";
import { IncidentCause } from "../../interfaces/IncidentCause";
import { Dispatch } from "react";

interface PropsType {
  incident_causes: IncidentCause[];
  set_incident_causes: Dispatch<IncidentCause[]>;
  company_uuid: string;
}
const incident_class_tooltip =
  "The incident class (spill etc) that this option is available for.";
const resolution_type_tooltip =
  "Define how an incident is closed when this cause is selected by a user in the spill dashboard.";
const order_tooltip =
  "The order the options are displayed to the user. The option with the lowest number will get displayed at the top of the dropdown menu in the Spill dashboard.";
const is_hidden_tooltip =
  "When hidden, the option is hidden from the dropdown list when closing incidents in the spill dashboard.";

export default function IncidentCauseList({
  incident_causes,
  set_incident_causes,
  company_uuid,
}) {
  const navigate = useNavigate();
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "text",
          name: "Name",
        },
        {
          id: "incident_class_name",
          name: "Incident class",
          tooltip_title: incident_class_tooltip,
        },
        {
          id: "resplution_type_name",
          name: "Resolution type",
          tooltip_title: resolution_type_tooltip,
        },
        {
          id: "order",
          name: "Order",
          tooltip_title: order_tooltip,
        },
        {
          id: "is_hidden",
          name: "Is hidden?",
          tooltip_title: is_hidden_tooltip,
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={sortedByOrder(incident_causes)}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.text}</TableCell>
          <TableCell>
            {row.incident_class.name}
          </TableCell>
          <TableCell>
            {row.resplution_type_name || row.resolution_type.name}
          </TableCell>
          <TableCell>{row.order}</TableCell>
          <TableCell>{row.is_hidden ? "true" : "false"}</TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/company/${company_uuid}/incident-cause/edit/${row.uuid}`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteIncidentCause
              company_uuid={company_uuid}
              incident_cause_uuid={row.uuid}
              incident_causes={incident_causes}
              set_incident_causes={set_incident_causes}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
