import { IconButton, TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Subscription } from "../../interfaces/Subscription";
import MUITable from "../Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDate } from "../../utilis/formatDate";
import { orderType } from "../../enums/Order";
interface SubscriptionsListTableProps {
  subscriptions: Array<Subscription>;
  currentRouteState: {
    company_name: string;
    prevCompanyLocation: string;
  };
}

export default function SubscriptionsListTable({
  subscriptions,
  currentRouteState,
}: Readonly<SubscriptionsListTableProps>) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "service.name",
          name: "Service",
        },
        {
          id: "tier.name",
          name: "Tier",
        },
        {
          id: "expires_at_utc",
          name: "Expiry date",
        },
        {
          id: "active",
          name: "Active",
        },
        {
          id: "activated_at_utc",
          name: "Activated on",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      orderByOption="activated_at_utc"
      orderOption={orderType.DESC}
      data={subscriptions}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid} data-testid="user-row">
          <TableCell>{row.service.name}</TableCell>
          <TableCell>{row.tier.name}</TableCell>
          <TableCell>
            {row.expires_at_utc
              ? formatDate(row.expires_at_utc)
              : "No expiration date"}
          </TableCell>
          <TableCell>{row.active ? "Yes" : "No"}</TableCell>
          <TableCell>{formatDate(row.activated_at_utc)}</TableCell>

          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="view-subscription-button"
              onClick={() =>
                navigate(
                  `/company/${params?.company_uuid}/service/${row.service.uuid}/subscription/${row.uuid}`,
                  {
                    state: {
                      ...currentRouteState,
                    },
                  },
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    />
  );
}
