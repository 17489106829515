import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { companiesSync } from "../../apis/v1/DetectionZone/Sync";
import { showSnackbar } from "../../utilis/snackbar";

export default function Sync() {
  const handleSyncCompanies = () => {
    companiesSync().then((res) => {
      res && showSnackbar("Synced Successfully", "success");
    });
  };

  return (
    <>
      <Card variant="outlined" sx={{ pb: 2 }}>
        <CardHeader
          title="Sync Services"
          subheader="Perform a manual sync of services (grab company/camera data)"
        />
        <CardContent>
          <Button variant={"outlined"} onClick={handleSyncCompanies}>
            Sync Detection Zone Service with Org Service
          </Button>
        </CardContent>
      </Card>
    </>
  );
}
