import { Box, TableCell, TableRow } from "@mui/material";
import MUITable from "../../Table";
import DeleteCompanyIncidentClass from "./DeleteCompanyIncidentClass";

export default function CompanyIncidentClassListTable({
  companyIncidentClasses,
  setCompanyIncidentClasses,
  company_uuid,
  showFetchingDataProblemText,
}) {
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "colour",
          name: "Colour",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      showFetchingDataProblemText={showFetchingDataProblemText}
      data={companyIncidentClasses}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Box display={"flex"} gap={2}>
              <Box
                sx={{
                  backgroundColor: row.colour,
                  height: "20px",
                  width: "20px",
                }}
              />
              <Box> {row.colour}</Box>
            </Box>
          </TableCell>
          <TableCell align="right">
            <DeleteCompanyIncidentClass
              company_uuid={company_uuid}
              incident_class_uuid={row.uuid}
              companyIncidentClasses={companyIncidentClasses}
              setCompanyIncidentClasses={setCompanyIncidentClasses}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
