import { Service } from "../../../interfaces/Service";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const servicesBaseURL = `payment/v1/services`;

export const createService = (
  data: any,
): AxiosPromise<Service> => {
  return axiosInstance.post(servicesBaseURL, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const getServices = (): AxiosPromise<Service[]> => {
  return axiosInstance.get(servicesBaseURL);
};

export const getServiceDetails = (
  service_uuid: string,
): AxiosPromise<Service> => {
  return axiosInstance.get(`${servicesBaseURL}/${service_uuid}`);
};

export const editService = (
  service_uuid: string,
  data: any,
): AxiosPromise<Service> => {
  return axiosInstance.patch(
    `${servicesBaseURL}/${service_uuid}`,
    data,
  );
};

export const deleteService = (service_uuid: string): AxiosPromise<void> => {
  return axiosInstance.delete(`${servicesBaseURL}/${service_uuid}`);
};

export const getServiceImage = (service_uuid): AxiosPromise<Blob> => {
  return axiosInstance.get(`${servicesBaseURL}/${service_uuid}/image`, {
    responseType: "blob",
  });
};
