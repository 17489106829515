import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import AddCompanyForm from "../../components/Companies/v1/AddCompanyForm";
import { createCompany, getServices } from "../../apis/v1/Companies";
import { Service } from "../../interfaces/Service";
import { showSnackbar } from "../../utilis/snackbar";
import { getCountries } from "../../apis/v1/Countries";
import { Country } from "../../interfaces/Address";
import { CompanyFormValues, CreateCompanyData } from "../../interfaces/Company";

export default function AddCompany() {
  const navigate = useNavigate();
  const [services, setServices] = useState<Array<Service>>([]);

  const [countries, setCountries] = useState<Array<Country>>([]);

  useEffect(() => {
    getServices().then((res) => {
      res && setServices(res.data);
    });
    getCountries().then((res) => {
      res && setCountries(res.data);
    });
  }, []);

  const handleSubmitCompany = (values: CompanyFormValues) => {
    const data: CreateCompanyData = {
      name: values.name,
      phones: values.phone && values.country_code ?[
        {
          number: `${values.phone}`,
          country_code: values.country_code,
        },
      ]: [],
      addresses: [
        {
          address_1: values.address_1,
          address_2: values.address_2,
          city: values.city,
          postcode: values.postcode,
          county: values.county,
          country_uuid: values.country,
        },
      ],
      services_uuid: values.services_uuid,
    };
    createCompany(data).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate("/companies");
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Company" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddCompanyForm
            services={services}
            countries={countries}
            handleSubmitCompany={handleSubmitCompany}
          />
        </Grid>
      </Grid>
    </>
  );
}
