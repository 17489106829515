import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteCameraImage } from "../../apis/v1/DetectionZone/CameraImage";

export default function DeleteImage({ image_uuid, getCameraImageRequest }) {
  const [openDialog, setOpenDialog] = useState(false);
  const params = useParams();

  const submitDeleteTemplate = () => {
    deleteCameraImage(params.company_uuid, params.camera_uuid, image_uuid).then(
      (res) => {
        if (res) {
          getCameraImageRequest();
          setOpenDialog(false);
          showSnackbar("Deleted Successfully", "success");
        }
      },
    );
  };
  return (
    <>
      <Tooltip title="delete image" placement="top">
        <IconButton
          size="small"
          data-testid="deleteBtn"
          onClick={() => setOpenDialog(true)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <ConfirmationPopup
        dialogTitle="Delete Camera Detection Zone Image"
        dialogBody="Are you sure you want to delete this image?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteTemplate()}
      />
    </>
  );
}
