import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import AddIncidentCauseForm from "../../components/IncidentCauses/AddIncidentCauseForm";
import PagesHeader from "../../components/PagesHeader";
import {
  createIncidentCause,
} from "../../apis/v1/IncidentCause";
import { showSnackbar } from "../../utilis/snackbar";
import {
  IncidentCauseCreateArgs,
} from "../../interfaces/IncidentCause";
import { getCompanyIncidentClasses } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";

export default function AddIncidentCause() {
  const [incidentClasses, setIncidentClasses] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCompanyIncidentClasses(params.company_uuid).then((res) => {
      setIncidentClasses(res?.data);
    });
  }, []);

  const handleSubmitForm = (values: IncidentCauseCreateArgs) => {
    createIncidentCause(params.company_uuid, values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };
  return (
    <>
      <PagesHeader title="Add Incident Cause" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddIncidentCauseForm
            handleSubmitForm={handleSubmitForm}
            incident_classes={incidentClasses}
          />
        </Grid>
      </Grid>
    </>
  );
}
