import { Box, Grid } from "@mui/material";
import { Company } from "../../../interfaces/Company";

interface DetailsCardProps {
  company: Company;
}

export default function DetailsCard({ company }: DetailsCardProps) {
  const checkIfArrayHasValues = (array) => {
    return Boolean(company[array] && company[array].length > 0);
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12}>
        <Box component={"h4"} m={0}>
          Company Details
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component={"p"} my={0} data-testid="company-account-number">
          Account number: {company.account_number}
        </Box>
        <Box component={"p"} my={0} data-testid="company-name">
          Name: {company.name}
        </Box>
        <Box component={"p"} my={0} data-testid="company-phone">
          Phone: {checkIfArrayHasValues("phones") ? company.phones[0]?.number : '-'}
        </Box>
      </Grid>
      <Grid data-testid="company-address" item xs={12}>
        <Box component={"h4"} mb={0}>
          Address Details
        </Box>
      </Grid>
      <Grid data-testid="company-address-details" item xs={12} sm={6} md={4}>
        {company.addresses.length === 0 && "-"}
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") && company.addresses[0].address_1}
        </Box>
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") && company.addresses[0].address_2}
        </Box>
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") && company.addresses[0].city}
        </Box>
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") && company.addresses[0].county}
        </Box>
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") && company.addresses[0].postcode}
        </Box>
        <Box component={"p"} my={0}>
          {checkIfArrayHasValues("addresses") &&
            company.addresses[0].country.name}
        </Box>
      </Grid>
      <Grid data-testid="company-services-title" item xs={12}>
        <Box component={"h4"} mb={0}>
          Services
        </Box>
      </Grid>
      <Grid data-testid="company-services" item xs={12} sm={6} md={4}>
        <Box component={"p"} mt={0} mb={2}>
          {company && company.services.length > 0
            ? company.services.map((service: any) => service.name).join(", ")
            : "-"}
        </Box>
      </Grid>
      {/* TODO: this should be uncommented when deploy payment service */}
      {/* <CompanyTiers /> */}
    </Grid>
  );
}
