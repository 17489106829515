/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import EditSiteForm from "../../components/Sites/v1/EditSiteForm";
import { editSite, getSiteDetails } from "../../apis/v1/Sites";
import { showSnackbar } from "../../utilis/snackbar";
import { getCountries } from "../../apis/v1/Countries";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { getChildren, resetList } from "../../utilis/format-child-nodes";

export default function EditSite() {
  const navigate = useNavigate();
  const [site, setSite] = useState(null);
  const params = useParams();
  const [countries, setCountries] = useState<Array<any>>([]);
  const [parentNodes, setParentNodes] = useState([]);

  useEffect(() => {
    resetList();
    getSiteDetails(params.site_uuid, params.company_uuid).then((res) => {
      res && setSite(res.data);
    });
    getCountries().then((res) => {
      setCountries(res.data);
    });

    getCompanyRootNodes(params.company_uuid, params.node_uuid).then((res) => {
      const nodes = getChildren(res.data);
      setParentNodes(nodes);
    });

    return () => {
      resetList();
    };
  }, [params.site_uuid, params.company_uuid]);

  const handleSubmitSite = (values) => {
    editSite(params.company_uuid, params.site_uuid, {
      name: values.name,
      phones: [
        {
          uuid: site.phones[0].uuid,
          number: values.phone,
          country_code: values.country_code,
        },
      ],
      address: {
        uuid: site.address.uuid,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        postcode: values.postcode,
        county: values.county,
        country_uuid: values.country,
      },
      parent_node_uuid: values.parent_node_uuid,
    }).then((res) => {
      if (res) {
        showSnackbar("Site edited successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit Site" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {site && (
            <EditSiteForm
              parentNodes={parentNodes}
              site={site}
              countries={countries}
              handleSubmitSite={handleSubmitSite}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
