import { useNavigate, useParams } from "react-router-dom";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MUITable from "../Table";
import DeleteConsumable from "./DeletConsumable";
import { Consumable } from "../../interfaces/Consumable";

interface ConsumablesListTableProps {
  consumables: Consumable[];
  setConsumables: (consumables: Consumable[]) => void;
}

export default function ConsumablesListTable({
  consumables,
  setConsumables,
}: ConsumablesListTableProps) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={consumables}
      tableBodyRow={(row: Consumable) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Tooltip title={row?.description} placement="top-start">
              <p className="truncate_text">{row?.description}</p>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
          <IconButton
              size="small"
              data-testid="view_icon_btn"
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${row.uuid}`
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${row.uuid}/edit`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteConsumable
              service_uuid={params.service_uuid}
              consumable_uuid={row.uuid}
              consumables={consumables}
              setConsumables={setConsumables}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
