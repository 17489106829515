import AddServiceForm from "../../components/Services/AddServiceForm";
import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { createService } from "../../apis/v1/Services/Services";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate } from "react-router-dom";

export default function AddService() {
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('name' , values.name);
    formData.append('description' , values.description);
    formData.append('image' , values.image);
    formData.append('color' , values.color);

    createService(formData).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate('/services');
    });
  };

  return (
    <>
      <PagesHeader title="Add Service" />
      <Grid container mt={3}>
        <Grid item sm={12} md={8} xl={6}>
          <AddServiceForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
