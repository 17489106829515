import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { Box, Button, Grid, TextField } from "@mui/material";

export default function AddGlobalIncidentClassForm({ handleSubmitForm }) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        colour: "#000",
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitForm(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="label-name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
            data-testid="label-name"
          />

          <Box mt={2}>
            <label> Colour </label>
            <input
              type="color"
              value={values.colour || ""}
              name="colour"
              onChange={handleChange}
            />
          </Box>

          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
