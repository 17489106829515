import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

export default function MUIDateTimePicker({
  label,
  value,
  setValue,
  errors,
  required
}: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        label={`${label + (required ? "*" : "")}`}
        ampm={false}
        value={value}
        onChange={(newValue: any) => setValue(newValue)}
        inputFormat="DD-MM-yyyy HH:mm"
        disableMaskedInput={true}
        minDate={new Date()}
        renderInput={(params: any) => (
          <TextField
            fullWidth
            size="small"
            {...params}
            error={Boolean(errors)}
            helperText={<>{errors}</>}
          />
        )}
      />
    </LocalizationProvider>
  );
}