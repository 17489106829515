/* eslint-disable react-hooks/exhaustive-deps */
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCompanyTemplateAttributesByUUID,
  getTemplateDetails,
} from "../../apis/v1/Camera/CompanyCameraTemplates";
import DetailsCard from "../../components/Cameras/v1/CompanyTemplate/DetailsCard";
import { Attribute, CameraConnection } from "../../interfaces/Camera";

export default function CompanyCameraTemplateDetails() {
  const [template, setTemplate] = useState<CameraConnection>();
  const [attributes, setAttributes] = useState<Array<Attribute>>();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getTemplateDetails(params.company_uuid, params.template_uuid).then(
      (res) => {
        if (res) {
          setTemplate(res.data);
        }
      },
    );
    getCompanyTemplateAttributesByUUID(
      params.company_uuid,
      params.template_uuid,
    ).then((res) => {
      if (res) {
        setAttributes(res.data.attributes);
      }
    });
  }, []);

  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link color="inherit" to="/companies">
          Companies
        </Link>
        <Link
          color="inherit"
          to={location.state?.prevCompanyLocation}
          state={location.state}
        >
          {location.state.company_name}
        </Link>
        <Typography color="primary">{template && template?.name}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          title="Company Template Details"
          subheader="This is company camera template details"
        />
        <CardContent>
          {template && !template.is_active && (
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/connection/company-template/${params.company_uuid}/edit/${params.template_uuid}`,
                )
              }
            >
              Edit
            </Button>
          )}
        </CardContent>
        <CardContent>
          {template && attributes && (
            <DetailsCard template={template} attributes={attributes} />
          )}
        </CardContent>
      </Card>
    </>
  );
}
