/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import EditFeatureForm from "../../components/Features/EditFeatureForm";
import { showSnackbar } from "../../utilis/snackbar";
import { Feature } from "../../interfaces/Features";
import {
  editFeature,
  getFeatureDetails,
} from "../../apis/v1/Services/Features";

export default function EditFeature() {
  const navigate = useNavigate();
  const params = useParams();
  const [feature, setFeature] = useState<Feature>();

  useEffect(() => {
    getFeatureDetails(params.service_uuid, params.feature_uuid).then((res) => {
      setFeature(res.data);
    });
  }, []);

  const handleSubmit = (values) => {
    editFeature(params.service_uuid, params.feature_uuid, values).then(() => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Edit Feature" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {feature && (
            <EditFeatureForm feature={feature} handleSubmit={handleSubmit} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
