import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssignLicense from "../../components/Users/v1/EditUser/AssignLicense";
import {
  getLicenses,
  getUserLicenses,
  updateUserLicenses,
} from "../../apis/v1/Licenses";
import { getSubscriptions } from "../../apis/v1/Subscriptions/Subscriptions";
import { showSnackbar } from "../../utilis/snackbar";

const useSubscriptions = (companyUuid) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(null);

  const fetchSubscriptions = useCallback(async () => {
    try {
      const response = await getSubscriptions({
        company_uuid: companyUuid,
        active: true,
      });
      setSubscriptions(response.data);
    } catch (e) {
      setError("Failed to fetch services");
      console.error(e);
    }
  }, [companyUuid]);

  useEffect(() => {
    if (companyUuid) {
      fetchSubscriptions();
    }
  }, [companyUuid, fetchSubscriptions]);

  return { subscriptions, error, fetchSubscriptions };
};

const useAssignedLicenses = (userId, company_uuid) => {
  const [assignedLicenses, setAssignedLicenses] = useState([]);

  const fetchAssignedLicenses = useCallback(async () => {
    try {
      const response = await getUserLicenses(userId, company_uuid);
      setAssignedLicenses(response.data);
    } catch (e) {
      console.error(e);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchAssignedLicenses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return { assignedLicenses, setAssignedLicenses };
};

export default function UpdateLicenses() {
  const { company_uuid, user_id } = useParams();
  const navigate = useNavigate();
  const { subscriptions } = useSubscriptions(company_uuid);
  const { assignedLicenses, setAssignedLicenses } =
    useAssignedLicenses(user_id, company_uuid);

  const hasLicense = (service_uuid) =>
    assignedLicenses.some((license) => license.service_uuid === service_uuid);

  const handleUpdateLicenses = (service_uuid) => {
    if (hasLicense(service_uuid)) {
      setAssignedLicenses(
        assignedLicenses.filter(
          (license) => license.service_uuid !== service_uuid,
        ),
      );
    } else {
      const service = subscriptions?.find(
        (subscription) => subscription.service.uuid === service_uuid,
      ).service;
      setAssignedLicenses([
        ...assignedLicenses,
        {
          service_uuid: service.uuid,
        },
      ]);
    }
  };

  const getLicensesCount = async (service_uuid) => {
    try {
      const response = await getLicenses(company_uuid, service_uuid);
      return response?.data?.length ?? 0;
    } catch (error) {
      console.error("Error while fetching licenses count", error);
      return 0;
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await updateUserLicenses({
        user_uuid: user_id,
        company_uuid: company_uuid,
        service_uuids: assignedLicenses.map((license) => license.service_uuid),
      });
      if (response) {
        showSnackbar("Licenses updated successfully", "success");
        navigate(-1);
      }
    } catch (error) {
      console.log("Error while update licenses", error);
    }
  };

  return (
    <AssignLicense
      subscriptions={subscriptions}
      hasLicense={hasLicense}
      handleUpdateLicenses={handleUpdateLicenses}
      getLicensesCount={getLicensesCount}
      handleSubmit={handleSubmit}
    />
  );
}
