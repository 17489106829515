import { axiosInstance } from "../../config/axiosInstance";
import {
  Attribute,
  CameraConnectionAttributes,
  CameraConnectionResponse,
  CreateCameraConnectionPayload,
  UpdateCameraConnectionPayload,
} from "../../../interfaces/Camera";
import { AxiosResponse } from "axios";

const cameraConnectionURL = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  connection_uuid?: string,
) => {
  if (connection_uuid) {
    return `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}/camera-connections/${connection_uuid}`;
  }
  return `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}/camera-connections`;
};

export const createCameraConnection = (
  data: CreateCameraConnectionPayload,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    cameraConnectionURL(data.company_uuid, data.site_uuid, data.camera_uuid),
    data,
  );
};

export const getCameraConnectionDetails = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  connection_uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.get(
    cameraConnectionURL(company_uuid, site_uuid, camera_uuid, connection_uuid),
  );
};

export const deleteCameraConnection = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  connection_uuid: string,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    cameraConnectionURL(company_uuid, site_uuid, camera_uuid, connection_uuid),
  );
};

export const updateCameraConnection = (
  params: Record<string, string>,
  data: UpdateCameraConnectionPayload,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.patch(
    cameraConnectionURL(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ),
    data,
  );
};

export const addCameraConnectionAttribute = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  connection_uuid: string,
  data: Attribute,
): Promise<AxiosResponse<Attribute>> => {
  return axiosInstance.post(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, connection_uuid)}/attribute`,
    data,
  );
};

export const getCameraConnectionAttributes = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  uuid: string,
): Promise<AxiosResponse<CameraConnectionAttributes>> => {
  return axiosInstance.get(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, uuid)}/attribute`,
  );
};

export const deleteCameraConnectionAttribute = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  uuid: string,
  params: Record<string, string>,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, uuid)}/attribute`,
    {
      params: {
        ...params,
      },
    },
  );
};

export const editCameraConnectionAttribute = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  uuid: string,
  params: Record<string, string>,
  body: Pick<Attribute, "value">,
): Promise<AxiosResponse<Attribute>> => {
  return axiosInstance.patch(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, uuid)}/attribute`,
    body,
    {
      params: {
        ...params,
      },
    },
  );
};
export const activateCameraConnection = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, uuid)}/activate`,
  );
};

export const deactivateCameraConnection = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    `${cameraConnectionURL(company_uuid, site_uuid, camera_uuid, uuid)}/deactivate`,
  );
};
