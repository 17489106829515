/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import AssignPermission from "../../components/Users/v1/EditUser/AssignPermission";
import {
  getPermissions,
  updateUserPermission,
} from "../../apis/v1/Permissions";
import { useNavigate, useParams } from "react-router-dom";
import { showSnackbar } from "../../utilis/snackbar";

export default function UpdatePermissions() {
  const params = useParams();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const fetchPermissions = async () => {
    try {
      const response = await getPermissions();
      setPermissions(response.data);
    } catch (e) {
      console.log("Error fetching permissions", e);
    }
  };
  const fetchAssignedPermissions = async () => {
    try {
      const response = await getPermissions(params.user_id);
      setAssignedPermissions(response.data);
    } catch (e) {
      console.log("Error fetching permissions", e);
    }
  };

  function hasPermission(permission_uuid) {
    return assignedPermissions.some(
      (permission) => permission.uuid === permission_uuid,
    );
  }

  const handleUpdatePermissions = (permission_uuid) => {
    if (hasPermission(permission_uuid)) {
      setAssignedPermissions(
        assignedPermissions.filter(
          (permission) => permission.uuid !== permission_uuid,
        ),
      );
    } else {
      setAssignedPermissions([
        ...assignedPermissions,
        permissions.find((permission) => permission.uuid === permission_uuid),
      ]);
    }
  };

  useEffect(() => {
    fetchPermissions();
    fetchAssignedPermissions();
  }, [params.user_uuid]);

  const handleSubmit = async () => {
    try {
      const response = await updateUserPermission({
        user_uuid: params.user_id,
        company_uuid: params.company_uuid,
        permission_uuids: assignedPermissions.map(
          (permission) => permission.uuid,
        ),
      });
      if (response) {
        showSnackbar("Permissions updated successfully", "success");
        navigate(-1);
      }
    } catch (error) {
      console.log("Error while update permissions", error);
    }
  };

  return (
    <AssignPermission
      permissions={permissions}
      handleUpdatePermissions={handleUpdatePermissions}
      hasPermission={hasPermission}
      handleSubmit={handleSubmit}
    />
  );
}
