import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Box } from "@mui/system";
import MUISelect from "../../../../Controls/Select";
import { setFieldValueWithoutSpaces } from "../../../../../utilis/shared";

export default function EditOcuconTemplateStep1({
  template,
  handleSubmitForm,
  authTypes,
}) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required(),
    is_http: yup.boolean(),
    url_template: yup
      .string()
      .nullable()
      .when("is_http", (is_http) => {
        if (is_http) {
          return yup.string().required();
        }
      }),
    auth_type: yup.mixed(),
  });

  return (
    <Formik
      initialValues={{
        name: template.name || "",
        is_http: template.is_http || false,
        url_template: template.url_template || "",
        auth_type: template.auth_type || null,
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values) => handleSubmitForm(values)}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                type="text"
                id="name"
                data-testid="name"
                label="Template name*"
                fullWidth
                margin="normal"
                name="name"
                error={Boolean(errors.name && touched.name)}
                // helperText={errors && errors.name && touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                size="small"
              />
              <Box display={"flex"} justifyContent="end">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_http"
                      data-testid="is_http"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_http}
                    />
                  }
                  label="Is http connection?"
                />
              </Box>
              {values.is_http && (
                <>
                  <TextField
                    type="text"
                    id="url_template"
                    data-testid="url_template"
                    label="url template*"
                    fullWidth
                    margin="normal"
                    name="url_template"
                    onChange={(e) =>
                      setFieldValueWithoutSpaces(
                        e,
                        "url_template",
                        setFieldValue,
                      )
                    }
                    onBlur={handleBlur}
                    value={values.url_template}
                    size="small"
                  />
                  <MUISelect
                    dataTestid="auth_type"
                    label="Auth Type"
                    labelId="auth_type"
                    id="auth_type"
                    name="auth_type"
                    handleChange={handleChange}
                    list={authTypes}
                    margin="normal"
                    value={values.auth_type}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    sx={{ width: "100%" }}
                    type="submit"
                    data-testid="saveBtn"
                  >
                    Save and go next
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    onClick={() =>
                      navigate("/connection/ocucon-camera-connection-templates")
                    }
                    color="error"
                    sx={{ width: "100%" }}
                    data-testid="cancelBtn"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
