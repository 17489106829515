import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RulesFilters from "../NotificationsRules/RulesFilters";
import NotificationsRulesTable from "../NotificationsRules";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { getRuleIntegrations } from "../../apis/v1/Notifications/Integrations";
import { getFlattenChildrenNodesList } from "../../utilis/format-child-nodes";
import {
  getCompanyRules,
  getRuleTypes,
} from "../../apis/v1/Notifications/Rules";
import { RouterHistoryState } from "../../interfaces/RouterHistoryState";

interface CompanyNotificationRulesListProps {
  currentRouteState: RouterHistoryState;
}

export default function CompanyNotificationRulesList({
  currentRouteState,
}: CompanyNotificationRulesListProps) {
  const [renderedRules, setRenderedRules] = useState(null);
  const [originalRules, setOriginalRules] = useState(null);
  const [rules, setRules] = useState(null);
  const [ruleIntegration, setRuleIntegration] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [ruleTypes, setRuleTypes] = useState([]);

  const [filters, setFilters] = useState({
    node_name: "",
    node_type: "",
    integration_type: "",
    rule_type: "",
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (rules?.length > 0) {
      let integrations = [];
      rules?.map(async (rule) => {
        const res = await getRuleIntegrations(params.company_uuid, rule.uuid);
        integrations.push({
          rule_uuid: rule.uuid,
          types: res.data.map((integration) => integration.type),
        });
        setRuleIntegration((integ) => integrations);
      });
      getCompanyRootNodes(params.company_uuid, params.root_node_uuid).then(
        (res) => {
          const nodesList = getFlattenChildrenNodesList(res.data);
          setNodes(nodesList);
        },
      );
      setRenderedRules(rules);
      setOriginalRules(rules);
    }
  }, [rules]);

  useEffect(() => {
    if (nodes?.length > 0) {
      setRules(
        rules.map((rule) => {
          return {
            ...rule,
            node: nodes?.find((node) => node.uuid === rule.node_uuid),
            integrations: ruleIntegration?.find(
              (integration) => integration.rule_uuid === rule.uuid,
            ),
          };
        }),
      );
    }
  }, [nodes]);

  useEffect(() => {
    getCompanyRulesRequest();
    getRuleTypes().then((res) => {
      setRuleTypes(res.data);
    });
  }, [params.company_uuid]);

  const getCompanyRulesRequest = () => {
    getCompanyRules(params.company_uuid).then((res) => {
      if (res) {
        setRules(res.data);
      }
    });
  };
  return (
    <Card variant="outlined">
      <CardHeader
        title="Company Notification Rules"
        subheader="A list of notification rules assigned to this company and its nodes"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/root_node/${params?.root_node_uuid}/notifications/add-rule`,
              {
                state: {
                  ...currentRouteState,
                },
              },
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <RulesFilters
          filters={filters}
          setFilters={setFilters}
          setRenderedRules={setRenderedRules}
          originalRules={originalRules}
          ruleTypes={ruleTypes}
        />
      </CardContent>
      <CardContent>
        <NotificationsRulesTable
          renderedRules={renderedRules}
          setRenderedRules={setRenderedRules}
          company_uuid={params.company_uuid}
          currentRouteState={currentRouteState}
          root_node_uuid={params.root_node_uuid}
          setOriginalRules={setOriginalRules}
          originalRules={originalRules}
        />
      </CardContent>
    </Card>
  );
}
