import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { showSnackbar } from "../../../utilis/snackbar";
import ConfirmationPopup from "../../ConfirmationPopup";
import { deleteCompanyIncidentClass } from "../../../apis/v1/DetectionZone/CompanyIncidentClasses";

export default function DeleteCompanyIncidentClass({
  incident_class_uuid,
  companyIncidentClasses,
  setCompanyIncidentClasses,
  company_uuid,
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const getListWithoutDeleted = () => {
    return companyIncidentClasses.filter((label) => label.uuid !== incident_class_uuid);
  };

  const submitDeleteLabel = () => {
    deleteCompanyIncidentClass(company_uuid, incident_class_uuid).then((res) => {
      if (res) {
        const listWithoutDeleted = getListWithoutDeleted();
        setCompanyIncidentClasses(listWithoutDeleted);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Company Incident Class"
        dialogBody="Are you sure you want to delete this incident class?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteLabel()}
      />
    </>
  );
}
