// convert points string to array so we can make iteration on it while dragging
export const mapToConvertPointsToArray = (points: any) => {
  let splitedPoints = points.split(" ");
  splitedPoints = splitedPoints.map((point: any) => {
    let pointItem = point.split(",");
    return {
      x: Number(pointItem[0]),
      y: Number(pointItem[1]),
    };
  });
  return splitedPoints;
};

// convert points array to string to send it to points attribute in html
export const mapToConvertPointsToString = (points: any) => {
  let stringPoints = points
    .map((point: any) => {
      return `${point.x},${point.y}`;
    })
    .join(" ");
  return stringPoints;
};

// Returns a number always between 0 and 1 for normalized points
export const clampBetween0And1 = (n: number) => {
  const min = 0;
  const max = 1;
  return Math.min(Math.max(n, min), max);
};

// Points between 0 and 1
export const normalizedPoints = (
  points: { x: number; y: number }[],
  svgWidth: number,
  svgHeight: number,
) => {
  return points.map((point: any) => {
    return {
      x: clampBetween0And1(point.x / svgWidth),
      y: clampBetween0And1(point.y / svgHeight),
    };
  });
};

// Real points values
export const relativePoints = (
  points: any,
  svgWidth: number,
  svgHeight: number,
) => {
  return points.map((point) => {
    return {
      x: point.x * svgWidth,
      y: point.y * svgHeight,
    };
  });
};
