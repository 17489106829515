import { axiosInstance } from "../../config/axiosInstance";
import { Camera, CreateCameraPayload } from "../../../interfaces/Camera";
import { AxiosPromise } from "axios";

export const getCameraDetails = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
): AxiosPromise<Camera> => {
  return axiosInstance.get(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}`,
  );
};

export const editCamera = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  data: CreateCameraPayload,
): AxiosPromise<Camera> => {
  return axiosInstance.patch(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}`,
    data,
  );
};

export const deleteCamera = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}`,
  );
};

export const createCamera = (
  company_uuid: string,
  site_uuid: string,
  data: CreateCameraPayload,
): AxiosPromise<Camera> => {
  return axiosInstance.post(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras`,
    data,
  );
};
//TODO the code below is used in a page which is not used, consider removing it
export const getCameraList = () => {
  return axiosInstance.get(`/organisation-management/v1/cameras`);
};

export const getCameraWithSiteUUID = ({ company_uuid, site_uuid }) => {
  return axiosInstance.get(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras`,
  );
};

export const getAuthTypes = () => {
  return axiosInstance.get(`/organisation-management/v1/auth-types`);
};
