import { AxiosPromise } from "axios";
import { axiosInstance } from "../../config/axiosInstance";
import {
  CompanyIncidentClassPayload,
  GIncidentClass,
} from "../../../interfaces/IncidentClass";

const companyIncidentClassURL = (company_uuid: string): string =>
  `/detection-zone/v1/companies/${company_uuid}/incident-classes`;

export const getCompanyIncidentClasses = (
  company_uuid: string,
): AxiosPromise<GIncidentClass[]> => {
  return axiosInstance.get(companyIncidentClassURL(company_uuid));
};

export const addCompanyIncidentClass = (
  company_uuid: string,
  data: CompanyIncidentClassPayload,
): AxiosPromise<string> => {
  return axiosInstance.post(companyIncidentClassURL(company_uuid), data);
};

export const deleteCompanyIncidentClass = (
  company_uuid: string,
  incident_class_uuid: string,
): AxiosPromise<string> => {
  return axiosInstance.delete(
    `${companyIncidentClassURL(company_uuid)}/${incident_class_uuid}`,
  );
};
