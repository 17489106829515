import { Box, Button, CardContent, Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UserOrgAssignments from "./UserOrgAssignments";

export default function UserDetails({ customer, company }) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      <Box component={"h4"} my={0}>
        Details
      </Box>

      <Box component={"p"} my={0}>
        Username: {customer.username}
      </Box>
      <Box component={"p"} my={0}>
        Email: {customer.email}
      </Box>
      <Box component={"p"} my={0}>
        Email verified: {customer.emailVerified ? "Yes" : "No"}
      </Box>
      <Box component={"p"} my={0}>
        Company:{" "}
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/company/${company.uuid}/root-node/${company.root_node_uuid}`,
            )
          }
        >
          {company?.name}
        </Link>
      </Box>
      <UserOrgAssignments customer={customer} />
      <Button
        variant={"outlined"}
        sx={{ my: 1, mb:2 }}
        onClick={() =>
          navigate(
            `/company/${params.company_uuid}/node/${params.node_uuid}/edit-user/${params.user_id}`,
          )
        }
      >
        Edit
      </Button>
    </>
  );
}
