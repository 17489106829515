import { AttributeTypes } from "../interfaces/Camera";

export const template_attributes_types = (is_http: boolean) => {
  if (is_http) {
    return [
      AttributeTypes.URL_REPLACEMENT_VALUE,
      AttributeTypes.QUERY_PARAMS,
      AttributeTypes.SETTINGS,
    ];
  } else {
    return [AttributeTypes.SETTINGS];
  }
};
