/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, CardHeader } from "@mui/material";
import { useParams } from "react-router-dom";
import { SubscriptionHistory } from "../../interfaces/Subscription";
import { useEffect, useState } from "react";
import { getSubscriptionHistories } from "../../apis/v1/Subscriptions/Subscriptions";
import SubscriptionsLHistoryTable from "./SubscriptionHistoryTable";

export default function SubscriptionHistoriesList() {
  const [subscriptionHistories, setSubscriptionHistories] = useState<
    SubscriptionHistory[]
  >([]);
  const params = useParams();

  useEffect(() => {
    getSubscriptionHistories(
      params.service_uuid,
      params.subscription_uuid,
      params.company_uuid,
    ).then((res) => {
      if (res) {
        setSubscriptionHistories(res.data);
      }
    });
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader title="Subscription Audit History" subheader="List of audit history for this subscription" />

      <CardContent>
        {subscriptionHistories && (
          <SubscriptionsLHistoryTable
            subscriptionHistories={subscriptionHistories}
          />
        )}
      </CardContent>
    </Card>
  );
}
