import { useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationPopup from "../ConfirmationPopup";
import { showSnackbar } from "../../utilis/snackbar";
import { deleteRule } from "../../apis/v1/Notifications/Rules";
import { CompanyRule } from "../../interfaces/Notifications";

interface DeleteNotificationRuleProps {
  company_uuid: string;
  rule_uuid: string;
  rules: CompanyRule[],
  setRules: React.Dispatch<CompanyRule[]>,
  originalRules: CompanyRule[],
  setOriginalRules: React.Dispatch<CompanyRule[]>,
}

export default function DeleteNotificationRule({
  company_uuid,
  rule_uuid,
  rules,
  setRules,
  originalRules,
  setOriginalRules,
}: DeleteNotificationRuleProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getRulesListWithoutDeleted = () => {
    return rules.filter((rule) => rule.uuid !== rule_uuid);
  };

  const submitDeleteSite = () => {
    deleteRule(company_uuid, rule_uuid).then((res) => {
      if (res) {
        const rulesListWithoutDeleted = getRulesListWithoutDeleted();
        setRules(rulesListWithoutDeleted);
        setOriginalRules(
          originalRules.filter((rule) => rule.uuid !== rule_uuid),
        );
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Notification Rule"
        dialogBody="Are you sure you want to delete this notification rule?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
