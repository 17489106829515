import { TierPayload, TierResponse } from "../../../interfaces/Tier";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const paymentServiceURL = "payment/v1";
const generateTierURL = (service_uuid: string) => {
  return `${paymentServiceURL}/services/${service_uuid}/tiers`;
};

export const createTier = (
  service_uuid: string,
  data: TierPayload,
): AxiosPromise<TierResponse> => {
  return axiosInstance.post(generateTierURL(service_uuid), data);
};

export const getTiers = (
  service_uuid: string,
): AxiosPromise<TierResponse[]> => {
  return axiosInstance.get(generateTierURL(service_uuid));
};

export const getTierDetails = (
  service_uuid: string,
  tier_uuid: string,
): AxiosPromise<TierResponse> => {
  return axiosInstance.get(`${generateTierURL(service_uuid)}/${tier_uuid}`);
};

export const editTier = (
  service_uuid: string,
  tier_uuid: string,
  data: TierPayload,
): AxiosPromise<TierResponse> => {
  return axiosInstance.patch(
    `${generateTierURL(service_uuid)}/${tier_uuid}`,
    data,
  );
};

export const deleteTier = (
  service_uuid: string,
  tier_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(`${generateTierURL(service_uuid)}/${tier_uuid}`);
};

export const unhideTier = (
  service_uuid: string,
  tier_uuid: string,
): AxiosPromise<TierResponse> => {
  return axiosInstance.post(
    `${generateTierURL(service_uuid)}/${tier_uuid}/unhide`,
    {},
  );
};

export const hideTier = (
  service_uuid: string,
  tier_uuid: string,
): AxiosPromise<TierResponse> => {
  return axiosInstance.post(
    `${generateTierURL(service_uuid)}/${tier_uuid}/hide`,
    {},
  );
};

export const getTiersPerCompany = (
  company_uuid: string,
): AxiosPromise<TierResponse[]> => {
  return axiosInstance.get(`${paymentServiceURL}/tiers`, {
    params: {
      company_uuid,
    },
  });
};

export const getAllTiers = (): AxiosPromise<TierResponse[]> => {
  return axiosInstance.get(`${paymentServiceURL}/tiers`, {});
};
