import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { Dispatch, useState } from "react";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteReason } from "../../apis/v1/Credits/Reasons";
import { Reason } from "../../interfaces/Credits";

interface PropsType {
  reasonUUID: string;
  reasons: Reason[];
  setReasons: Dispatch<Reason[]>;
}

export default function DeleteReason({
  reasonUUID,
  reasons,
  setReasons,
}: Readonly<PropsType>) {
  const [openDialog, setOpenDialog] = useState(false);

  const filterReasons = (): Reason[] => {
    return reasons.filter((reason: Reason) => reason.uuid !== reasonUUID);
  };

  const submitDeleteIncidentCause = async () => {
    try {
      const response = await deleteReason(reasonUUID);
      if (response) {
        const filteredReasons: Reason[] = filterReasons();
        setReasons(filteredReasons);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    } catch (error) {
      console.log("Error delete reason", error);
    }
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Credit Reason"
        dialogBody="Are you sure you want to delete this credit reason?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteIncidentCause()}
      />
    </>
  );
}
