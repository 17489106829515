import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getCompanyIncidentClasses } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";

export default function IncidentCauseListFilters({
  filters,
  setFilters,
  setRenderedIncidentCauses,
  originalIncidentCauses,
  company_uuid
}) {
  const [showReset, setShowReset] = useState<boolean>(false);
  const [incidentClasses, setIncidentClasses] = useState([]);

  useEffect(() => {
    getCompanyIncidentClasses(company_uuid).then((res) => {
      setIncidentClasses(res?.data);
    });
  }, []);

  const handleChange = (event) => {
    setFilters({
      ...filters,
      incident_class: event.target.value,
    });
  };

  const handleFilter = () => {
    setShowReset(true);
    let filteredData = originalIncidentCauses.filter((cause) => {
      if (filters.incident_class) {
        return filters.incident_class.includes(cause.incident_class.name);
      }
      return cause;
    });
    setRenderedIncidentCauses(
      filteredData.map((item) => {
        return {
          ...item,
          incident_class_name: item.incident_class.name,
          resplution_type_name: item.resolution_type.name,
        };
      }),
    );
  };

  return (
    <Grid container spacing={2} py={1}>
      <Grid item xs={12} sm={3}>
        <FormControl size="small" fullWidth>
          <InputLabel id="incident_class">Incident Class</InputLabel>
          <Select
            labelId="incident_class"
            id="incident_class"
            multiple
            value={filters?.incident_class || []}
            onChange={handleChange}
            input={<OutlinedInput label="Incident Class" />}
          >
            {incidentClasses.map((class_item) => (
              <MenuItem key={class_item.uuid} value={class_item.name}>
                {class_item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={3}
      >
        <Button variant="outlined" onClick={handleFilter}>
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setFilters(null);
              setRenderedIncidentCauses(originalIncidentCauses);
              setShowReset(false);
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
