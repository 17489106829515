import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";

function PrivateRoute({ children }: any) {
  const authenticated = localStorage.getItem("access_token");

  if (authenticated) {
    return <Layout>{children}</Layout>;
  } else {
    return <Navigate to="/" />;
  }
}

export default PrivateRoute;
