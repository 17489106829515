import { AxiosPromise, AxiosResponse } from "axios";
import { axiosInstance } from "../../config/axiosInstance";
import { CameraDefaultSettings } from "../../../interfaces/Settings";

const CAMERA_DEFAULTS_BASE_URL =
  "/organisation-management/v1/settings/camera-defaults";

export const getCameraDefaultSettings = (): Promise<
  AxiosResponse<CameraDefaultSettings[]>
> => {
  return axiosInstance.get(CAMERA_DEFAULTS_BASE_URL);
};

export const addCameraDefaultSettings = (
  data: Omit<CameraDefaultSettings, "uuid">,
): AxiosPromise<CameraDefaultSettings> => {
  return axiosInstance.post(CAMERA_DEFAULTS_BASE_URL, data);
};

export const getSingleCameraDefaultSettings = (
  camera_settings_uuid: string,
): AxiosPromise<CameraDefaultSettings> => {
  return axiosInstance.get(
    `${CAMERA_DEFAULTS_BASE_URL}/${camera_settings_uuid}`,
  );
};

export const editCameraDefaultSettings = (
  camera_settings_uuid: string,
  data: Omit<CameraDefaultSettings, "uuid" | "data_type">,
): AxiosPromise<CameraDefaultSettings> => {
  return axiosInstance.patch(
    `${CAMERA_DEFAULTS_BASE_URL}/${camera_settings_uuid}`,
    data,
  );
};

export const deleteCameraDefaultSettings = (
  camera_settings_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${CAMERA_DEFAULTS_BASE_URL}/${camera_settings_uuid}`,
  );
};
