import { createTheme } from "@mui/material/styles";
import ButtonOverrides from "./Button";
import CardOverrides from "./Card";
import AlertOverrides from "./Alert";
import IconButtonOverrides from "./IconButton";
import DividerOverrides from "./Divider";

let theme = createTheme({
  palette: {
    primary: {
      main: "#16afca",
    },
    success: {
      main: "#47ac80",
    },
    error: {
      main: "#fd6450",
    },
    secondary: {
      main: "#16afca",
    },
  },
  typography: {
    fontFamily: [
      "MuseoSans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = createTheme(theme, {
  ...theme,
  components: {
    ...ButtonOverrides(theme),
    ...AlertOverrides(),
    ...CardOverrides(),
    ...IconButtonOverrides(),
    ...DividerOverrides(),
  },
});

export default theme;
