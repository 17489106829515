import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import MUISelect from "../../../Controls/Select";
import { CameraDefaultSettings } from "../../../../interfaces/Settings";

interface AddDefaultSettingsFormProps {
  handleSubmit: (values: Omit<CameraDefaultSettings, 'uuid'>) => void;
}

export default function AddDefaultSettingsForm({
  handleSubmit,
}: AddDefaultSettingsFormProps) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    default_value: yup.string().required("Required"),
    data_type: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        default_value: "",
        data_type: "",
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="name"
            size="small"
          />
          <MUISelect
            error={errors.data_type && touched.data_type && errors.data_type}
            value={values.data_type}
            label={"Data Type"}
            required
            name="data_type"
            handleChange={handleChange}
            handleBlur={handleBlur}
            list={["STRING", "BOOL", "INT", "FLOAT", "TIME"]}
            labelId={"data_type"}
            id={"data_type"}
            dataTestid="data_type"
            styles={{
              mt: 2,
            }}
          />
          <TextField
            // type="number"
            id="default_value"
            label="Default Value*"
            fullWidth
            margin="normal"
            name="default_value"
            error={Boolean(errors.default_value && touched.default_value)}
            helperText={
              errors.default_value &&
              touched.default_value &&
              errors.default_value
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.default_value}
            data-testid="default_value"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description*"
            fullWidth
            margin="normal"
            name="description"
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description && errors.description
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            data-testid="description"
            multiline
            rows={3}
            size="small"
          />

          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate("/settings/camera/default-settings")}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
