import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Consumable } from "../../interfaces/Consumable";
import { TierResponse } from "../../interfaces/Tier";
import MUISelect from "../Controls/Select";
import { CURRENCIES, stringifyAmountNumber } from "../../utilis/shared";
import PricesForm from "../Tiers/PricesForm";
import { Country } from "../../interfaces/Address";
import { useAppSelector } from "../../hooks/useStoreHook";

interface AddConsumableAddonsFormProps {
  tiers: TierResponse[];
  handleSubmit: (values: Omit<Consumable, "uuid">) => void;
  countries: Country[];
}

export default function AddConsumableAddonsForm({
  handleSubmit,
  tiers,
  countries,
}: Readonly<AddConsumableAddonsFormProps>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const initialValue: any = {
    amount: "",
    default_price: {
      unit_amount: "",
      currency: "",
      unit_price_overrides: [],
    },
    tier_uuid: "",
  };

  const currencyOptionItemSchema = yup.object().shape({
    currency: yup.string(),
    unit_amount: yup.number().when("currency", {
      is: (currency) => !!currency,
      then: yup.number().required("Required").min(0, "Must be positive"),
      otherwise: yup.number(),
    }),
  });

  const unitPriceOverridesSchema = yup.object().shape({
    country_codes: yup.array().of(yup.string()),
    unit_price: yup.object().shape({
      amount: yup.number().min(0, "Must be positive"),
      currency_code: yup.string(),
    }),
  });

  const defaultPriceSchema = yup.object().shape({
    unit_amount: yup.number().required("Required").min(0, "Must be positive"),
    currency: yup.string().required("Required"),
    currency_options: yup.array().of(currencyOptionItemSchema),
    unit_price_overrides: yup.array().of(unitPriceOverridesSchema),
  });

  const schema: yup.SchemaOf<any> = yup.object().shape({
    amount: yup.number().required("Required").positive("Must be positive"),
    default_price: defaultPriceSchema.required(),
    tier_uuid: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={(values: any) =>
        handleSubmit({
          ...values,
          default_price: {
            ...values.default_price,
            unit_amount: values.default_price.unit_amount * 100,
            unit_price_overrides: values.default_price.unit_price_overrides.map(
              (unit_price_override: any) => {
                return {
                  ...unit_price_override,
                  unit_price: {
                    ...unit_price_override.unit_price,
                    amount: stringifyAmountNumber(unit_price_override.unit_price.amount),
                  },
                };
              },
            ),
          },
        })
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="number"
            id="amount"
            label="Amount*"
            fullWidth
            margin="normal"
            name="amount"
            error={Boolean(errors.amount && touched.amount)}
            helperText={errors.amount && touched.amount && errors.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
            data-testid="amount"
            size="small"
          />

          <Box component={"h4"} mb={0}>
            Price
          </Box>
          <small> Note: this price will be the default for any purchase.</small>
          <Grid
            container
            spacing={2}
            alignItems={"flex-start"}
            sx={{ pt: 2, pb: 1 }}
          >
            <Grid item xs={6}>
              <MUISelect
                error={
                  errors?.default_price?.currency &&
                  touched?.default_price?.currency &&
                  errors?.default_price?.currency
                }
                value={values?.default_price?.currency}
                label={"Currency"}
                dataTestid={"currency"}
                required
                name="default_price.currency"
                handleChange={handleChange}
                handleBlur={handleBlur}
                list={CURRENCIES}
                labelId={"label-currency"}
                id={"select-currency"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                id="unit_amount"
                label="Unit Amount*"
                fullWidth
                name="default_price.unit_amount"
                error={Boolean(
                  errors?.default_price?.unit_amount &&
                    touched?.default_price?.unit_amount,
                )}
                helperText={
                  Boolean(
                    errors?.default_price?.unit_amount &&
                      touched?.default_price?.unit_amount,
                  ) && errors?.default_price?.unit_amount
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.default_price.unit_amount}
                size="small"
              />
            </Grid>
          </Grid>
          <Box component={"h4"} my={1}>
            Additional Currency options
          </Box>
          <small>
            {" "}
            Note: Any additinal currencies will be added to help the customer
            pay in his local currency.
          </small>

          <PricesForm
            values={values}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            countries={countries}
            setFieldValue={setFieldValue}
          />

          <MUISelect
            dataTestid="tier_uuid"
            label="Tier"
            styles={{ mt: 2 }}
            labelId="tier"
            id="tier_uuid"
            name="tier_uuid"
            required
            handleChange={handleChange}
            value={values?.tier_uuid}
            keyItem="uuid"
            keyValue="name"
            list={tiers}
            renderValue={() =>
              tiers?.find((tier) => tier.uuid === values?.tier_uuid)?.name
            }
            error={errors?.tier_uuid && touched?.tier_uuid && errors?.tier_uuid }
          />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
