/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../../components/PagesHeader";
import {
  editCameraDefaultSettings,
  getSingleCameraDefaultSettings,
} from "../../../apis/v1/Settings/CameraDefaults";
import EditCameraDefaultSettingsForm from "../../../components/Settings/v1/CameraDefaultSettings/EditDefaultSettingsForm";
import { parseOverrideValue, showSnackbar } from "../../../utilis/snackbar";

export default function EditCameraDefaultSettings() {
  const navigate = useNavigate();
  const params = useParams();
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    getSingleCameraDefaultSettings(params.uuid).then((res) => {
      res && setDefaultValues(res.data);
    });
  }, []);

  const handleSubmit = (values: any) => {
    const default_value = parseOverrideValue(values.default_value);
    editCameraDefaultSettings(params.uuid, {
      ...values,
      default_value,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit Camera Default Settings" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {defaultValues && (
            <EditCameraDefaultSettingsForm
              defaultValues={defaultValues}
              handleSubmit={handleSubmit}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
