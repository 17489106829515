import { Box, Grid } from "@mui/material";
import { rule_types } from "../../utilis/enum";
import { CompanyRule } from "../../interfaces/Notifications";
import { OrgStructureNode } from "../../interfaces/OrgStructure";

interface RuleDetailsCardProps {
  rule: CompanyRule,
  node: OrgStructureNode
}

export default function RuleDetailsCard({ rule, node }: RuleDetailsCardProps) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12} sm={6} md={4}>
        <Box component={"p"} my={0}>
          Node name: {node?.name}
        </Box>
        <Box component={"p"} my={0}>
          Node Type: {node?.type}
        </Box>
        <Box component={"p"} my={0}>
          Rule Type: {rule?.type}
        </Box>
        <Box component={"p"} my={0}>
          Is Active?: {rule?.is_active ? "Yes" : "No"}
        </Box>
        <Box component={"p"} my={0}>
          Condition: {rule?.condition}
        </Box>
        {rule.type === rule_types.RULE_INCIDENT_CREATED_COUNT && (
          <>
            <Box component={"p"} my={0}>
              Target incidents count: {rule?.target_incident_count || "-"}
            </Box>
            <Box component={"p"} my={0}>
              Evaluation period (seconds): {rule?.evaluation_period || "-"}
            </Box>
          </>
        )}
        {rule.type === rule_types.RULE_INCIDENT_OPEN_DURATION && (
          <>
            <Box component={"p"} my={0}>
              Target open duration (seconds):{" "}
              {rule?.target_open_duration || "-"}
            </Box>
          </>
        )}
        <Box component={"p"} mt={0}>
          Sleep period (seconds): {rule?.sleep_period}
        </Box>
      </Grid>
    </Grid>
  );
}
