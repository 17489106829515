import { axiosInstance } from "../../config/axiosInstance";
import {
  Attribute,
  CameraConnectionResponse,
  CompanyTemplateAttributesResponse,
  CreateCameraConnectionPayload,
  UpdateCameraConnectionPayload,
} from "../../../interfaces/Camera";
import { AxiosResponse } from "axios";

const OCUCON_TEMPLATE_BASE_URL =
  "/organisation-management/v1/camera-connection-templates";

export const getTemplateDetails = (uuid: string) => {
  return axiosInstance.get(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}`);
};

export const editTemplateDetails = (
  uuid: string,
  data: UpdateCameraConnectionPayload,
) => {
  return axiosInstance.patch(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}`, {
    ...data,
  });
};

export const deleteAttribute = (
  uuid: string,
  params: Record<string, string>,
) => {
  return axiosInstance.delete(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}/attribute`, {
    params,
  });
};

export const getOcuconTemplateList = (is_active?: boolean) => {
  return axiosInstance.get(`${OCUCON_TEMPLATE_BASE_URL}`, {
    params: {
      is_active,
    },
  });
};

export const deleteTemplate = (uuid: string) => {
  return axiosInstance.delete(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}`);
};

export const createTemplate = (data: CreateCameraConnectionPayload) => {
  return axiosInstance.post(OCUCON_TEMPLATE_BASE_URL, data);
};

export const createTemplateAttribute = (uuid: string, data: Attribute) => {
  return axiosInstance.post(
    `${OCUCON_TEMPLATE_BASE_URL}/${uuid}/attribute`,
    data,
  );
};

export const getOcuconTemplateAttributesByUUID = (
  uuid: string,
): Promise<AxiosResponse<CompanyTemplateAttributesResponse>> => {
  return axiosInstance.get(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}/attribute`);
};

export const activateOcuconTemplate = (
  uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}/activate`);
};

export const deactivateOcuconTemplate = (
  uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(`${OCUCON_TEMPLATE_BASE_URL}/${uuid}/deactivate`);
};
