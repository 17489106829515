/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import AddCreditForm from "../../components/Credits/AddCreditForm";
import { CreditPayload } from "../../interfaces/Credits";
import { createCredit } from "../../apis/v1/Credits/Credits";
import { useNavigate, useParams } from "react-router-dom";
import { showSnackbar } from "../../utilis/snackbar";
import { getConsumables } from "../../apis/v1/Services/Consumables";
import { getReasons } from "../../apis/v1/Credits/Reasons";

export default function AddCredit() {
  const [consumables, setConsumables] = useState([]);
  const [reasons, setReasons] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  const fetchConsumables = async () => {
    try {
      const response = await getConsumables(params.service_uuid);
      if (response.data) {
        setConsumables(response.data);
      }
    } catch (error) {
      console.log("error fetching consumables", error);
    }
  };

  const fetchReasons = async () => {
    try {
      const response = await getReasons();
      if (response.data) {
        setReasons(response.data);
      }
    } catch (error) {
      console.log("error fetching reasons", error);
    }
  };

  useEffect(() => {
    fetchReasons();
    if(params.service_uuid){
        fetchConsumables();
    }
  }, [params.service_uuid])

  const handleSubmitForm = async (values: CreditPayload) => {
    try {
      const response = await createCredit(
        params.service_uuid,
        values.consumable_uuid,
        {
          ...values,
          company_uuid: params.company_uuid,
          
        },
      );
      if (response) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    } catch (error) {
      console.log("Error submit reason", error);
    }
  };
  return (
    <>
      <PagesHeader title="Add Credit" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddCreditForm handleSubmitForm={handleSubmitForm} reasons={reasons} consumables={consumables} />
        </Grid>
      </Grid>
    </>
  );
}
