import { IconButton, Link, TableCell, TableRow, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { Customer, User } from "../../../../interfaces/User";
import MUITable from "../../../Table";
import { Company } from "../../../../interfaces/Company";
import { OrgStructureNode } from "../../../../interfaces/OrgStructure";

interface AllUsersTableProps {
  users: Array<Customer>;
  rootNodes: Array<OrgStructureNode>;
  companies: Array<Company>;
}

export default function AllUsersTable({
  users,
  rootNodes,
  companies,
}: Readonly<AllUsersTableProps>) {
  const navigate = useNavigate();

  const getRootNodePerCompany = (company_uuid) => {
    const rootNode = rootNodes?.find(
      (node) => node?.company_uuid === company_uuid,
    );
    return rootNode;
  };

  const getUserCompany = (company_uuid: string) => {
    const company = companies?.find((company) => company.uuid === company_uuid);
    return company;
  };

  const renderTableBody = (row) => {
    return (
      <TableRow key={row.id} data-testid="user-row">
        <TableCell>{row.username}</TableCell>
        <TableCell>{row.firstName || "-"}</TableCell>
        <TableCell>{row.lastName || "-"}</TableCell>
        <TableCell>{row.email || "-"}</TableCell>
        <TableCell>{row.emailVerified ? "Yes" : "No"}</TableCell>
        <TableCell>
          {row?.org_structure_assignments?.length > 0 ? "Yes" : "No"}
        </TableCell>
        <TableCell>
          {(row?.company_uuid && row?.company_uuid !== '*') ? (
            <Tooltip title={"View user's company"} placement="top">
              <Link
                onClick={() =>
                  navigate(
                    `/company/${row?.company_uuid}/root-node/${getRootNodePerCompany(row?.company_uuid)?.uuid}`,
                  )
                }
                sx={{
                  cursor: "pointer",
                }}
              >
                {getUserCompany(row.company_uuid)?.name || "-"}
              </Link>
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="right">
          {(row?.company_uuid && row?.company_uuid !== '*')  && (
            <Tooltip title="View user details" placement="top">
              <IconButton
                size="small"
                data-testid="view-user-button"
                onClick={() =>
                  navigate(
                    `/company/${row.company_uuid}/node/${getRootNodePerCompany(row.company_uuid)?.uuid}/user/${row.id}`,
                    {
                      state: {
                        user_name: row.username,
                      },
                    },
                  )
                }
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "username",
          name: "Username",
        },
        {
          id: "firstName",
          name: "First Name",
        },
        {
          id: "lastName",
          name: "Last Name",
        },
        {
          id: "email",
          name: "Email",
        },
        {
          id: "emailVerified",
          name: "Verified email",
        },
        {
          id: "org_structure_assignments",
          name: "Assigned within organisation",
        },
        {
          id: "company_uuid",
          name: "Company",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={users}
      tableBodyRow={(row: User) => renderTableBody(row)}
    />
  );
}
