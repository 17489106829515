/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editTemplateDetails,
  getTemplateDetails,
} from "../../../apis/v1/Camera/OcuconCameraTemplates";
import EditOcuconTemplateStep1 from "../../../components/Cameras/v1/OcuconCameraConnectionTemplate/Edit/Step1";
import PagesHeader from "../../../components/PagesHeader";
import { showSnackbar } from "../../../utilis/snackbar";
import { getAuthTypes } from "../../../apis/v1/Camera/Camera";

export default function EditOcuconTemplate() {
  const [template, setTemplate] = useState({
    uuid: "",
    template_url: "",
    name: "",
    is_http: "",
  });
  const [authTypes, setAuthTypes] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getAuthTypes().then((res) => res && setAuthTypes(res.data));
    getTemplateDetails(params.uuid).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
  }, []);

  const handleSubmitForm = (values) => {
    editTemplateDetails(params.uuid, values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(
          `/connection/ocucon-camera-connection-template/edit/attributes/${template.uuid}`,
        );
      }
    });
  };
  return (
    <>
      <PagesHeader title="Edit Ocucon Camera Connection Template" />

      <EditOcuconTemplateStep1
        authTypes={authTypes}
        handleSubmitForm={handleSubmitForm}
        template={template}
      />
    </>
  );
}
