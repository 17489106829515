/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { Feature } from "../../interfaces/Features";
import { getFeatures } from "../../apis/v1/Services/Features";
import FeaturesListTable from "../../components/Features/FeaturesListTable";

export default function FeaturesList() {
  const navigate = useNavigate();
  const params = useParams();
  const [features, setFeatures] = useState<Feature[]>([]);
  useEffect(() => {
    getFeatures(params.service_uuid).then((res) => setFeatures(res.data));
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader title="Feature" subheader="List of features" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/service/${params.service_uuid}/feature/add`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <FeaturesListTable features={features} setFeatures={setFeatures} />
      </CardContent>
    </Card>
  );
}
