import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import DeleteCameraConnection from "./DeleteCameraConnection";
import NoDataFound from "../../../Table/NoDataFound";
import {
  ACTIVATED_CONNECTION_TOOLTIP,
  DEACTIVATE_CONNECTION_NOTE,
} from "../../../../utilis/shared";
import { Camera, CameraConnection } from "../../../../interfaces/Camera";

type Props = {
  cameraConnections: CameraConnection;
  getCameraDetailsRequest: () => void;
  handleChangeStatus: (uuid: string, status: boolean) => void;
  location_state_details: any;
  camera_details: Camera;
};

export default function CameraConnectionTable({
  cameraConnections,
  getCameraDetailsRequest,
  handleChangeStatus,
  location_state_details,
  camera_details,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Box borderTop={"1px solid #e7e7e7"} mb={3}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Is Http</TableCell>
              <Tooltip
                title={ACTIVATED_CONNECTION_TOOLTIP}
                placement="top-start"
              >
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              </Tooltip>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {cameraConnections && (
            <TableBody>
              <TableRow data-testid="camera-connection-row">
                <TableCell>{cameraConnections.name}</TableCell>
                <TableCell>
                  {cameraConnections.is_http ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                  {cameraConnections.is_active ? "Active" : "Deactive"}
                  <Switch
                    checked={cameraConnections.is_active}
                    onChange={() =>
                      handleChangeStatus(
                        cameraConnections.uuid,
                        cameraConnections.is_active,
                      )
                    }
                    aria-label="switch"
                    size="small"
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(
                        `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/connection/${cameraConnections.uuid}`,
                        {
                          state: {
                            ...location_state_details,
                            ...camera_details,
                          },
                        },
                      )
                    }
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <Tooltip title={cameraConnections.is_active ? DEACTIVATE_CONNECTION_NOTE : ""} placement="top">
                    <span>
                      <IconButton
                        size="small"
                        data-testid="editBtn"
                        disabled={cameraConnections.is_active}
                        onClick={() =>
                          navigate(
                            `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/connection/${cameraConnections.uuid}/edit`,
                          )
                        }
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <DeleteCameraConnection
                    is_active={cameraConnections.is_active}
                    connection={cameraConnections}
                    getCameraDetailsRequest={getCameraDetailsRequest}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <NoDataFound data={cameraConnections} />
    </Box>
  );
}
