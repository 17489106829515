import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Grid } from "@mui/material";
import MUISelect from "../../Controls/Select";

export default function AddCompanyIncidentClassForm({
  handleSubmitForm,
  incidentClasses,
}) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    incident_class_uuid: yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        incident_class_uuid: "",
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitForm(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <MUISelect
            error={errors.incident_class_uuid && touched.incident_class_uuid && errors.incident_class_uuid}
            value={values.incident_class_uuid}
            label={"Incident class"}
            required
            name="incident_class_uuid"
            dataTestid={"incident_class_uuid"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            list={incidentClasses}
            labelId={"incident_class_uuid"}
            id={"select-label"}
            renderValue={() => {
              const incident_class = incidentClasses.find(
                (incidentClass) => incidentClass.uuid === values.incident_class_uuid,
              );
              return <>{incident_class.name}</>;
            }}
          />

          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
