import { Button, Grid, IconButton, TextField } from '@mui/material';
import { FieldArray, getIn } from 'formik';
import MUISelect from '../Controls/Select';
import DeleteIcon from "@mui/icons-material/Delete";

export default function PricesForm({ values , errors , handleChange, handleBlur, countries , setFieldValue}) {
  return (
    <FieldArray name="default_price.unit_price_overrides">
    {({ push, remove }) => {
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            sx={{ my: 1, display: "block" }}
            onClick={() =>
              push({
                country_codes: [],
                unit_price: { amount: "", currency_code: "" },
              })
            }
          >
            Add Currency Option
          </Button>
          {values?.default_price?.unit_price_overrides.map(
            (item, index) => {
              const currency_option_currency_code = `default_price.unit_price_overrides[${index}].unit_price.currency_code`;
              const currency_option_amount = `default_price.unit_price_overrides[${index}].unit_price.amount`;
              const currency_option_country_codes = `default_price.unit_price_overrides[${index}].country_codes`;

              const currency_option_currency_val = getIn(
                values,
                currency_option_currency_code,
              );
              const currency_option_unit_amount_val = getIn(
                values,
                currency_option_amount,
              );
              const currency_option_currency_error = getIn(
                errors,
                currency_option_currency_code,
              );
              const currency_option_unit_amount_error = getIn(
                errors,
                currency_option_amount,
              );
              return (
                <Grid
                  container
                  spacing={2}
                  sx={{ pt: 2, pb: 1 }}
                  key={`currency_options_${currency_option_currency_code}`}
                  alignItems={"start"}
                >
                  {/* <Grid item xs={3}>
                    <MUISelect
                      value={currency_option_country_codes_val.length > 0  ? currency_option_country_codes_val : ""}
                      label={"Country"}
                      dataTestid={"country_option_currency"}
                      name={currency_option_country_codes}
                      handleChange={(e) => setFieldValue(currency_option_country_codes , [e.target.value])}
                      handleBlur={handleBlur}
                      list={countries?.map(country => country.code)}
                      labelId={"country_option_currency"}
                      id={"country_option_currency"}
                      error={Boolean(currency_option_country_codes_error)}
                      helperText={currency_option_country_codes_error}
                    />
                  </Grid> */}
                  <Grid item xs={5}>
                    <MUISelect
                      value={currency_option_currency_val}
                      label={"Currency"}
                      dataTestid={"currency_option_currency"}
                      name={`default_price.unit_price_overrides[${index}].unit_price.currency_code`}
                      handleChange={(e)=> {
                        handleChange(e);
                        const country_code = countries.find(country => country.currency === e.target.value).code;
                        setFieldValue(currency_option_country_codes, [country_code]);
                      }}
                      handleBlur={handleBlur}
                      list={countries?.map(country => country.currency)}
                      labelId={"currency_option_currency"}
                      id={"currency_option_currency"}
                      error={Boolean(currency_option_currency_error)}
                      helperText={currency_option_currency_error}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      type="number"
                      id="currency_option_unit_amount"
                      label="Amount"
                      fullWidth
                      name={`default_price.unit_price_overrides[${index}].unit_price.amount`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={currency_option_unit_amount_val}
                      size="small"
                      error={Boolean(currency_option_unit_amount_error)}
                      helperText={currency_option_unit_amount_error}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    {(index > 0 ||
                      values.default_price.unit_price_overrides.length >
                        0) && (
                      <IconButton
                        size="small"
                        data-testid="deleteSettings"
                        aria-label="delete"
                        sx={{ mt: "0.3em" }}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            },
          )}
        </>
      );
    }}
  </FieldArray>

  )
}
