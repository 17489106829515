import { useParams } from "react-router-dom";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { EditText } from "react-edit-text";
import MUITable from "../../../Table";
import DeleteSettingsOverrides from "./DeleteSettingsOverrides";
import { addCompanyCameraSettingsOverrides } from "../../../../apis/v1/Settings/CameraCompanyOverrides";
import "react-edit-text/dist/index.css";
import { parseOverrideValue } from "../../../../utilis/snackbar";
import {
  CameraDefaultSettings,
  CompanyCameraSettingsOverridesResponse,
} from "../../../../interfaces/Settings";

interface CompanyCameraOverridesProps {
  defaultCameraSettings: CameraDefaultSettings[];
  companyCameraOverrides: CompanyCameraSettingsOverridesResponse[];
  getCompanyOverridesRequest: () => void;
  handleEditOverride: (override_uuid, value) => void;
}

export default function CompanyCameraOverrides({
  defaultCameraSettings,
  companyCameraOverrides,
  getCompanyOverridesRequest,
  handleEditOverride,
}: CompanyCameraOverridesProps) {
  const params = useParams();
  const getCompanyOverride = (default_uuid) => {
    const company_override = companyCameraOverrides?.find(
      (override) => override.default_camera_setting_uuid === default_uuid,
    );
    return {
      company_override_value: company_override?.value?.toString(),
      company_override_uuid: company_override?.uuid,
      has_company_override: company_override ? true : false,
    };
  };

  const handleOverridesChange = (row_uuid, value, override_uuid) => {
    const override_value = parseOverrideValue(value);
    if (override_uuid) {
      handleEditOverride(override_uuid, value);
    } else {
      addCompanyCameraSettingsOverrides(params.company_uuid, {
        value: override_value,
        default_camera_setting_uuid: row_uuid,
      }).then((res) => {
        res && getCompanyOverridesRequest();
      });
    }
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "default_value",
          name: "Default",
        },
        {
          id: "company_override",
          name: "Company override",
        },
      ]}
      hasPagination={true}
      data={defaultCameraSettings}
      tableBodyRow={(row: any) => {
        const company_has_override = getCompanyOverride(
          row.uuid,
        )?.has_company_override;
        const company_override_value =
          getCompanyOverride(row.uuid)?.company_override_value || "";
        const company_override_uuid = getCompanyOverride(
          row.uuid,
        )?.company_override_uuid;
        return (
          <TableRow key={row.uuid}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <Tooltip title={row?.description} placement="top-start">
                <p className="truncate_text">{row?.description}</p>
              </Tooltip>
            </TableCell>
            <TableCell>{row?.default_value?.toString()}</TableCell>
            <TableCell>
              <Box display={"flex"} alignItems="center" height={24}>
                <EditText
                  style={{ width: "150px" }}
                  data-testid={"override_value"}
                  name="override_value"
                  defaultValue={company_override_value}
                  onSave={({ value }) =>
                    handleOverridesChange(
                      row.uuid,
                      value,
                      company_override_uuid,
                    )
                  }
                />
                {company_has_override && (
                  <Box display={"flex"} alignItems="center">
                    <DeleteSettingsOverrides
                      overrides_settings_uuid={company_override_uuid}
                      onDelete={() => getCompanyOverridesRequest()}
                    />
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
