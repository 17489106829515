import { AlertColor } from "@mui/material";
import store from "../store";
import { setAlert } from "../store/slices/alertSlice";

export const showSnackbar = (message?: string, type?: AlertColor) => {
  store.dispatch(
    setAlert({
      isOpen: true,
      message: message ? message : "Something went wrong",
      type: type ? type : "error",
    }),
  );
};

export const hideSnackbar = (type) => {
  store.dispatch(
    setAlert({
      isOpen: false,
      message: "",
      type,
    }),
  );
};
// TODO parse in type and validate based on it
export const parseOverrideValue = (value) => {
  // this regex checking for format HH:MM for time datatype
  const is_valid_time =
    /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
  if (is_valid_time) {
    return value;
  }
  // If entered value is string with numbers should be converted to number type
  if (parseInt(value) || parseFloat(value) || value == 0) {
    return Number(value);
  }

  // if entered value is string with true or false should be converted to boolean type
  if (value === "true" || value === "false") {
    return JSON.parse(value);
  }

  // if not number or boolean return the value and backend will validate the value
  return value;
};
