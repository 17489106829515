import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteCompanyChildNode } from "../../apis/v1/OrgStructure";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";

export default function DeleteChildNode({ node_uuid, updateNodeList }) {
  const [openDialog, setOpenDialog] = useState(false);
  const params = useParams();

  const submitDeleteNode = () => {
    deleteCompanyChildNode(params.company_uuid, node_uuid).then((res) => {
      if (res) {
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
        updateNodeList();
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Node"
        dialogBody="Are you sure you want to delete this node?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteNode()}
      />
    </>
  );
}
