/* eslint-disable react-hooks/exhaustive-deps */
import MUIBreadcrumb from "../../components/Breadcrumb";
import { useLocation } from "react-router-dom";
import Detections from "../../components/DetectionsArea/Detections";

export default function CameraDetectionsArea() {
  const location = useLocation();

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state?.company_name,
              path: location.state?.prevCompanyLocation,
              state: location.state,
            },
            {
              name: location.state?.site_name,
              path: location.state?.prevSiteLocation,
              state: location.state,
            },
            {
              name: location.state?.camera_name,
              path: location.state?.prevCameraLocation,
              state: location.state,
            },
          ],
          current: "image",
        }}
      />
      <Detections isEditable={true} />
    </>
  );
}
