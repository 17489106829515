/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOcuconTemplateAttributesByUUID,
  getTemplateDetails,
} from "../../apis/v1/Camera/OcuconCameraTemplates";
import OcuconCameraConnectionTemplateCard from "../../components/Cameras/v1/OcuconCameraConnectionTemplate/OcuconCameraConnectionTemplateCard";
import { Attribute, CameraConnection } from "../../interfaces/Camera";

export default function OcuconTemplateDetails() {
  const [template, setTemplate] = useState<CameraConnection>();
  const [attributes, setAttributes] = useState<Array<Attribute>>();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getTemplateDetails(params.uuid).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
    getOcuconTemplateAttributesByUUID(params.uuid).then((res) => {
      if (res) {
        setAttributes(res.data.attributes);
      }
    });
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Ocucon Camera Connection Template Details"
        subheader="This is ocucon camera connection details page"
      />
      <CardContent>
        {template && !template.is_active && (
          <Button
            variant={"outlined"}
            onClick={() =>
              navigate(
                `/connection/ocucon-camera-connection-template/edit/${params.uuid}`,
              )
            }
            data-testid="edit-ocucon-camera-connection-template-btn"
          >
            Edit
          </Button>
        )}
      </CardContent>
      <CardContent>
        {template && attributes && (
          <OcuconCameraConnectionTemplateCard
            template={template}
            attributes={attributes}
          />
        )}
      </CardContent>
    </Card>
  );
}
