import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import { Service } from "../../interfaces/Service";
import { useEffect, useState } from "react";
import { SERVICE_COLOR_TOOLTIP, UPLOAD_SERVICE_IMAGE_NOTE } from "../../utilis/shared";

interface EditServiceFormProps {
  handleSubmit: (values: Omit<Service, "uuid">) => void;
  service: Service;
}

export default function EditServiceForm({
  handleSubmit,
  service,
  
}: EditServiceFormProps) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  const schema: yup.SchemaOf<Omit<Service, "uuid">> = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    color: yup.string(),
    image: yup.string().nullable()
  });

  const [imagePreview, setImagePreview] = useState<string | null>(null);


  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    setImagePreview(service.image);
  }, [service])

  return (
    <Formik
      initialValues={{
        name: service.name || "",
        description: service.description || "",
        color: service.color || "#000",
        image: null,
      }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name"
            required
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="service_name"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description"
            required
            fullWidth
            margin="normal"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            data-testid="service_description"
            size="small"
            multiline
            rows={5}
            error={Boolean(errors.description && touched.description)}
            helperText={errors.description && touched.description && errors.description}
          />
          <Box mt={2}>
            {imagePreview && (
              <Box mt={2}>
                <img
                  src={imagePreview}
                  alt="preview"
                  style={{ height: "150px" }}
                />
              </Box>
            )}
            <Box mb={1}>
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleImageChange(e);
                  setFieldValue("image", e.currentTarget.files[0]);
                }}
              />
              <label htmlFor="image-upload">
                <Button variant="outlined" component="span">
                  Upload background image
                </Button>
              </label>
            </Box>
            <small>
                {UPLOAD_SERVICE_IMAGE_NOTE}
            </small>
            {errors.image && touched.image && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.image}
              </div>
            )}
          </Box>
          <Box mt={2}>
            <Tooltip
              placement="top"
              title={SERVICE_COLOR_TOOLTIP}
            >
              <label>
                Color
              </label>
            </Tooltip>
            <input
              type="color"
              value={values.color || ""}
              name="color"
              onChange={handleChange}
              style={{ marginLeft: '10px' }}
            />
          </Box>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                data-testid={"submit-btn"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
