import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../../Table";
import { Company } from "../../../interfaces/Company";

interface CompaniesListTableProps {
  companies: Company[];
}

export default function CompaniesListTable({
  companies,
}: CompaniesListTableProps) {
  const navigate = useNavigate();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "account_number",
          name: "Account Number"
        },
        {
          id: "name",
          name: "Name",
        },
        {
          id: "phone",
          name: "Phone",
        },
        {
          id: "services",
          name: "Services",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={companies}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.account_number}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.phones.length > 0 ? row.phones[0].number : "-"}</TableCell>
          <TableCell>
            {row.services.length > 0
              ? row.services.map((service) => service.name).join(", ")
              : "-"}
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/company/${row.uuid}/root-node/${row.root_node_uuid}`)
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => navigate(`/company/edit/${row.uuid}`)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    />
  );
}
