/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import { Reason } from "../../interfaces/Credits";
import { editReason, getReason } from "../../apis/v1/Credits/Reasons";
import { useEffect, useState } from "react";
import EditReasonForm from "../../components/Reasons/EditReasonForm";

export default function EditReason() {
  const [reason, setReason] = useState<Reason>();
  const navigate = useNavigate();
  const params = useParams();

  const fetchReason = async () => {
    try {
      const response = await getReason(params.reason_uuid);
      setReason(response.data);
    } catch (error) {
      console.log("Error fetch reason", error);
    }
  };

  useEffect(() => {
    if (params.reason_uuid) {
      fetchReason();
    }
  }, [params.reason_uuid]);

  const handleSubmitForm = async (values: Pick<Reason, "reason">) => {
    try {
      const response = await editReason(params.reason_uuid, values);
      if (response) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    } catch (error) {
      console.log("Error submit reason", error);
    }
  };
  return (
    <>
      <PagesHeader title="Edit Credit Reason" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <EditReasonForm reason={reason} handleSubmitForm={handleSubmitForm} />
        </Grid>
      </Grid>
    </>
  );
}
