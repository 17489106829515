import { orderType } from "../../../enums/Order";

type Order = orderType.ASC | orderType.DESC;

export class TableOrder {
  private currentOrder: Order = orderType.ASC;

  getCurrentOrder() {
    return this.currentOrder;
  }

  setCurrentOrder(order) {
    this.currentOrder = order;
  }

  toggleOrder(order) {
    if (order === orderType.ASC) {
      this.setCurrentOrder(orderType.DESC);
    } else {
      this.setCurrentOrder(orderType.ASC);
    }
  }
}
