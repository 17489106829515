import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { FieldArray, Formik, getIn } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import MUISelect from "../../../Controls/Select";
import GroupedTemplatesSelect from "../../../Controls/GroupedTemplatesSelect";
import { getCompanyTemplateAttributesByUUID } from "../../../../apis/v1/Camera/CompanyCameraTemplates";
import { getOcuconTemplateAttributesByUUID } from "../../../../apis/v1/Camera/OcuconCameraTemplates";
import { template_attributes_types } from "../../../../utilis/templateAttributesFilter";
import { setFieldValueWithoutSpaces } from "../../../../utilis/shared";
import { Fragment } from "react";

export default function AddCompanyTemplateForm({
  base_templates,
  handleSubmitForm,
  authTypes,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const schema = yup.object().shape({
    name: yup.string().required(),
    is_http: yup.boolean(),
    url_template: yup
      .string()
      .nullable()
      .when("is_http", (is_http) => {
        if (is_http) {
          return yup.string().required();
        }
      }),
    attributes_fields: yup.array(),
    attributes: yup.array().of(
      yup.object().shape({
        key: yup.mixed(),
        value: yup.mixed(),
      }),
    ),
    auth_type: yup.mixed(),
    username: yup.mixed(),
    password: yup.mixed(),
  });

  return (
    <Formik
      initialValues={{
        base_template: null,
        name: "",
        is_http: true,
        url_template: "",
        auth_type: null,
        username: null,
        password: null,
        attributes: [],
        attributes_fields: [
          {
            key: "",
            value: "",
            type: "url_replacement_values",
          },
          {
            key: "",
            value: "",
            type: "query_params",
          },
          {
            key: "",
            value: "",
            type: "settings",
          },
        ],
      }}
      validationSchema={schema}
      onSubmit={(values) => handleSubmitForm(values)}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={4}>
              <GroupedTemplatesSelect
                dataTestid="base_template"
                label="Base Template"
                labelId="base_template"
                id="base_template"
                name="base_template"
                handleChange={(e) => {
                  handleChange(e);
                  const merged_templates = [
                    ...base_templates.ocucon_templates,
                    ...base_templates.company_templates,
                  ];
                  const template = merged_templates.find(
                    (template) => template.uuid === e.target.value,
                  );
                  setFieldValue("base_template", e.target.value);
                  setFieldValue("is_http", template.is_http);
                  setFieldValue("url_template", template.url_template);
                  setFieldValue("auth_type", template.auth_type);
                  setFieldValue("username", template.username);
                  setFieldValue("password", template.password);
                  if (template.type === "ocucon_template") {
                    getOcuconTemplateAttributesByUUID(e.target.value).then(
                      (res) => {
                        if (res) {
                          setFieldValue("attributes", res.data.attributes);
                        }
                      },
                    );
                  } else {
                    getCompanyTemplateAttributesByUUID(
                      params.company_uuid,
                      e.target.value,
                    ).then((res) => {
                      if (res) {
                        setFieldValue("attributes", res.data.attributes);
                      }
                    });
                  }
                }}
                keyItem="uuid"
                keyValue="name"
                list={base_templates}
                value={values.base_template}
              />
              <small>
                {" "}
                Note: Select a predefined template. The template list is a
                mixture of ocucon and company templates.
              </small>
              <TextField
                type="text"
                id="name"
                data-testid="name"
                label="Name*"
                fullWidth
                margin="normal"
                name="name"
                error={Boolean(errors.name && touched.name)}
                helperText={errors.name && touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                size="small"
              />
              <Box display={"flex"} justifyContent="end">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_http"
                      data-testid="is_http"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_http}
                    />
                  }
                  label="Is http connection?"
                />
              </Box>
              {values.is_http && (
                <>
                  <TextField
                    type="text"
                    id="url_template"
                    data-testid="url_template"
                    label="url template*"
                    fullWidth
                    margin="normal"
                    name="url_template"
                    onChange={(e) =>
                      setFieldValueWithoutSpaces(
                        e,
                        "url_template",
                        setFieldValue,
                      )
                    }
                    onBlur={handleBlur}
                    value={values.url_template}
                    size="small"
                    error={Boolean(errors.url_template && touched.url_template)}
                    helperText={
                      errors &&
                      errors.url_template &&
                      touched.url_template &&
                      errors.url_template
                    }
                  />
                  <MUISelect
                    dataTestid="auth_type"
                    label="Auth Type"
                    labelId="auth_type"
                    id="auth_type"
                    name="auth_type"
                    handleChange={handleChange}
                    list={authTypes}
                    margin="normal"
                    value={values.auth_type}
                    setFieldValue={setFieldValue}
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        type="text"
                        id="username"
                        data-testid="username"
                        label="Username"
                        fullWidth
                        margin="normal"
                        name="username"
                        onChange={(e) =>
                          setFieldValueWithoutSpaces(
                            e,
                            "username",
                            setFieldValue,
                          )
                        }
                        onBlur={handleBlur}
                        value={values.username || ""}
                        size="small"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        type="text"
                        id="password"
                        data-testid="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        name="password"
                        onChange={(e) =>
                          setFieldValueWithoutSpaces(
                            e,
                            "password",
                            setFieldValue,
                          )
                        }
                        onBlur={handleBlur}
                        value={values.password || ""}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {template_attributes_types(values.is_http).map((item) => (
                <div key={item}>
                  <h4>{item.split("_").join(" ")}</h4>
                  <FieldArray name="attributes_fields">
                    {({ replace }) => (
                      <Box key={item}>
                        {values.attributes_fields.map((attr: any, index) => {
                          const key = `attributes_fields[${index}].key`;
                          const value = `attributes_fields[${index}].value`;
                          const keyErr = getIn(errors, key);
                          const valueErr = getIn(errors, value);
                          if (attr.type === item) {
                            return (
                              <Fragment key={index}>
                                {values.attributes.map(
                                  (attr_val, attr_val_index) => {
                                    const attrVal = getIn(
                                      values,
                                      `attributes[${attr_val_index}].value`,
                                    );
                                    if (attr_val.type === item) {
                                      return (
                                        <Grid
                                          container
                                          spacing={2}
                                          key={attr_val_index}
                                          justifyContent="space-between"
                                        >
                                          <Grid item xs={4}>
                                            <Box component={"h6"} m={0}>
                                              Key
                                            </Box>
                                            <Box
                                              component={"p"}
                                              mt={0}
                                              sx={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {attr_val.key}
                                            </Box>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <TextField
                                              variant="outlined"
                                              data-testid="value"
                                              label={`value`}
                                              name={`attributes[${attr_val_index}].value`}
                                              value={attrVal || ""}
                                              onChange={(e) =>
                                                setFieldValueWithoutSpaces(
                                                  e,
                                                  `attributes[${attr_val_index}].value`,
                                                  setFieldValue,
                                                )
                                              }
                                              onBlur={handleBlur}
                                              size="small"
                                              fullWidth
                                            />
                                          </Grid>
                                          <Grid item xs={4}>
                                            <IconButton
                                              size="small"
                                              data-testid="deleteSettings"
                                              aria-label="delete"
                                              sx={{ mt: "0.3em" }}
                                              onClick={() =>
                                                setFieldValue(
                                                  "attributes",
                                                  values.attributes.filter(
                                                    (attr, i) =>
                                                      attr_val_index !== i,
                                                  ),
                                                )
                                              }
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      );
                                    } else {
                                      return (
                                        <Fragment
                                          key={attr_val_index}
                                        ></Fragment>
                                      );
                                    }
                                  },
                                )}
                                <Grid
                                  container
                                  alignItems={"start"}
                                  mb={2}
                                  spacing={2}
                                >
                                  <Grid item xs={4}>
                                    <TextField
                                      variant="outlined"
                                      data-testid="settingsField"
                                      label={`key`}
                                      name={key}
                                      value={attr.key}
                                      onChange={(e) =>
                                        setFieldValueWithoutSpaces(
                                          e,
                                          key,
                                          setFieldValue,
                                        )
                                      }
                                      onBlur={handleBlur}
                                      size="small"
                                      error={Boolean(keyErr)}
                                      helperText={keyErr && keyErr}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      variant="outlined"
                                      data-testid="value"
                                      label={`value`}
                                      name={value}
                                      value={attr.value}
                                      onChange={(e) =>
                                        setFieldValueWithoutSpaces(
                                          e,
                                          value,
                                          setFieldValue,
                                        )
                                      }
                                      onBlur={handleBlur}
                                      size="small"
                                      error={Boolean(valueErr)}
                                      helperText={valueErr && valueErr}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      type="button"
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        // Both empty
                                        if (attr.key.length === 0) {
                                          setFieldError(
                                            `attributes_fields[${index}].key`,
                                            "Required",
                                          );
                                        }

                                        if (attr.key) {
                                          setFieldError(
                                            `attributes_fields[${index}].key`,
                                            "",
                                          );
                                          setFieldError(
                                            `attributes_fields[${index}].value`,
                                            "",
                                          );
                                          setFieldValue("attributes", [
                                            ...values.attributes,
                                            {
                                              key: attr.key,
                                              value: attr.value,
                                              type: attr.type,
                                            },
                                          ]);
                                          replace(index, {
                                            key: "",
                                            value: "",
                                            type: attr.type,
                                          });
                                        }
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Fragment>
                            );
                          }
                          return <Fragment key={index}></Fragment>;
                        })}
                      </Box>
                    )}
                  </FieldArray>
                </div>
              ))}

              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    sx={{ width: "100%" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    onClick={() => navigate(-1)}
                    color="error"
                    sx={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
