import { IShape } from "./Interface/Shape";
import Circles from "./Shape/Circles";
import Polygon from "./Shape/Polygon";

export default function DetectionsDrawer({
  shapes,
  polygonRef,
  handleMouseDownOnCircle,
  handleMouseMoveOnCircle,
  handleMouseUpOnCircle,
  svgWidth,
  svgHeight,
  setActiveShape,
  activeShape,
  handleDeletePoint,
  handleCloseShape,
  handleSubmitShape,
  isPointDragging,
  activePointIndex,
  setActivePointIndex,
  isEditable,
  isDrawingActive,
}) {
  const renderShape = (shape, index) => {
    const isActiveShape = activeShape && activeShape.index === index;
    return (
      <g id={`shape-${index}`} key={index}>
        <Polygon
          index={index}
          shapes={shapes}
          shape={shape}
          polygonRef={polygonRef}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
          setActiveShape={setActiveShape}
          isActiveShape={isActiveShape}
          isDrawingActive={isDrawingActive}
        />
        {isEditable && (
          <Circles
            index={index}
            shapes={shapes}
            shape={shape}
            isActiveShape={isActiveShape}
            activePointIndex={activePointIndex}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            isPointDragging={isPointDragging}
            setActivePointIndex={setActivePointIndex}
            setActiveShape={setActiveShape}
            handleMouseDownOnCircle={handleMouseDownOnCircle}
            handleMouseMoveOnCircle={handleMouseMoveOnCircle}
            handleMouseUpOnCircle={handleMouseUpOnCircle}
            handleDeletePoint={handleDeletePoint}
            handleSubmitShape={handleSubmitShape}
            handleCloseShape={handleCloseShape}
          />
        )}
      </g>
    );
  };

  return shapes.length > 0 ? (
    shapes?.map((shape: IShape, index: number) => {
      return renderShape(shape, index);
    })
  ) : (
    <></>
  );
}
