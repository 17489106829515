import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { deleteTemplate } from "../../../../apis/v1/Camera/OcuconCameraTemplates";
import { showSnackbar } from "../../../../utilis/snackbar";
import ConfirmationPopup from "../../../ConfirmationPopup";

export default function DeleteTemplate({
  template_uuid,
  is_active,
  templates,
  setTemplates,
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const getTemplatesListWithoutDeleted = () => {
    return templates.filter((template) => template.uuid !== template_uuid);
  };

  const submitDeleteTemplate = () => {
    deleteTemplate(template_uuid).then((res) => {
      if (res) {
        const templatesListWithoutDeleted = getTemplatesListWithoutDeleted();
        setTemplates(templatesListWithoutDeleted);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        disabled={is_active}
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Template"
        dialogBody="Are you sure you want to delete this template?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteTemplate()}
      />
    </>
  );
}
