/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import ConsumablesListTable from "../../components/Consumables/ConsumablesListTable";
import { Consumable } from "../../interfaces/Consumable";
import { getConsumables } from "../../apis/v1/Services/Consumables";

export default function ConsumablesList() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumable, setConsumables] = useState<Consumable[]>([]);
  useEffect(() => {
    getConsumables(params.service_uuid).then((res) => setConsumables(res.data));
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader title="Consumables" subheader="List of consumables" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/service/${params.service_uuid}/consumable/add`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <ConsumablesListTable
          consumables={consumable}
          setConsumables={setConsumables}
        />
      </CardContent>
    </Card>
  );
}
