import { Box } from "@mui/material";

interface PagesHeaderProps {
  title: string;
  style?: {
    mb: number;
  };
}

export default function PagesHeader({ title, style }: PagesHeaderProps) {
  return (
    <Box component="h3" sx={{ fontWeight: 700, ...style }}>
      {title}
    </Box>
  );
}
