import { Routes, Route } from "react-router-dom";
import AddAdmin from "../pages/Admins/AddAdmin";
import AdminsList from "../pages/Admins/AdminsList";
import Auth from "../pages/Auth";
import AddCompany from "../pages/Companies/AddCompany";
import Companies from "../pages/Companies";
import CompanyDetails from "../pages/Companies/CompanyDetails";
import EditCompany from "../pages/Companies/EditCompany";
import AddSite from "../pages/Sites/AddSite";
import EditSite from "../pages/Sites/EditSite";
import SiteDetails from "../pages/Sites/SiteDetails";
import TokenExchange from "../pages/TokenExchange";
import PrivateRoute from "./PrivateRoute";
import OcuconTemplateList from "../pages/OcuconCameraConnectionTemplate/OcuconTemplates";
import AddOcuconTemplate from "../pages/OcuconCameraConnectionTemplate/AddOcuconTemplate";
import EditOcuconTemplate from "../pages/OcuconCameraConnectionTemplate/Edit/Step1";
import EditOcuconTemplateAttributes from "../pages/OcuconCameraConnectionTemplate/Edit/Step2";
import OcuconTemplateDetails from "../pages/OcuconCameraConnectionTemplate/OcuconTemplateDetails";
import EditCompanyCameraTemplate from "../pages/CompanyCameraTemplate/Edit/Step1";
import EditCompanyCameraTemplateAttributes from "../pages/CompanyCameraTemplate/Edit/Step2";
import AddCompanyCameraTemplate from "../pages/CompanyCameraTemplate/AddCompanyCameraTemplate";
import CompanyCameraTemplateDetails from "../pages/CompanyCameraTemplate/CompanyCameraTemplateDetails";
import AddCamera from "../pages/Cameras/AddCamera";
import CameraDetails from "../pages/Cameras/CameraDetails";
import EditCamera from "../pages/Cameras/EditCamera";
import AddCameraConnection from "../pages/CameraConnection/AddCameraConnection";
import CameraConnectionDetails from "../pages/CameraConnection/CameraConnectionDetails";
import EditCameraConnectionTemplate from "../pages/CameraConnection/Edit/Step1";
import EditCameraConnectionAttributes from "../pages/CameraConnection/Edit/Step2";
import DefaultCameraSettingsList from "../pages/Settings/DefaultCameraSettings/DefaultCameraSettingsList";
import AddCameraDefaultSettings from "../pages/Settings/DefaultCameraSettings/AddCameraDefaultSettings";
import CameraDefaultSettingsDetails from "../pages/Settings/DefaultCameraSettings/CameraDefaultSettingsDetails";
import EditCameraDefaultSettings from "../pages/Settings/DefaultCameraSettings/EditCameraDefaultSettings";
import AddCompanyCameraSettingsOverrides from "../pages/Companies/AddCompanyCameraSettingsOverrides";
import AddSiteCameraSettingsOverrides from "../pages/Sites/AddSiteCameraSettingsOverrides";
import AddCameraSettingsOverrides from "../pages/Cameras/AddCameraSettingsOverrides";
import AddUser from "../pages/Companies/AddUser";
import EditUser from "../pages/Companies/EditUser";
import User from "../pages/Companies/UserDetails";
import ChildNodes from "../pages/OrgStructure/ChildNodes";
import AddChildNode from "../pages/OrgStructure/AddChildNode";
import RootNode from "../pages/OrgStructure/RootNode";
import AddGlobalIncidentClass from "../pages/GlobalIncidentClasses/AddGlobalIncidentClass";
import EditGlobalIncidentClass from "../pages/GlobalIncidentClasses/EditGlobalIncidentClass";
import GlobalIncidentClassesList from "../pages/GlobalIncidentClasses/GlobalIncidentClassesList";
import CameraDetectionsArea from "../pages/CameraDetectionsArea";
import EditRule from "../pages/Notifications/EditRule";
import RuleDetails from "../pages/Notifications/RuleDetails";
import AddRuleIntegration from "../pages/Notifications/AddRuleIntegration";
import AddRule from "../pages/Notifications/AddRule";
import EditRuleIntegration from "../pages/Notifications/EditRuleIntegration";
import Sync from "../pages/Sync";
import AddIncidentCause from "../pages/IncidentCauses/AddIncidentCause";
import EditIncidentCause from "../pages/IncidentCauses/EditIncidentCause";
import ServicesList from "../pages/Services/ServicesList";
import AddService from "../pages/Services/AddService";
import EditService from "../pages/Services/EditService";
import ServiceDetails from "../pages/Services/ServiceDetails";
import AddTier from "../pages/Tiers/AddTier";
import EditTier from "../pages/Tiers/EditTier";
import TierDetails from "../pages/Tiers/TierDetails";
import EditFeature from "../pages/Features/EditFeature";
import AddFeature from "../pages/Features/AddFeature";
import EditConsumable from "../pages/Consumables/EditConsumable";
import AddConsumable from "../pages/Consumables/AddConsumable";
import ConsumableDetails from "../pages/Consumables/ConsumableDetails";
import AddConsumableAddons from "../pages/ConsumablesAddons/AddConsumableAddons";
import EditConsumableAddons from "../pages/ConsumablesAddons/EditConsumableAddons";
import ConsumableAddonsDetails from "../pages/ConsumablesAddons/ConsumableAddonsDetails";
import AddCompanyIncidentClass from "../pages/Companies/AddCompanyIncidentClass";
import AddPolicy from "../pages/Policies/AddPolicy";
import EditPolicy from "../pages/Policies/EditPolicy";
import AllUsersList from "../pages/Users/AllUsersList";
import AddReason from "../pages/CreditReasons/AddReason";
import EditReason from "../pages/CreditReasons/EditReason";
import AddCredit from "../pages/Credits/AddCredit";
import SubscriptionsList from "../pages/Subscriptions/AllSubscriptionsList";
import AddSubscription from "../pages/Subscriptions/AddSubscription";
import SubscriptionDetails from "../pages/Subscriptions/SubscriptionDetails";
import UpdatePermissions from "../pages/Users/UpdatePermissions";
import UpdateLicenses from "../pages/Users/UpdateLicenses";

export default function RoutesList() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="/callback" element={<TokenExchange />} />
      <Route
        path="/companies"
        element={
          <PrivateRoute>
            <Companies />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/add"
        element={
          <PrivateRoute>
            <AddCompany />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/edit/:company_uuid"
        element={
          <PrivateRoute>
            <EditCompany />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root-node/:root_node_uuid"
        element={
          <PrivateRoute>
            <CompanyDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/site/add"
        element={
          <PrivateRoute>
            <AddSite />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/site/:site_uuid/edit"
        element={
          <PrivateRoute>
            <EditSite />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/site/:site_uuid"
        element={
          <PrivateRoute>
            <SiteDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admins"
        element={
          <PrivateRoute>
            <AdminsList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/add"
        element={
          <PrivateRoute>
            <AddAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/ocucon-camera-connection-templates"
        element={
          <PrivateRoute>
            <OcuconTemplateList />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/ocucon-camera-connection-template/add"
        element={
          <PrivateRoute>
            <AddOcuconTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/ocucon-camera-connection-template/edit/:uuid"
        element={
          <PrivateRoute>
            <EditOcuconTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/ocucon-camera-connection-template/edit/attributes/:uuid"
        element={
          <PrivateRoute>
            <EditOcuconTemplateAttributes />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/ocucon-camera-connection-template/:uuid"
        element={
          <PrivateRoute>
            <OcuconTemplateDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/company-template/add/:company_uuid"
        element={
          <PrivateRoute>
            <AddCompanyCameraTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/company-template/:company_uuid/edit/:uuid"
        element={
          <PrivateRoute>
            <EditCompanyCameraTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/company-template/:company_uuid/edit/attributes/:uuid"
        element={
          <PrivateRoute>
            <EditCompanyCameraTemplateAttributes />
          </PrivateRoute>
        }
      />
      <Route
        path="/connection/company-template/:company_uuid/:template_uuid"
        element={
          <PrivateRoute>
            <CompanyCameraTemplateDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/add"
        element={
          <PrivateRoute>
            <AddCamera />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/edit"
        element={
          <PrivateRoute>
            <EditCamera />
          </PrivateRoute>
        }
      />
      <Route
        path="/camera/:camera_uuid/company/:company_uuid/site/:site_uuid"
        element={
          <PrivateRoute>
            <CameraDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/add"
        element={
          <PrivateRoute>
            <AddCameraConnection />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid"
        element={
          <PrivateRoute>
            <CameraConnectionDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid/edit"
        element={
          <PrivateRoute>
            <EditCameraConnectionTemplate />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid/attributes/edit"
        element={
          <PrivateRoute>
            <EditCameraConnectionAttributes />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/camera/default-settings"
        element={
          <PrivateRoute>
            <DefaultCameraSettingsList />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/camera/default-settings/add"
        element={
          <PrivateRoute>
            <AddCameraDefaultSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/camera/default-settings/:uuid"
        element={
          <PrivateRoute>
            <CameraDefaultSettingsDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/camera/default-settings/edit/:uuid"
        element={
          <PrivateRoute>
            <EditCameraDefaultSettings />
          </PrivateRoute>
        }
      />

      <Route
        path="/company/:company_uuid/add-company-overrides"
        element={
          <PrivateRoute>
            <AddCompanyCameraSettingsOverrides />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/add-site-overrides"
        element={
          <PrivateRoute>
            <AddSiteCameraSettingsOverrides />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/add-camera-overrides"
        element={
          <PrivateRoute>
            <AddCameraSettingsOverrides />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/add-user"
        element={
          <PrivateRoute>
            <AddUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/edit-user/:user_id"
        element={
          <PrivateRoute>
            <EditUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/user/:user_id"
        element={
          <PrivateRoute>
            <User />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/user/:user_id/update-permissions"
        element={
          <PrivateRoute>
            <UpdatePermissions />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/node/:node_uuid/user/:user_id/update-licenses"
        element={
          <PrivateRoute>
            <UpdateLicenses />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root-node/:root_node_uuid/node"
        element={
          <PrivateRoute>
            <RootNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root-node/:root_node_uuid/node/:node_uuid/children"
        element={
          <PrivateRoute>
            <ChildNodes />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/parent-node/:node_uuid/add-child-node"
        element={
          <PrivateRoute>
            <AddChildNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/image/:image_uuid"
        element={
          <PrivateRoute>
            <CameraDetectionsArea />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/add-company-incident-class"
        element={
          <PrivateRoute>
            <AddCompanyIncidentClass />
          </PrivateRoute>
        }
      />
      <Route
        path="/global-incident-classes/add"
        element={
          <PrivateRoute>
            <AddGlobalIncidentClass />
          </PrivateRoute>
        }
      />
      <Route
        path="/global-incident-classes"
        element={
          <PrivateRoute>
            <GlobalIncidentClassesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/global-incident-classes/:incident_class_uuid/edit"
        element={
          <PrivateRoute>
            <EditGlobalIncidentClass />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root_node/:root_node_uuid/notifications/add-rule"
        element={
          <PrivateRoute>
            <AddRule />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root_node/:root_node_uuid/notifications/rule/:rule_uuid/edit"
        element={
          <PrivateRoute>
            <EditRule />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/root_node/:root_node_uuid/notifications/rule/:rule_uuid"
        element={
          <PrivateRoute>
            <RuleDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/notifications/rule/:rule_uuid/type/:rule_type/integration/add"
        element={
          <PrivateRoute>
            <AddRuleIntegration />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/notifications/rule/:rule_uuid/type/:rule_type/integration/:integration_uuid/edit"
        element={
          <PrivateRoute>
            <EditRuleIntegration />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/add-incident-cause"
        element={
          <PrivateRoute>
            <AddIncidentCause />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/incident-cause/edit/:incident_cause_uuid"
        element={
          <PrivateRoute>
            <EditIncidentCause />
          </PrivateRoute>
        }
      />
      <Route
        path="/sync"
        element={
          <PrivateRoute>
            <Sync />
          </PrivateRoute>
        }
      />
      <Route
        path="/services"
        element={
          <PrivateRoute>
            <ServicesList />
          </PrivateRoute>
        }
      />

      <Route
        path="/service/add"
        element={
          <PrivateRoute>
            <AddService />
          </PrivateRoute>
        }
      />

      <Route
        path="/service/edit/:service_uuid"
        element={
          <PrivateRoute>
            <EditService />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid"
        element={
          <PrivateRoute>
            <ServiceDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid/tier/add"
        element={
          <PrivateRoute>
            <AddTier />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid/tier/:tier_uuid/edit"
        element={
          <PrivateRoute>
            <EditTier />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid/tier/:tier_uuid"
        element={
          <PrivateRoute>
            <TierDetails />
          </PrivateRoute>
        }
      />

      <Route
        path="/service/:service_uuid/feature/:feature_uuid/edit"
        element={
          <PrivateRoute>
            <EditFeature />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid/feature/add"
        element={
          <PrivateRoute>
            <AddFeature />
          </PrivateRoute>
        }
      />

      <Route
        path="/service/:service_uuid/consumable/:consumable_uuid/edit"
        element={
          <PrivateRoute>
            <EditConsumable />
          </PrivateRoute>
        }
      />
      <Route
        path="/service/:service_uuid/consumable/add"
        element={
          <PrivateRoute>
            <AddConsumable />
          </PrivateRoute>
        }
      />
      <Route
        path="service/:service_uuid/consumable/:consumable_uuid"
        element={
          <PrivateRoute>
            <ConsumableDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="service/:service_uuid/consumable/:consumable_uuid/consumable_addon/add"
        element={
          <PrivateRoute>
            <AddConsumableAddons />
          </PrivateRoute>
        }
      />
      <Route
        path="service/:service_uuid/consumable/:consumable_uuid/consumable_addon/:consumable_addon_uuid/edit"
        element={
          <PrivateRoute>
            <EditConsumableAddons />
          </PrivateRoute>
        }
      />
      <Route
        path="service/:service_uuid/consumable/:consumable_uuid/consumable_addon/:consumable_addon_uuid"
        element={
          <PrivateRoute>
            <ConsumableAddonsDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="add-policy"
        element={
          <PrivateRoute>
            <AddPolicy />
          </PrivateRoute>
        }
      />
      <Route
        path="edit-policy/:policy_uuid"
        element={
          <PrivateRoute>
            <EditPolicy />
          </PrivateRoute>
        }
      />
      <Route
        path="users"
        element={
          <PrivateRoute>
            <AllUsersList />
          </PrivateRoute>
        }
      />
      <Route
        path="reasons/add"
        element={
          <PrivateRoute>
            <AddReason />
          </PrivateRoute>
        }
      />
      <Route
        path="/subscriptions"
        element={
          <PrivateRoute>
            <SubscriptionsList />
          </PrivateRoute>
        }
      />
      <Route
        path="reasons/:reason_uuid/edit"
        element={
          <PrivateRoute>
            <EditReason />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/add-subscription"
        element={
          <PrivateRoute>
            <AddSubscription />
          </PrivateRoute>
        }
      />
      <Route
        path="company/:company_uuid/service/:service_uuid/add-credit"
        element={
          <PrivateRoute>
            <AddCredit />
          </PrivateRoute>
        }
      />
      <Route
        path="/company/:company_uuid/service/:service_uuid/subscription/:subscription_uuid"
        element={
          <PrivateRoute>
            <SubscriptionDetails />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
