/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import PentagonIcon from "@mui/icons-material/Pentagon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getCompanyIncidentClasses } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";
import { useParams } from "react-router-dom";

export default function DetectionsSideMenu({
  shapes,
  addNewShape,
  setActiveShape,
  handleDeleteShape,
  activeShapeIndex,
  setActiveShapeIndex,
}) {
  const { company_uuid } = useParams();

  const [incidentClasses, setIncidentClasses] = useState([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    getCompanyIncidentClasses(company_uuid).then((res) => {
      setIncidentClasses(res.data);
    });
  }, [company_uuid]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (_, index: number) => {
    setActiveShapeIndex(index);
  };

  const renderIncidentClassesList = () => {
    return (
      <List
        className="listUpperSection"
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflowY: "auto",
          position: "relative",
          "& ul": { padding: 0 },
          flexGrow: 1,
        }}
        dense={true}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Incident Classes
          </ListSubheader>
        }
      >
        <Divider />
        {incidentClasses.map((incident_class) => {
          return (
            <ListItemButton key={incident_class.uuid} onClick={() => addNewShape(incident_class)}>
              <ListItemIcon>
                <PentagonIcon style={{ color: incident_class.colour }} />
              </ListItemIcon>
              <ListItemText primary={incident_class.name} />
            </ListItemButton>
          );
        })}
      </List>
    );
  };

  const renderShapesList = () => {
    return (
      <List
        className="listLowerSection"
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflowY: "auto",
          position: "relative",
          "& ul": { padding: 0 },
          flexGrow: 1,
        }}
        dense={true}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Shapes
          </ListSubheader>
        }
      >
        <Divider />
        {shapes.map((shape, SIndex) => {
          if (shape.uuid) {
            return (
              <ListItem
                key={SIndex}
                onClick={() => {
                  setActiveShape({
                    index: SIndex,
                    ...shapes[SIndex],
                  });
                }}
                disablePadding
                secondaryAction={
                  <Fragment key={SIndex}>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleDeleteShape();
                          handleClose();
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Fragment>
                }
              >
                <ListItemButton
                  key={`${SIndex}UUID`}
                  selected={activeShapeIndex === SIndex}
                  onClick={(event) => handleListItemClick(event, SIndex)}
                >
                  <ListItemIcon>
                    <PentagonIcon style={{ color: shape.incident_class.colour }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${shape.incident_class.name} ( ${shape.zone_number} )`}
                  />
                </ListItemButton>
              </ListItem>
            );
          } else {
            return <Fragment key={`Fragment${SIndex}`}></Fragment>;
          }
        })}
      </List>
    );
  };

  return (
    <Card
      variant="outlined"
      className="detectionSideMenu"
      sx={{ height: "100%", width: "100%" }}
    >
      <Box className="listContainer">
        {renderIncidentClassesList()}
        <Divider />
        {renderShapesList()}
      </Box>
    </Card>
  );
}
