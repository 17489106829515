import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";
import { GIncidentClass } from "../../../interfaces/IncidentClass";

const incidentClassesURL: string = `/detection-zone/v1/incident-classes`;

export const getGlobalIncidentClasses = (): AxiosPromise<GIncidentClass[]> => {
  return axiosInstance.get(incidentClassesURL);
};

export const addGlobalIncidentClass = (
  data: Omit<GIncidentClass, "uuid">,
): AxiosPromise<GIncidentClass> => {
  return axiosInstance.post(incidentClassesURL, data);
};

export const deleteGlobalIncidentClass = (
  incident_class_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(`${incidentClassesURL}/${incident_class_uuid}`);
};

export const editGlobalIncidentClass = (
  incident_class_uuid: string,
  data: Omit<GIncidentClass, "uuid">,
): AxiosPromise<GIncidentClass> => {
  return axiosInstance.patch(
    `${incidentClassesURL}/${incident_class_uuid}`,
    data,
  );
};
