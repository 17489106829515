import { TableCell, TableRow } from "@mui/material";
import { SubscriptionHistory } from "../../interfaces/Subscription";
import MUITable from "../Table";
import { formatDate } from "../../utilis/formatDate";

interface SubscriptionsListTableProps {
  subscriptionHistories: Array<SubscriptionHistory>;
}

export default function SubscriptionsLHistoryTable({
  subscriptionHistories,
}: Readonly<SubscriptionsListTableProps>) {
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "type",
          name: "Action",
        },
        {
          id: "user_email",
          name: "User",
        },
        {
          id: "user_type",
          name: "User Type",
        },
        {
          id: "cancel_reason",
          name: "Cancel Reason",
        },
        {
          id: "created_at_utc",
          name: "Date",
        },
      ]}
      hasPagination={true}
      data={subscriptionHistories}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid} data-testid="history-row">
          <TableCell>{row.type}</TableCell>
          <TableCell>{row.user_email}</TableCell>
          <TableCell>{row.user_type}</TableCell>
          <TableCell>{row.cancel_reason || " - "}</TableCell>
          <TableCell>{formatDate(row.created_at_utc)}</TableCell>
        </TableRow>
      )}
    />
  );
}
