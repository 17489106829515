import { useParams } from "react-router-dom";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { EditText } from "react-edit-text";
import MUITable from "../../../Table";
import DeleteSettingsOverrides from "./DeleteSettingsOverrides";
import {
  addCameraSettingsOverrides,
  editCameraSettingsOverrides,
} from "../../../../apis/v1/Settings/CameraOverrides";
import "react-edit-text/dist/index.css";
import { parseOverrideValue } from "../../../../utilis/snackbar";
import {
  CameraDefaultSettings,
  CameraSettingsOverridesResponse,
  CameraSettingsResponse,
  CompanyCameraSettingsOverridesResponse,
  SiteCameraSettingsOverridesResponse,
} from "../../../../interfaces/Settings";

interface CompanyCameraOverridesProps {
  defaultCameraSettings: CameraDefaultSettings[];
  companyCameraOverrides: CompanyCameraSettingsOverridesResponse[];
  sitesOverrides: SiteCameraSettingsOverridesResponse[];
  overrides: CameraSettingsOverridesResponse[];
  cameraSettings: CameraSettingsResponse[];
  getCameraOverridesRequest: () => void;
}

export default function CameraOverrides({
  overrides,
  cameraSettings,
  defaultCameraSettings,
  companyCameraOverrides,
  sitesOverrides,
  getCameraOverridesRequest,
}: CompanyCameraOverridesProps) {
  const params = useParams();
  const getCameraOverride = (default_uuid) => {
    const camera_override = overrides.find(
      (override) => override?.default_camera_setting_uuid === default_uuid,
    );
    return {
      camera_override_value: camera_override?.value?.toString(),
      camera_override_uuid: camera_override?.uuid,
      has_camera_override: camera_override ? true : false,
    };
  };

  const handleOverridesChange = (row_uuid, value, override_uuid) => {
    const override_value = parseOverrideValue(value);
    if (override_uuid) {
      editCameraSettingsOverrides(
        params.company_uuid,
        params.site_uuid,
        params.camera_uuid,
        override_uuid,
        {
          value: override_value,
        },
      ).then((res) => {
        res && getCameraOverridesRequest();
      });
    } else {
      addCameraSettingsOverrides(
        params.company_uuid,
        params.site_uuid,
        params.camera_uuid,
        {
          value: override_value,
          default_camera_setting_uuid: row_uuid,
        },
      ).then((res) => {
        res && getCameraOverridesRequest();
      });
    }
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "default_value",
          name: "Default",
        },
        {
          id: "company_overrides",
          name: "Company override",
        },
        {
          id: "site_overrides",
          name: "Site override",
        },
        {
          id: "camera_overrides",
          name: "Camera override",
        },
        {
          id: "applied_settings",
          name: "Applied settings",
        },
      ]}
      hasPagination={true}
      data={defaultCameraSettings}
      tableBodyRow={(row: any, index) => {
        const camera_has_override = getCameraOverride(
          row.uuid,
        ).has_camera_override;
        const camera_override_value =
          getCameraOverride(row.uuid).camera_override_value || "";
        const camera_override_uuid = getCameraOverride(
          row.uuid,
        ).camera_override_uuid;
        return (
          <TableRow key={row.uuid}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <Tooltip title={row?.description} placement="top-start">
                <p className="truncate_text">{row?.description}</p>
              </Tooltip>
            </TableCell>
            <TableCell>{row.default_value.toString()}</TableCell>
            <TableCell>
              {companyCameraOverrides &&
                companyCameraOverrides
                  .find(
                    (company_override) =>
                      company_override.default_camera_setting_uuid === row.uuid,
                  )
                  ?.value?.toString()}
            </TableCell>
            <TableCell>
              {sitesOverrides &&
                sitesOverrides
                  .find(
                    (site_override) =>
                      site_override.default_camera_setting_uuid === row.uuid,
                  )
                  ?.value?.toString()}
            </TableCell>
            <TableCell>
              <Box display={"flex"} alignItems="center" height={24}>
                {/* {row.data_type === 'FLOAT' || row.data_type === 'INT' ?
                  <EditText
                    style={{ width: "150px" }}
                    type={"number"}
                    data-testid={"override_value"}
                    name="override_value"
                    defaultValue={camera_override_value}
                    onSave={({ value }) => handleOverridesChange(row.uuid, value, camera_override_uuid, true)} />
                  :
                  <Box width="150px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ py: 0 }}
                          onChange={(e) => { handleOverridesChange(row.uuid, e.target.checked, camera_override_uuid, false) }}
                          checked={camera_has_override ? JSON.parse(camera_override_value) : false} />}
                      label={camera_override_value}
                    />
                  </Box>
                } */}
                <EditText
                  style={{ width: "150px" }}
                  data-testid={"override_value"}
                  name="override_value"
                  defaultValue={camera_override_value}
                  onSave={({ value }) =>
                    handleOverridesChange(row.uuid, value, camera_override_uuid)
                  }
                />
                {camera_has_override && (
                  <Box display={"flex"} alignItems="center">
                    <DeleteSettingsOverrides
                      overrides_settings_uuid={
                        getCameraOverride(row.uuid).camera_override_uuid
                      }
                      onDelete={() => getCameraOverridesRequest()}
                    />
                  </Box>
                )}
              </Box>
            </TableCell>
            <TableCell>
              {cameraSettings &&
                cameraSettings
                  .find(
                    (applied_settings) => applied_settings.uuid === row.uuid,
                  )
                  ?.value?.toString()}
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
