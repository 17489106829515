import { Service } from "./Service";

export interface Attribute {
  key: string;
  value: string;
  type?: AttributeTypes;
  id?: string;
}

export enum AttributeTypes {
  SETTINGS = "settings",
  QUERY_PARAMS = "query_params",
  URL_REPLACEMENT_VALUE = "url_replacement_values",
}
export interface CameraConnection {
  is_active: boolean;
  is_http: boolean;
  name: string;
  url: string;
  url_template: string;
  uuid: string;
  attributes: Array<Attribute>;
}

export interface Camera {
  name: string;
  uuid: string;
  company_uuid: string;
  site_uuid: string;
  services: Service[];
  camera_connection: CameraConnection;
}
export interface CreateCameraPayload {
  name: string;
  services_uuid: Array<string>;
}
export enum AuthType {
  NONE = "none",
  BASIC = "basic",
  DIGEST = "digest",
}

export type CreateCameraConnectionFormValues = {
  base_template: string;
  name: string;
  is_http: boolean;
  url_template: string;
  auth_type: AuthType;
  username: string;
  password: string;
  attributes: Attribute[];
  attributes_fields: Attribute[];
};

export interface CreateCameraConnectionPayload {
  camera_uuid: string;
  company_uuid: string;
  site_uuid: string;
  name: string;
  is_http: boolean;
  url_template: string;
  auth_type: AuthType;
  username: string;
  password: string;
  attributes: Attribute[];
}

export type UpdateCameraConnectionPayload = Pick<
  CreateCameraConnectionPayload,
  "name" | "is_http" | "url_template" | "auth_type" | "username" | "password"
>;
export interface CameraConnectionResponse {
  name: string;
  is_http: boolean;
  url_template: string;
  auth_type: AuthType;
  username: string;
  password: string;
  uuid: string;
  is_active: boolean;
  attributes: Attribute[];
  url: string;
}

export interface CompanyTemplateAttributesResponse {
  attributes: Attribute[];
  template_uuid: string;
}
export interface CameraConnectionAttributes {
  attributes: Attribute[];
  connection_uuid: string;
}

export type FormValues = Omit<
  CreateCameraConnectionPayload,
  "camera_uuid" | "company_uuid" | "site_uuid"
> & {
  base_template: string;
  attributes_fields?: Attribute[];
};
export type SetFieldValue = (
  field: string,
  value: string | Array<Attribute> | boolean,
  shouldValidate?: boolean | undefined,
) => void;

export type SetFieldError = (arg0: string, arg1: string) => void;
export type Replace = (arg0: number, arg1: Attribute) => void;
export type BaseTemplates = Record<
  string,
  Array<CameraConnectionResponse & { type: string }>
>;
