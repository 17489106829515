export const redirectionURLs = [
    {
      name: "Spill dashboard",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL_SPILL_DASHBOARD,
    },
    {
      name: "Central platform",
      redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URL_CENTRAL_PLATFORM,
    },
  ];
  