/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  hideTier,
  unhideTier,
  getTiers,
} from "../../apis/v1/Services/Tiers";
import TiersListTable from "../../components/Tiers/TiersListTable";
import { TierResponse } from "../../interfaces/Tier";
import { getCompanies } from "../../apis/v1/Companies";
import { Company } from "../../interfaces/Company";

export default function TiersList() {
  const navigate = useNavigate();
  const params = useParams();
  const [tiers, setTiers] = useState<TierResponse[]>();
  const [companies, setCompanies] = useState<Company[]>();

  useEffect(() => {
    getCompaniesRequest();
    getTiersRequest();
  }, []);

  const getTiersRequest = () => {
    getTiers(params.service_uuid).then((res) => setTiers(res.data));
  };

  const getCompaniesRequest = () => {
    getCompanies().then((res) => setCompanies(res.data))
  }

  const handleChangeHiddenStatus = (tier_uuid: string, is_hidden: boolean) => {
    if (is_hidden) {
      unhideTier(params.service_uuid, tier_uuid).then(() => {
        getTiersRequest();
      });
    } else {
      hideTier(params.service_uuid, tier_uuid).then(() => {
        getTiersRequest();
      });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Tiers" subheader="List of tiers" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() => navigate(`/service/${params.service_uuid}/tier/add`)}
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <TiersListTable
          tiers={tiers}
          setTiers={setTiers}
          companies={companies}
          handleChangeHiddenStatus={handleChangeHiddenStatus}
        />
      </CardContent>
    </Card>
  );
}
