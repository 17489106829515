import { AxiosPromise } from "axios";
import { License, LicensePayload } from "../../interfaces/License";
import { axiosInstance } from "../config/axiosInstance";

const generateLicensesBaseURL = (company_uuid) =>
  `/permission-management/v1/companies/${company_uuid}`;

const generateUserLicensesURL = (company_uuid, user_uuid) =>
  `${generateLicensesBaseURL(company_uuid)}/users/${user_uuid}/licences`;

export const getUserLicenses = (
  user_uuid: string,
  company_uuid: string,
): AxiosPromise<License[]> => {
  return axiosInstance.get(
    `${generateUserLicensesURL(company_uuid, user_uuid)}`,
  );
};

export const getLicenses = (
  company_uuid: string,
  service_uuid: string,
): AxiosPromise<License[]> => {
  return axiosInstance.get(
    `${generateLicensesBaseURL(company_uuid)}/services/${service_uuid}/licences`,
  );
};

export const assignLicense = (data: LicensePayload): AxiosPromise<void> => {
  return axiosInstance.post(
    `${generateUserLicensesURL(data.company_uuid, data.user_uuid)}`,
    {
      service_uuids: data.service_uuids,
    },
  );
};

export const unassignLicense = (data: LicensePayload): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateUserLicensesURL(data.company_uuid, data.user_uuid)}`,
    {
      params: {
        service_uuid: data.service_uuid,
      },
    },
  );
};

export const updateUserLicenses = (
  data: LicensePayload,
): AxiosPromise<void> => {
  return axiosInstance.patch(
    `${generateUserLicensesURL(data.company_uuid, data.user_uuid)}`,
    {
      service_uuids: data.service_uuids,
    },
  );
};
