import AddSubscriptionForm from "../../components/Subscriptions/AddSubscriptionForm";
import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { createSubscription } from "../../apis/v1/Subscriptions/Subscriptions";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { Service } from "../../interfaces/Service";
import { useEffect, useState } from "react";
import { getServices } from "../../apis/v1/Services/Services";
import { getTiersPerCompany, getAllTiers } from "../../apis/v1/Services/Tiers";
import { TierResponse } from "../../interfaces/Tier";

export default function AddSubscription() {
  const navigate = useNavigate();
  const [services, setServices] = useState<Service[]>();
  const [tiers, setTiers] = useState<TierResponse[]>();
  const [customTiers, setCustomTiers] = useState<TierResponse[]>();

  const params = useParams();
  const handleSubmit = (values) => {
    createSubscription(values, params.company_uuid).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  useEffect(() => {
    getServices().then((res) => {
      setServices(res.data);
    });
    getAllTiers().then((res) => {
      setTiers(res.data);
    });
    getTiersPerCompany(params.company_uuid).then((res) => {
      setCustomTiers(res.data);
    });
  }, [params.company_uuid]);

  return (
    <>
      <PagesHeader title="Add Subscription" />
      <Grid container mt={3}>
        <Grid item sm={12} md={8} xl={6}>
          <AddSubscriptionForm
            handleSubmit={handleSubmit}
            services={services}
            customTiers={customTiers}
            tiers={tiers}
          />
        </Grid>
      </Grid>
    </>
  );
}
