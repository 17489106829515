import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import { addCompanyIncidentClass } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";
import { useEffect, useState } from "react";
import { getGlobalIncidentClasses } from "../../apis/v1/DetectionZone/GlobalIncidentClasses";
import { AWAITING_SYNC_MSG } from "../../utilis/shared";
import { AxiosResponse } from "axios";
import { DetectionZoneCompanyLabelsPayload } from "../../interfaces/Detections";
import AddCompanyIncidentClassForm from "../../components/CompanyIncidentClass/v1/AddCompanyIncidentClassForm";
import { GIncidentClass } from "../../interfaces/IncidentClass";

export default function AddCompanyIncidentClass() {
  const navigate = useNavigate();
  const params = useParams();
  const [incidentClasses, setIncidentClasses] = useState([]);

  useEffect(() => {
    getGlobalIncidentClasses().then((res : AxiosResponse<GIncidentClass[]>) => {
      if (res) {
        setIncidentClasses(res.data);
      }
    });
  }, []);

  const handleSubmitForm = (values : DetectionZoneCompanyLabelsPayload) => {
    addCompanyIncidentClass(params.company_uuid, values)
      .then((res) => {
        if (res) {
          showSnackbar("Done successfully", "success");
          navigate(-1);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data.message === "Company not found"
        ) {
          showSnackbar(AWAITING_SYNC_MSG, "warning");
        }
      });
  };

  return (
    <>
      <PagesHeader title="Add Company Incident Class" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddCompanyIncidentClassForm
            incidentClasses={incidentClasses}
            handleSubmitForm={handleSubmitForm}
          />
        </Grid>
      </Grid>
    </>
  );
}
