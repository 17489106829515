import { axiosInstance } from "../../config/axiosInstance";
import {
  CameraSettingsOverridesPayload,
  CameraSettingsOverridesResponse,
  CameraSettingsResponse,
} from "../../../interfaces/Settings";
import { AxiosPromise } from "axios";

const generateCameraOverridesURL = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  camera_override_uuid?: string,
) => {
  let url = `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}/settings/camera-overrides`;
  if (camera_override_uuid) {
    url += `/${camera_override_uuid}`;
  }
  return url;
};

export const getCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
): AxiosPromise<CameraSettingsOverridesResponse[]> => {
  return axiosInstance.get(
    generateCameraOverridesURL(company_uuid, site_uuid, camera_uuid),
  );
};

export const addCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  data: CameraSettingsOverridesPayload,
): AxiosPromise<CameraSettingsOverridesResponse> => {
  return axiosInstance.post(
    generateCameraOverridesURL(company_uuid, site_uuid, camera_uuid),
    data,
  );
};

export const editCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  camera_override_uuid: string,
  data: Omit<CameraSettingsOverridesPayload, "default_camera_setting_uuid">,
): AxiosPromise<CameraSettingsOverridesResponse> => {
  return axiosInstance.patch(
    generateCameraOverridesURL(
      company_uuid,
      site_uuid,
      camera_uuid,
      camera_override_uuid,
    ),
    data,
  );
};

export const deleteCameraSettingsOverrides = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
  camera_override_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    generateCameraOverridesURL(
      company_uuid,
      site_uuid,
      camera_uuid,
      camera_override_uuid,
    ),
  );
};

export const getCameraSettings = (
  company_uuid: string,
  site_uuid: string,
  camera_uuid: string,
): AxiosPromise<CameraSettingsResponse> => {
  return axiosInstance.get(
    `/organisation-management/v1/companies/${company_uuid}/sites/${site_uuid}/cameras/${camera_uuid}/settings`,
  );
};
