import { useParams } from "react-router-dom";
import {
  activateCameraConnection,
  deactivateCameraConnection,
} from "../../apis/v1/Camera/CameraConnection";
import CameraConnectionTable from "../../components/Cameras/v1/CameraConnection/ListTable";

export default function CameraConnectionList({
  camera,
  getCameraDetailsRequest,
  location_state_details,
  camera_details,
}) {
  const params = useParams();

  const handleChangeStatus = (uuid, is_active) => {
    if (is_active) {
      deactivateCameraConnection(
        params.company_uuid,
        params.site_uuid,
        params.camera_uuid,
        uuid,
      ).then((res) => {
        if (res) {
          getCameraDetailsRequest();
        }
      });
    } else {
      activateCameraConnection(
        params.company_uuid,
        params.site_uuid,
        params.camera_uuid,
        uuid,
      ).then((res) => {
        if (res) {
          getCameraDetailsRequest();
        }
      });
    }
  };

  return (
    <CameraConnectionTable
      cameraConnections={camera?.camera_connection}
      getCameraDetailsRequest={getCameraDetailsRequest}
      handleChangeStatus={handleChangeStatus}
      location_state_details={location_state_details}
      camera_details={camera_details}
    />
  );
}
