import { AxiosPromise } from "axios";
import { Reason } from "../../../interfaces/Credits";
import { axiosInstance } from "../../config/axiosInstance";

const reasonBaseURL = `payment/v1/reasons`;

export const createReason = (
  data: Omit<Reason, "uuid">,
): AxiosPromise<Reason> => {
  return axiosInstance.post(reasonBaseURL, data);
};

export const getReasons = (): AxiosPromise<Reason[]> => {
  return axiosInstance.get(reasonBaseURL);
};

export const getReason = (reason_uuid: string): AxiosPromise<Reason> => {
  return axiosInstance.get(`${reasonBaseURL}/${reason_uuid}`);
};

export const editReason = (
  reason_uuid: string,
  data: Omit<Reason, "uuid">,
): AxiosPromise<Reason> => {
  return axiosInstance.patch(`${reasonBaseURL}/${reason_uuid}`, data);
};
export const deleteReason = (reason_uuid: string): AxiosPromise<void> => {
  return axiosInstance.delete(`${reasonBaseURL}/${reason_uuid}`);
};
