import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Box } from "@mui/material";
import { sliceSortedData, sortData } from "../../utilis/sort";
import MUITableHead from "./TableHead";
import MUITablePagination from "./TablePagination";
import NoDataFound from "./NoDataFound";
import { orderType } from "../../enums/Order";
import { TableOrder } from "./Model/TableOrder";

interface MUITableProps {
  tableHeaderCells: any;
  tableBodyRow: (row, index) => JSX.Element;
  data: any;
  hasPagination: boolean;
  showFetchingDataProblemText?: boolean;
  orderByOption?: string;
  orderOption?: Order;
}

type Order = orderType.ASC | orderType.DESC;

export default function MUITable({
  tableHeaderCells,
  tableBodyRow,
  data,
  hasPagination,
  showFetchingDataProblemText,
  orderByOption,
  orderOption,
}: MUITableProps) {
  const tableOrder = new TableOrder();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>(
    orderOption ?? tableOrder.getCurrentOrder(),
  );
  const [orderBy, setOrderBy] = React.useState<string>(orderByOption ?? "name");

  React.useEffect(() => {
    setPage(0);
  }, [data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    selectedSortProperty: string,
  ) => {
    tableOrder.toggleOrder(order);
    setOrder(tableOrder.getCurrentOrder());
    setOrderBy(selectedSortProperty);
  };

  const createSortHandler =
    (selectedSortProperty: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, selectedSortProperty);
    };

  return (
    <Box borderTop={"1px solid #e7e7e7"}>
      <TableContainer data-testid="table-container">
        <Table size="small">
          <MUITableHead
            tableHeaderCells={tableHeaderCells}
            orderBy={orderBy}
            order={order}
            createSortHandler={createSortHandler}
          />
          <TableBody>
            {sliceSortedData(
              sortData(data, order, orderBy),
              page,
              rowsPerPage,
            )?.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  {tableBodyRow(row, index)}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <NoDataFound
        data={data}
        showFetchingDataProblemText={showFetchingDataProblemText}
      />
      {hasPagination && (
        <MUITablePagination
          data={data}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
