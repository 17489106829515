import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import MUISelect from "../../Controls/Select";
import MultiSelect from "../../Controls/MultiSelect";
import { useAppSelector } from "../../../hooks/useStoreHook";
import { LoadingButton } from "@mui/lab";
import { Service } from "../../../interfaces/Service";
import { Country } from "../../../interfaces/Address";
import { CompanyFormValues } from "../../../interfaces/Company";

interface PropsType {
  handleSubmitCompany: (values: CompanyFormValues) => void;
  services: Service[];
  countries: Country[];
}
export default function AddCompanyForm({
  handleSubmitCompany,
  services,
  countries,
}: Readonly<PropsType>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const formInitialValues: CompanyFormValues = {
    name: "",
    country_code: "",
    phone: "",
    address_1: "",
    address_2: "",
    country: "",
    county: "",
    postcode: "",
    city: "",
    services_uuid: [],
  };

  const schema: SchemaOf<CompanyFormValues> = yup.object().shape({
    name: yup.string().required("Required"),
    country_code: yup.string().when("phone", {
      is: (phone) => phone && phone.length > 0,
      then: () => yup.string().required("Required"),
      otherwise: () => yup.string().nullable(),
    }),
    phone: yup.string().when("country_code", {
      is: (country_code) => country_code && country_code.length > 0,
      then: () =>
        yup
          .string()
          .required("Required")
          .matches(/^[+]*\d*$/g, "Please enter numbers"),
      otherwise: () => yup.string().nullable(),
    }),
    address_1: yup.string().required("Required"),
    address_2: yup.string().nullable(),
    country: yup.string().required("Required"),
    county: yup.string().required("Required"),
    postcode: yup.string().required("Required"),
    city: yup.string().required("Required"),
    services_uuid: yup.array(yup.string()).nullable(),
  },[["country_code", "phone"]]);

  const renderCountry = (values) => {
    const country = countries.find(
      (country) => country.uuid === values.country,
    );
    return (
      <>
        {country.flag} {country.name}
      </>
    );
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={schema}
      onSubmit={(values: CompanyFormValues) => handleSubmitCompany(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <Box mb={1} component={"h4"}>
            Company Details
          </Box>
          <TextField
            type="text"
            id="company-name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
          />
             <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
            <Grid item xs={3}>
              <MUISelect
                error={
                  errors.country_code &&
                  touched.country_code &&
                  errors.country_code
                }
                value={values.country_code}
                label={"Code"}
                dataTestid={"code"}
                name="country_code"
                handleChange={handleChange}
                handleBlur={handleBlur}
                keyItem="code"
                keyValue="flag"
                keyAppend="dial_code"
                list={countries}
                labelId={"label-code"}
                id={"select-code"}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                type="text"
                id="phone"
                label="Phone"
                fullWidth
                name="phone"
                error={Boolean(errors.phone && touched.phone)}
                helperText={errors.phone && touched.phone && errors.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                size="small"
              />
            </Grid>
          </Grid>
          <Box mb={1} component={"h4"}>
            Address Details
          </Box>
          <TextField
            id="company-address-line-1"
            label="Address line 1*"
            fullWidth
            margin="normal"
            name="address_1"
            error={Boolean(errors.address_1 && touched.address_1)}
            helperText={
              errors.address_1 && touched.address_1 && errors.address_1
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address_1}
            size="small"
          />
          <TextField
            id="company-address-line-2"
            label="Address line 2"
            fullWidth
            margin="normal"
            name="address_2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address_2}
            size="small"
          />
          <TextField
            id="company-city"
            label="City*"
            fullWidth
            margin="normal"
            name="city"
            error={Boolean(errors.city && touched.city)}
            helperText={errors.city && touched.city && errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            size="small"
          />
          <TextField
            id="company-county"
            label="State / Province / Region*"
            fullWidth
            margin="normal"
            name="county"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.county}
            size="small"
            error={Boolean(errors.county && touched.county)}
            helperText={errors.county && touched.county && errors.county}
          />
          <TextField
            id="company-postcode"
            label="Zip / Postal Code*"
            fullWidth
            margin="normal"
            name="postcode"
            error={Boolean(errors.postcode && touched.postcode)}
            helperText={errors.postcode && touched.postcode && errors.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.postcode}
            sx={{ mb: 3 }}
            size="small"
          />
          <MUISelect
            error={errors.country && touched.country && errors.country}
            value={values.country}
            label={"Country"}
            required
            name="country"
            dataTestid={"country"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            keyPreAppend="flag"
            list={countries}
            labelId={"label-country"}
            id={"select-country"}
            renderValue={() => renderCountry(values)}
          />
          <Box component={"h4"} mb={0}>
            Services
          </Box>
          <Box component={"p"} mt={0}>
            Select a service to poll cameras
          </Box>
          <Field name="services_uuid">
            {({ form, field, meta }: any) => (
              <>
                <MultiSelect
                  label="Services"
                  dataTestid={"services"}
                  meta={meta}
                  form={form}
                  field={field}
                  list={services}
                  uuid="uuid"
                  value="name"
                />
              </>
            )}
          </Field>
          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate("/companies")}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
