import { checkAuth, refreshToken } from "../v1/Auth";
import { axiosInstance } from "./axiosInstance";
import { AxiosError, AxiosResponse } from "axios";

interface FailedQueueTypes {
  resolve: (response: AxiosResponse | null) => void;
  reject: (error: AxiosError) => void;
}

let isRefreshing: boolean = false;
let failedQueue: Array<FailedQueueTypes> = [];

const processQueue = (
  error: AxiosError | null,
  response: AxiosResponse | null,
) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(response);
    }
  });

  failedQueue = [];
};

export const refreshTokenHandler = async (error: any) => {
  const originalRequest = error.config;
  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then((token) => {
        originalRequest.headers["Authorization"] = "Bearer " + token;
        return axiosInstance(originalRequest);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  originalRequest._retry = true;
  isRefreshing = true;

  return await new Promise(function (resolve) {
    refreshToken()
      .then((res: AxiosResponse) => {
        const { access_token, refresh_token } = res.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + access_token;
        originalRequest.headers["Authorization"] = "Bearer " + access_token;
        processQueue(null, access_token);
        resolve(axiosInstance(originalRequest));
      })
      .catch((error: AxiosError) => {
        handleFailedRefreshToken(error);
      })
      .then(() => {
        isRefreshing = false;
      });
  });
};

const handleFailedRefreshToken = (error: AxiosError) => {
  localStorage.clear();
  processQueue(error, null);
  checkAuth(401);
};
