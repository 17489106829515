/* eslint-disable react-hooks/exhaustive-deps */
import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { createConsumableAddons } from "../../apis/v1/Services/ConsumablesAddons";
import AddConsumableAddonsForm from "../../components/ConsumablesAddons/AddConsumableAddonsForm";
import { useEffect, useState } from "react";
import { getTiers } from "../../apis/v1/Services/Tiers";
import { Country } from "../../interfaces/Address";
import { getCountries } from "../../apis/v1/Countries";

export default function AddConsumableAddons() {
  const navigate = useNavigate();
  const params = useParams();
  const [tiers, setTiers] = useState<any>();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    getTiers(params.service_uuid).then((res) => {
      setTiers(res.data);
    });

    getCountries().then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handleSubmit = (values) => {
    createConsumableAddons(
      params.service_uuid,
      params.consumable_uuid,
      values,
    ).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Add Consumable Addon" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddConsumableAddonsForm
            tiers={tiers}
            handleSubmit={handleSubmit}
            countries={countries}
          />
        </Grid>
      </Grid>
    </>
  );
}
