import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteCamera } from "../../../../apis/v1/Camera/Camera";
import { showSnackbar } from "../../../../utilis/snackbar";
import ConfirmationPopup from "../../../ConfirmationPopup";
import { Camera } from "../../../../interfaces/Camera";

type Props = {
  camera: Camera;
  onCameraDeleted: () => void;
};
export default function DeleteCamera({
  camera,
  onCameraDeleted,
}: Readonly<Props>) {
  const [openDialog, setOpenDialog] = useState(false);
  const params = useParams();

  const submitDeleteCamera = () => {
    deleteCamera(params.company_uuid, params.site_uuid, camera.uuid).then(
      (res) => {
        onCameraDeleted();
        if (res) {
          setOpenDialog(false);
          showSnackbar("Deleted Successfully", "success");
        }
      },
    );
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Camera"
        dialogBody="Are you sure you want to delete this camera?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteCamera()}
      />
    </>
  );
}
