export default function CardOverrides() {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: 12,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: "#f6f6f8",
          borderBottom: " 1px solid #e7e7e7",
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 3,
          paddingBottom: 3,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
  };
}
