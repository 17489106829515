/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import AllSubscriptionsHistoriesFilter from "./AllSubscriptionsHistoriesFilters";
import Loader from "../Loader";
import AllSubscriptionsHistoriesListTable from "./AllSubscriptionsHistoryListTable";
import {
  getSubscriptionHistoryActions,
  getSubscriptionsHistories,
} from "../../apis/v1/Subscriptions/Subscriptions";

export default function SubscriptionsAuditHistoryCard({
  rootNodes,
  companies,
  services,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionHistories, setSubscriptionHistories] = useState([]);
  const [subscriptionActions, setSubscriptionActions] = useState([]);
  const [subscriptionsHistoryFilters, setSubscriptionsHistoryFilters] =
    useState();

  const fetchSubscriptionsHistories = () => {
    getSubscriptionsHistories(subscriptionsHistoryFilters).then((res) => {
      if (res) {
        setSubscriptionHistories(res.data);
      }
    });
  };
  const fetchSubscriptionHistoryActions = async () => {
    try {
      const response = await getSubscriptionHistoryActions();
      setSubscriptionActions(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionsHistories();
  }, [subscriptionsHistoryFilters]);

  useEffect(() => {
    fetchSubscriptionHistoryActions();
  }, []);

  console.log(subscriptionsHistoryFilters);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Subscriptions Audit History"
        subheader="List of subscriptions audit history for all subscriptions"
      />
      <CardContent>
        <AllSubscriptionsHistoriesFilter
          setSubscriptionsHistoryFilters={setSubscriptionsHistoryFilters}
          companies={companies}
          services={services}
          subscriptionActions={subscriptionActions}
        />
      </CardContent>
      <CardContent>
        {isLoading && (
          <Loader
            height="30vh"
            content="Loading subscriptions action history, please wait"
          />
        )}
        {subscriptionHistories && companies && !isLoading && (
          <AllSubscriptionsHistoriesListTable
            subscriptionHistories={subscriptionHistories}
            rootNodes={rootNodes}
            companies={companies}
          />
        )}
      </CardContent>
    </Card>
  );
}
