import { Button, Grid } from "@mui/material";
import { useState } from "react";
import MUISelect from "../Controls/Select";
import { activeOptions } from "../../utilis/enum";

export default function AllSubscriptionsFilter({
  setSubscriptionsFilters,
  companies,
  services,
}) {
  const [showReset, setShowReset] = useState<boolean>(false);
  const [companyUUID, setCompanyUUID] = useState<string>(null);
  const [serviceUUID, setServiceUUID] = useState<string>(null);
  const [active, setActive] = useState<number>(null);

  const getActiveOptions = () => {
    return activeOptions.find((option) => option.id === active)?.value;
  };
  const handleFilter = () => {
    setShowReset(true);
    setSubscriptionsFilters({
      company_uuid: companyUUID,
      service_uuid: serviceUUID,
      active: getActiveOptions(),
    });
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Company"
          labelId="label-company-uuid"
          id={"select-company-uuid"}
          name="company_uuid"
          handleChange={(e) => {
            setCompanyUUID(e.target.value);
          }}
          list={companies}
          margin="normal"
          value={companyUUID || ""}
          keyItem="uuid"
          keyValue="name"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Service"
          labelId="label-service-uuid"
          id={"select-service-uuid"}
          name="service_uuid"
          handleChange={(e) => {
            setServiceUUID(e.target.value);
          }}
          list={services}
          margin="normal"
          value={serviceUUID || ""}
          keyItem="uuid"
          keyValue="name"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Status"
          labelId="label-active"
          id={"select-active"}
          name="active"
          handleChange={(e) => {
            setActive(e.target.value);
          }}
          list={activeOptions}
          margin="normal"
          value={active}
          keyItem="id"
          keyValue="name"
        />
      </Grid>
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={6}
        md={3}
      >
        <Button
          variant="outlined"
          data-testid="filter-btn"
          onClick={handleFilter}
        >
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setSubscriptionsFilters({
                company: null,
                service: null,
                active: null,
              });
              setShowReset(false);
              setCompanyUUID(null);
              setServiceUUID(null);
              setActive(null);
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
