import { Button, Card, CardContent, CardHeader } from "@mui/material";
import IncidentCauseListFilters from "../IncidentCauses/IncidentCauseFilter";
import IncidentCauseList from "../IncidentCauses/IncidentCauseList";
import { useEffect, useState } from "react";
import { IncidentCause } from "../../interfaces/IncidentCause";
import { useNavigate, useParams } from "react-router-dom";
import { getIncidentCauseList } from "../../apis/v1/IncidentCause";

export default function IncidentCausesList() {
  const [incidentCauses, setIncidentCauses] = useState<IncidentCause[]>(null);
  const [renderedIncidentCause, setRenderedIncidentCause] =
    useState<IncidentCause[]>(null);

  const [incidentCauseFilters, setIncidentCauseFilters] = useState({
    incident_class: null,
  });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getIncidentCausesRequest();
  }, [params.company_uuid]);

  const getIncidentCausesRequest = () => {
    getIncidentCauseList(params.company_uuid).then((res) => {
      if (res) {
        const data = res.data.map((item) => {
          return {
            ...item,
            incident_class_name: item.incident_class.friendly_name,
            resplution_type_name: item.resolution_type.name,
          };
        });
        setIncidentCauses(data);
        setRenderedIncidentCause(data);
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Incident Causes"
        subheader="Incident causes define the drop down options a user has available to them when closing an incident in the Spill dashboard."
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/company/${params.company_uuid}/add-incident-cause`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <IncidentCauseListFilters
          filters={incidentCauseFilters}
          setFilters={setIncidentCauseFilters}
          setRenderedIncidentCauses={setRenderedIncidentCause}
          originalIncidentCauses={incidentCauses}
          company_uuid={params.company_uuid}
        />
      </CardContent>
      <CardContent>
        <IncidentCauseList
          incident_causes={renderedIncidentCause}
          set_incident_causes={setRenderedIncidentCause}
          company_uuid={params.company_uuid}
        />
      </CardContent>
    </Card>
  );
}
