import { useEffect, useState } from "react";
import { getPolicies, getPolicyFile } from "../../apis/v1/Policies";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PoliciesListTable from "./PoliciesListTable";
import { downloadFile, getFileName } from "../../utilis/downloadFile";

export default function PoliciesListCard() {
  const navigate = useNavigate();
  const [policies, setPolicies] = useState([]);

  const fetchPolicies = async () => {
    try {
      const response = await getPolicies();
      setPolicies(response.data);
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const downloadPolicy = async (uuid) => {
    try {
      const response = await getPolicyFile(uuid);
      if (response) {
        const policyFileName = getFileName(
          response.headers["content-disposition"],
        );
        downloadFile(response.data, policyFileName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Policies"
        subheader="List of policies includes privacy policy and terms for central platform"
      />
      <CardContent>
        <Button variant={"outlined"} onClick={() => navigate("/add-policy")}>
          Add
        </Button>
      </CardContent>
      <CardContent>
        <PoliciesListTable
          policies={policies}
          downloadPolicy={downloadPolicy}
        />
      </CardContent>
    </Card>
  );
}
