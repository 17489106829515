/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { useLocation, useParams } from "react-router-dom";
import MUIBreadcrumb from "../../components/Breadcrumb";
import ChildNodesList from "../../components/ChildNodes/ChildNodesList";
import AddNodeMenu from "../../components/ChildNodes/AddNodeMenu";
import { OrgStructureNode } from "../../interfaces/OrgStructure";

export default function RootNode() {
  const params = useParams();
  const location = useLocation();
  const [parentNode, setParentNode] = useState<OrgStructureNode>();

  const requestNodes = () => {
    getCompanyRootNodes(params.company_uuid, params.root_node_uuid).then(
      (res) => {
        setParentNode(res.data);
      },
    );
  };

  useEffect(() => {
    requestNodes();
  }, [params.root_node_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
            },
          ],
          current: location && parentNode?.name,
        }}
      />
      <PagesHeader title="Node Management" />
      <Card variant="outlined">
        <CardHeader
          title={"Root Node"}
          subheader="This is the root node details"
        />
        <CardContent sx={{ mb: 2 }}>
          <Typography> {parentNode?.name}</Typography>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardHeader
          title={"Child Nodes"}
          subheader="This is the root node child nodes list"
        />
        <CardContent>
          <AddNodeMenu
            parentNode={parentNode}
            state={[
              {
                name: location.state.company_name,
                path: location.state.prevCompanyLocation,
                state: location.state,
              },
              {
                name: parentNode?.name,
                path: location.pathname,
                state: location.state,
              },
            ]}
          />
        </CardContent>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ChildNodesList
                childNodes={parentNode?.children}
                state={[
                  {
                    name: location.state.company_name,
                    path: location.state.prevCompanyLocation,
                    state: location.state,
                  },
                  {
                    name: parentNode?.name,
                    path: location.pathname,
                    state: location.state,
                  },
                ]}
                updateNodeList={requestNodes}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
