import { axiosInstance } from "../../config/axiosInstance";
import { AxiosResponse } from "axios";
import {
  Attribute,
  CameraConnectionResponse,
  CompanyTemplateAttributesResponse,
  CreateCameraConnectionPayload,
  UpdateCameraConnectionPayload,
} from "../../../interfaces/Camera";

const companyTemplateURL = (company_uuid: string, template_uuid?: string) => {
  if (template_uuid) {
    return `/organisation-management/v1/companies/${company_uuid}/camera-connection-templates/${template_uuid}`;
  }
  return `/organisation-management/v1/companies/${company_uuid}/camera-connection-templates`;
};

export const getTemplateDetails = (
  company_uuid: string,
  template_uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.get(companyTemplateURL(company_uuid, template_uuid));
};

export const editTemplateDetails = (
  company_uuid: string,
  template_uuid: string,
  data: UpdateCameraConnectionPayload,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.patch(companyTemplateURL(company_uuid, template_uuid), {
    ...data,
  });
};

export const deleteAttribute = (
  company_uuid: string,
  template_uuid: string,
  params: Record<string, string>,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    `${companyTemplateURL(company_uuid, template_uuid)}/attribute`,
    {
      params,
    },
  );
};

export const getTemplateList = (
  company_uuid?: string,
  is_active?: boolean,
): Promise<AxiosResponse<CameraConnectionResponse[]>> => {
  return axiosInstance.get(companyTemplateURL(company_uuid), {
    params: {
      is_active,
    },
  });
};

export const deleteTemplate = (
  company_uuid: string,
  template_uuid: string,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(companyTemplateURL(company_uuid, template_uuid));
};

export const createTemplate = (
  data: CreateCameraConnectionPayload,
): Promise<AxiosResponse<CreateCameraConnectionPayload>> => {
  return axiosInstance.post(companyTemplateURL(data.company_uuid), data);
};

export const createTemplateAttribute = (
  company_uuid: string,
  template_uuid: string,
  data: Attribute,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    `${companyTemplateURL(company_uuid, template_uuid)}/attribute`,
    data,
  );
};

export const getCompanyTemplateAttributesByUUID = (
  company_uuid: string,
  template_uuid: string,
): Promise<AxiosResponse<CompanyTemplateAttributesResponse>> => {
  return axiosInstance.get(
    `${companyTemplateURL(company_uuid, template_uuid)}/attribute`,
  );
};

export const activateOcuconTemplate = (
  company_uuid: string,
  template_uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    `${companyTemplateURL(company_uuid, template_uuid)}/activate`,
  );
};

export const deactivateOcuconTemplate = (
  company_uuid: string,
  template_uuid: string,
): Promise<AxiosResponse<CameraConnectionResponse>> => {
  return axiosInstance.post(
    `${companyTemplateURL(company_uuid, template_uuid)}/deactivate`,
  );
};

export const editCompanyTemplateAttribute = (
  company_uuid: string,
  template_uuid: string,
  params: Record<string, string>,
  body: Record<"value", string>,
): Promise<AxiosResponse<Attribute>> => {
  return axiosInstance.patch(
    `${companyTemplateURL(company_uuid, template_uuid)}/attribute`,
    body,
    {
      params: {
        ...params,
      },
    },
  );
};
