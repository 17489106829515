/* eslint-disable react-hooks/exhaustive-deps */
import PagesHeader from "../../components/PagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Customer } from "../../interfaces/User";
import { getCustomers, editCustomer } from "../../apis/v1/UserManagement";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EditUserForm from "../../components/Users/v1/EditUser/EditUserForm";
import { showSnackbar } from "../../utilis/snackbar";
import { ADD_USER_TO_SITES_NOTE } from "../../utilis/shared";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import {
  getFlattenChildrenNodesList,
  resetList,
} from "../../utilis/format-child-nodes";

export default function EditUser() {
  const navigate = useNavigate();
  const params = useParams();
  const [customer, setCustomer] = useState<Customer>();
  const [parentNodes, setParentNodes] = useState([]);

  useEffect(() => {
    getCustomers({company_uuid : params.company_uuid}).then((res) => {
      if (res) {
        const customerDetails = res.data.find(
          (customer) => customer.id === params.user_id,
        );
        setCustomer(customerDetails);
      }
    });
    resetList();
    getCompanyRootNodes(params.company_uuid, params.node_uuid).then((res) => {
      const nodes = getFlattenChildrenNodesList(res.data);
      setParentNodes(nodes);
    });

    return () => {
      resetList();
    };
  }, []);

  const handleSubmit = (values) => {
    editCustomer(params.user_id, {
      ...values,
      org_structure_assignments: values.org_structure_assignments
        ? [values.org_structure_assignments]
        : [],
    }).then((res) => {
      if (res) {
        showSnackbar("User edited successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit User" />
      <Typography color="error" className="text-danger">
        {ADD_USER_TO_SITES_NOTE}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          {customer && (
            <EditUserForm
              parentNodes={parentNodes}
              customer={customer}
              handleSubmit={handleSubmit}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
