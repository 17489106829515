import { Box, Grid } from "@mui/material";
import { Service } from "../../../../interfaces/Service";
import { Camera } from "../../../../interfaces/Camera";

type Props = {
  camera: Camera;
};
export default function CameraDetailsCard({ camera }: Readonly<Props>) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="camera-name" item xs={12} sm={6}>
        <Box component={"h6"} mb={0}>
          camera name
        </Box>
        <Box component={"p"} mt={1} mb={0}>
          {camera.name}
        </Box>
      </Grid>
      <Grid data-testid="camera-services" item xs={12} sm={12}>
        <Box component={"h6"} mb={0}>
          Services
        </Box>
        <Box mt={1}>
          <ul>
            {camera?.services?.map((service: Service) => (
              <li data-testid="camera-service-item" key={service.uuid}>
                {service.name}
              </li>
            ))}
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
}
