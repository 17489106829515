import { axiosInstance } from "../../config/axiosInstance";
import {
  CompanyRule,
  CompanyRulePayload,
  RuleHistory,
} from "../../../interfaces/Notifications";
import { AxiosPromise } from "axios";

const notificationsBase = `/notification/v1`;

const generateRulesUrl = (company_uuid: string) =>
  `${notificationsBase}/companies/${company_uuid}/rules`;

export const getCompanyRules = (
  company_uuid: string,
): AxiosPromise<CompanyRule[]> => {
  return axiosInstance.get(generateRulesUrl(company_uuid));
};

export const getRule = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<CompanyRule> => {
  return axiosInstance.get(`${generateRulesUrl(company_uuid)}/${rule_uuid}`);
};

export const addRule = (
  company_uuid: string,
  data: CompanyRulePayload,
): AxiosPromise<CompanyRule> => {
  return axiosInstance.post(generateRulesUrl(company_uuid), data);
};

export const editRule = (
  company_uuid: string,
  rule_uuid: string,
  data: Omit<CompanyRulePayload, "type">,
): AxiosPromise<CompanyRule> => {
  return axiosInstance.patch(
    `${generateRulesUrl(company_uuid)}/${rule_uuid}`,
    data,
  );
};

export const deleteRule = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(`${generateRulesUrl(company_uuid)}/${rule_uuid}`);
};

export const activateRule = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<CompanyRule> => {
  return axiosInstance.post(
    `${generateRulesUrl(company_uuid)}/${rule_uuid}/activate`,
  );
};

export const deactivateRule = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<CompanyRule> => {
  return axiosInstance.post(
    `${generateRulesUrl(company_uuid)}/${rule_uuid}/deactivate`,
  );
};

export const getRuleHistory = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<RuleHistory[]> => {
  return axiosInstance.get(
    `${generateRulesUrl(company_uuid)}/${rule_uuid}/rule-history`,
  );
};

export const getRuleTypes = (): AxiosPromise<Array<string>> => {
  return axiosInstance.get(`${notificationsBase}/rule-types`);
};
