/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateOcuconTemplate,
  getOcuconTemplateAttributesByUUID,
  getTemplateDetails,
} from "../../../apis/v1/Camera/OcuconCameraTemplates";
import EditOcuconTemplateStep2 from "../../../components/Cameras/v1/OcuconCameraConnectionTemplate/Edit/Step2";
import PagesHeader from "../../../components/PagesHeader";

export default function EditOcuconTemplateAttributes() {
  const [template, setTemplate] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const getAttributes = () => {
    getOcuconTemplateAttributesByUUID(params.uuid).then((res) => {
      if (res) {
        setAttributes(
          res.data.attributes.map((attr, index) => ({
            ...attr,
            id: index.toString(),
          })),
        );
      }
    });
  };

  const activateTemplate = () => {
    activateOcuconTemplate(params.uuid).then((res) => {
      res && navigate(-2);
    });
  };

  useEffect(() => {
    getTemplateDetails(params.uuid).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
    getAttributes();
  }, []);
  return (
    <>
      <PagesHeader title="Edit Ocucon Camera Connection Template Attributes" />

      <EditOcuconTemplateStep2
        attributes={attributes}
        template={template}
        activateTemplate={activateTemplate}
      />
    </>
  );
}
