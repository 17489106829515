import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import MUISelect from "../../../Controls/Select";
import { FieldArray, Formik, getIn } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Box } from "@mui/system";
import { getOcuconTemplateAttributesByUUID } from "../../../../apis/v1/Camera/OcuconCameraTemplates";
import DeleteIcon from "@mui/icons-material/Delete";
import { template_attributes_types } from "../../../../utilis/templateAttributesFilter";
import { setFieldValueWithoutSpaces } from "../../../../utilis/shared";
import { Fragment } from "react";

export default function AddOcuconCameraConnectionTemplateForm({
  base_templates,
  handleSubmitForm,
  authTypes,
}) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required(),
    is_http: yup.boolean(),
    url_template: yup
      .string()
      .nullable()
      .when("is_http", (is_http) => {
        if (is_http) {
          return yup.string().required();
        }
      }),
    attributes_fields: yup.array(),
    attributes: yup.array().of(
      yup.object().shape({
        key: yup.string(),
      }),
    ),
    auth_type: yup.mixed(),
  });

  return (
    <Formik
      initialValues={{
        base_template: null,
        name: "",
        is_http: true,
        url_template: "",
        auth_type: null,
        attributes: [],
        attributes_fields: [
          {
            key: "",
            type: "url_replacement_values",
          },
          {
            key: "",
            type: "query_params",
          },
          {
            key: "",
            type: "settings",
          },
        ],
      }}
      validationSchema={schema}
      onSubmit={(values) => handleSubmitForm(values)}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={4}>
              <MUISelect
                dataTestid="base_template"
                label="Base Template"
                labelId="base_template"
                id="base_template"
                name="base_template"
                handleChange={(e) => {
                  handleChange(e);
                  const template = base_templates.find(
                    (template) => template.uuid === e.target.value,
                  );
                  setFieldValue("base_template", e.target.value);
                  setFieldValue("is_http", template.is_http);
                  setFieldValue("url_template", template.url_template);
                  setFieldValue("auth_type", template.auth_type);
                  getOcuconTemplateAttributesByUUID(e.target.value).then(
                    (res) => {
                      if (res) {
                        setFieldValue("attributes", res.data.attributes);
                      }
                    },
                  );
                }}
                value={values.base_template}
                keyItem="uuid"
                keyValue="name"
                list={base_templates}
              />
              <TextField
                type="text"
                id="name"
                data-testid="name"
                label="Name*"
                fullWidth
                margin="normal"
                name="name"
                error={Boolean(errors.name && touched.name)}
                helperText={errors.name && touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                size="small"
              />
              <Box display={"flex"} justifyContent="end">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_http"
                      data-testid="is_http"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_http}
                    />
                  }
                  label="Is http connection?"
                />
              </Box>
              {values.is_http && (
                <>
                  <TextField
                    type="text"
                    id="url_template"
                    data-testid="url_template"
                    label="url template*"
                    fullWidth
                    margin="normal"
                    name="url_template"
                    onChange={(e) =>
                      setFieldValueWithoutSpaces(
                        e,
                        "url_template",
                        setFieldValue,
                      )
                    }
                    onBlur={handleBlur}
                    value={values.url_template}
                    size="small"
                    error={Boolean(errors.url_template && touched.url_template)}
                    helperText={
                      errors &&
                      errors.url_template &&
                      touched.url_template &&
                      errors.url_template
                    }
                  />
                  <MUISelect
                    dataTestid="auth_type"
                    label="Auth Type"
                    labelId="auth_type"
                    id="auth_type"
                    name="auth_type"
                    handleChange={handleChange}
                    list={authTypes}
                    margin="normal"
                    value={values.auth_type}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
              {template_attributes_types(values.is_http).map((item) => (
                <div key={item}>
                  <h4>{item.split("_").join(" ")}</h4>
                  <FieldArray name="attributes_fields">
                    {({ replace }) => (
                      <Box key={item}>
                        {values.attributes_fields.map((attr: any, index) => {
                          const key = `attributes_fields[${index}].key`;
                          const keyErr = getIn(errors, key);

                          if (attr.type === item) {
                            return (
                              <Fragment key={index}>
                                {values.attributes.map(
                                  (attr_val, attr_val_index) => {
                                    if (attr_val.type === item) {
                                      return (
                                        <Box
                                          key={attr_val_index}
                                          display={"flex"}
                                          justifyContent="space-between"
                                        >
                                          <Box>
                                            <Box component={"h6"} m={0}>
                                              Key
                                            </Box>
                                            <Box component={"p"} mt={0}>
                                              {attr_val.key}
                                            </Box>
                                          </Box>

                                          <IconButton
                                            size="small"
                                            data-testid="deleteSettings"
                                            aria-label="delete"
                                            sx={{ mt: "0.3em" }}
                                            onClick={() =>
                                              setFieldValue(
                                                "attributes",
                                                values.attributes.filter(
                                                  (attr, i) =>
                                                    attr_val_index !== i,
                                                ),
                                              )
                                            }
                                          >
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </Box>
                                      );
                                    } else {
                                      return (
                                        <Fragment
                                          key={attr_val_index}
                                        ></Fragment>
                                      );
                                    }
                                  },
                                )}
                                <Box
                                  display={"flex"}
                                  justifyContent="space-between"
                                  alignItems={"start"}
                                  mb={2}
                                >
                                  <TextField
                                    variant="outlined"
                                    sx={{ width: "40%" }}
                                    data-testid="settingsField"
                                    label={`key`}
                                    name={key}
                                    value={attr.key}
                                    onChange={(e) =>
                                      setFieldValueWithoutSpaces(
                                        e,
                                        key,
                                        setFieldValue,
                                      )
                                    }
                                    onBlur={handleBlur}
                                    size="small"
                                    error={Boolean(keyErr)}
                                    helperText={keyErr && keyErr}
                                  />

                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      if (attr.key.length === 0) {
                                        setFieldError(
                                          `attributes_fields[${index}].key`,
                                          "Required",
                                        );
                                      }
                                      if (attr.key) {
                                        setFieldError(
                                          `attributes_fields[${index}].key`,
                                          "",
                                        );
                                        setFieldValue("attributes", [
                                          ...values.attributes,
                                          {
                                            key: attr.key,
                                            type: attr.type,
                                          },
                                        ]);
                                        replace(index, {
                                          key: "",
                                          type: attr.type,
                                        });
                                      }
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Box>
                              </Fragment>
                            );
                          }
                          return <Fragment key={index}></Fragment>;
                        })}
                      </Box>
                    )}
                  </FieldArray>
                </div>
              ))}

              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    sx={{ width: "100%" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    onClick={() =>
                      navigate("/connection/ocucon-camera-connection-templates")
                    }
                    color="error"
                    sx={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
