import { node_types } from "./enum";

let list = [];

export const getChildren = (node) => {
  if (!node) return [];
  if (node.type === node_types.SITE_NODE) return;
  list.push({
    name: node["name"],
    uuid: node["uuid"],
  });
  for (let i = 0; i < node["children"].length; i++) {
    getChildren(node["children"][i]);
  }
  return list;
};

export const resetList = () => {
  list = [];
};

export const getFlattenChildrenNodesList = (node) => {
  if (!node) return [];
  list.push({
    name: node["name"],
    uuid: node["uuid"],
    type: node["type"],
  });
  for (let i = 0; i < node["children"].length; i++) {
    getFlattenChildrenNodesList(node["children"][i]);
  }
  return list;
};
