import { axiosInstance } from "../../config/axiosInstance";
import {
  DetectionsResponse,
  DetectionZonePayload,
  UpsertDetectionZonePayload,
} from "../../../interfaces/Detections";
import { AxiosPromise } from "axios";

const detectin_zone_base_api = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
): string =>
  `/detection-zone/v1/companies/${company_uuid}/cameras/${camera_uuid}/images/${image_uuid}/polygons`;

export const getDetectionZones = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
): AxiosPromise<DetectionsResponse[]> => {
  return axiosInstance.get(
    detectin_zone_base_api(company_uuid, camera_uuid, image_uuid),
  );
};

export const addDetectionZone = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
  data: DetectionZonePayload,
): AxiosPromise<DetectionsResponse> => {
  return axiosInstance.post(
    detectin_zone_base_api(company_uuid, camera_uuid, image_uuid),
    data,
  );
};

export const deleteDetectionZone = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
  polygon_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${detectin_zone_base_api(company_uuid, camera_uuid, image_uuid)}/${polygon_uuid}`,
  );
};

export const upsertDetectionZone = (
  company_uuid: string,
  camera_uuid: string,
  image_uuid: string,
  data: UpsertDetectionZonePayload,
): AxiosPromise<DetectionsResponse> => {
  return axiosInstance.post(
    `${detectin_zone_base_api(company_uuid, camera_uuid, image_uuid)}/upsert`,
    data,
  );
};
