import { Button, Grid, IconButton, TextField } from "@mui/material";
import { FieldArray, Formik, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  addCameraConnectionAttribute,
  deleteCameraConnectionAttribute,
  editCameraConnectionAttribute,
} from "../../../../../apis/v1/Camera/CameraConnection";
import DeleteIcon from "@mui/icons-material/Delete";
import { template_attributes_types } from "../../../../../utilis/templateAttributesFilter";
import { showSnackbar } from "../../../../../utilis/snackbar";
import { setFieldValueWithoutSpaces } from "../../../../../utilis/shared";
import { Fragment } from "react";
import { Attribute, CameraConnection } from "../../../../../interfaces/Camera";

type Props = {
  attributes: Attribute[];
  template: CameraConnection;
  activateConnection: () => void;
};
export default function EditCameraConnectionStep2Form({
  attributes,
  template,
  activateConnection,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Formik
      initialValues={{
        attributes: attributes,
      }}
      enableReinitialize
      onSubmit={(values) => {}}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleBlur,
        setFieldError,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={6}>
              {template_attributes_types(template.is_http).map((item) => (
                <div key={item}>
                  <h4>{item.split("_").join(" ")}</h4>
                  <FieldArray name="attributes">
                    {({ push, remove, replace }) => (
                      <Box>
                        {values.attributes.map((attr: any, index) => {
                          const key = `attributes[${index}].key`;
                          const value = `attributes[${index}].value`;
                          const keyErr = getIn(errors, key);
                          const ValueErr = getIn(errors, value);

                          if (attr.type === item) {
                            return (
                              <Box
                                data-testid="attributes_keys"
                                key={index}
                                display={"flex"}
                                justifyContent="flex-start"
                                alignItems={"flex-start"}
                                gap={2}
                                mb={2}
                              >
                                {attr.id ? (
                                  <Box sx={{ width: "40%" }}>
                                    <Box component={"h6"} my={0}>
                                      Key
                                    </Box>
                                    <Box
                                      sx={{ overflowWrap: "break-word" }}
                                      component={"p"}
                                      mt={0}
                                      mb={1}
                                    >
                                      {attr.key}
                                    </Box>
                                  </Box>
                                ) : (
                                  <TextField
                                    variant="outlined"
                                    sx={{ width: "40%" }}
                                    data-testid="key"
                                    label={`key`}
                                    name={key}
                                    value={attr.key || ""}
                                    onChange={(e) =>
                                      setFieldValueWithoutSpaces(
                                        e,
                                        key,
                                        setFieldValue,
                                      )
                                    }
                                    onBlur={handleBlur}
                                    size="small"
                                    error={keyErr}
                                    helperText={keyErr && keyErr}
                                  />
                                )}
                                <TextField
                                  variant="outlined"
                                  sx={{ width: "40%" }}
                                  data-testid="value"
                                  label={`value`}
                                  name={value}
                                  value={attr.value || ""}
                                  onChange={(e) =>
                                    setFieldValueWithoutSpaces(
                                      e,
                                      value,
                                      setFieldValue,
                                    )
                                  }
                                  onBlur={handleBlur}
                                  size="small"
                                  error={ValueErr}
                                  helperText={ValueErr && ValueErr}
                                />
                                <Button
                                  type="button"
                                  variant="outlined"
                                  color="success"
                                  sx={{ width: "5em" }}
                                  onClick={() => {
                                    const new_attr = {
                                      key: attr.key,
                                      type: attr.type,
                                      value: attr.value,
                                    };

                                    if (attr.id !== "") {
                                      editCameraConnectionAttribute(
                                        params.company_uuid,
                                        params.site_uuid,
                                        params.camera_uuid,
                                        params.connection_uuid,
                                        {
                                          key: attr.key,
                                          type: attr.type,
                                        },
                                        { value: attr.value },
                                      ).then((res) => {
                                        if (res) {
                                          showSnackbar(
                                            "Saved Successfully",
                                            "success",
                                          );
                                        }
                                      });
                                    } else {
                                      if (
                                        attr.key.length > 0 &&
                                        attr.value.length > 0
                                      ) {
                                        addCameraConnectionAttribute(
                                          params.company_uuid,
                                          params.site_uuid,
                                          params.camera_uuid,
                                          params.connection_uuid,
                                          new_attr,
                                        ).then((res) => {
                                          if (res) {
                                            replace(index, {
                                              ...new_attr,
                                              id: index.toString(),
                                            });
                                            showSnackbar(
                                              "Saved Successfully",
                                              "success",
                                            );
                                          }
                                        });
                                      } else {
                                        if (
                                          attr.value.length === 0 &&
                                          attr.key.length === 0
                                        ) {
                                          setFieldError(
                                            `attributes[${index}].value`,
                                            "Required",
                                          );
                                          setFieldError(
                                            `attributes[${index}].key`,
                                            "Required",
                                          );
                                        }

                                        if (
                                          attr.value.length > 0 &&
                                          attr.key.length === 0
                                        ) {
                                          setFieldError(
                                            `attributes[${index}].key`,
                                            "Required",
                                          );
                                        }

                                        if (
                                          attr.value.length === 0 &&
                                          attr.key.length > 0
                                        ) {
                                          setFieldError(
                                            `attributes[${index}].value`,
                                            "Required",
                                          );
                                        }
                                      }
                                    }
                                  }}
                                >
                                  {attr.id !== "" ? "Update" : "Save"}
                                </Button>
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  onClick={() => {
                                    if (attr.id) {
                                      deleteCameraConnectionAttribute(
                                        params.company_uuid,
                                        params.site_uuid,
                                        params.camera_uuid,
                                        params.connection_uuid,
                                        {
                                          key: attr.key,
                                          type: attr.type,
                                        },
                                      ).then((res) => {
                                        if (res) {
                                          remove(index);
                                          showSnackbar(
                                            "Deleted Successfully",
                                            "success",
                                          );
                                        }
                                      });
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            );
                          }
                          return <Fragment key={index}></Fragment>;
                        })}
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          sx={{ width: "49%" }}
                          data-testid="add-attribute"
                          onClick={() =>
                            push({ id: "", key: "", value: "", type: item })
                          }
                        >
                          Add
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                </div>
              ))}

              <Grid container spacing={1} mt={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    sx={{ width: "100%" }}
                    onClick={activateConnection}
                  >
                    Done
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    onClick={() => navigate(-2)}
                    color="error"
                    sx={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
