/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import CompaniesListTable from "../../components/Companies/v1/CompaniesListTable";
import { getCompanies } from "../../apis/v1/Companies";
import { Company } from "../../interfaces/Company";
import CompanyFilters from "../../components/Companies/v1/CompanyFilters";

export default function Companies() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filters, setFilters] = useState({
    account_number: null,
  });

  const fetchCompanies = async () => {
    try {
      const response = await getCompanies(filters);
      setCompanies(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [filters]);

  return (
    <Card variant="outlined">
      <CardHeader title="Companies" subheader="List of companies" />
      <CardContent>
        <Button variant={"outlined"} onClick={() => navigate("/company/add")}>
          Add
        </Button>
      </CardContent>
      <CardContent>
        <CompanyFilters setFilters={setFilters} />
      </CardContent>
      <CardContent>
        <CompaniesListTable companies={companies} />
      </CardContent>
    </Card>
  );
}
