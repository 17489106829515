import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { createFeature } from "../../apis/v1/Services/Features";
import AddFeatureForm from "../../components/Features/AddFeatureForm";

export default function AddFeature() {
  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = (values) => {
    createFeature(params.service_uuid, values).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Add Feature" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddFeatureForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
