import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import MUISelect from "../Controls/Select";
import { integrationTypes } from "../../utilis/enum";
import DeleteIcon from "@mui/icons-material/Delete";
import KeywordsMenu from "./KeywordsMenu";

export default function EditIntegrationForm({
  integration,
  handleSubmitRule,
  keywords,
}) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    type: yup.string().required("Required"),
    targets: yup.array().required().min(1),
    target: yup.string().when("type", (type) => {
      if (type === integrationTypes.SMS || type === integrationTypes.VOICE) {
        return yup
          .string()
          .matches(
            /^[+][0-9]*$/g,
            "Please enter valid numbers starting with +",
          );
      } else if (type === integrationTypes.EMAIL) {
        return yup.string().email();
      }
    }),
  });

  const insertKeyword = (text_to_add) => {
    let textarea: any = document.getElementById("keyword-textarea");
    let start_position = textarea.selectionStart;
    let end_position = textarea.selectionEnd;

    const textVal = `${textarea.value.substring(
      0,
      start_position,
    )}${` {${text_to_add}} `}${textarea.value.substring(
      end_position,
      textarea.value.length,
    )}`;
    textarea.focus();
    return textVal;
  };

  return (
    <Formik
      initialValues={{
        type: integration.type,
        targets: integration.targets,
        target: "",
        message: integration.message,
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitRule(values)}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
        submitCount,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <MUISelect
            value={values.type}
            label={"Type"}
            required
            name="type"
            handleChange={(e) => {
              handleChange(e);
              setFieldValue("targets", []);
              setFieldValue("target", "");
            }}
            handleBlur={handleBlur}
            list={Object.values(integrationTypes)}
            labelId={"label-type"}
            id={"select-type"}
            renderValue={() => values.type}
            styles={{ mb: 2 }}
          />
          {values.type && <Box component={"h4"}>Targets</Box>}
          {values?.targets?.length === 0 && submitCount > 0 && (
            <FormHelperText sx={{ mb: 2 }} error>
              {errors.targets}
            </FormHelperText>
          )}
          {values?.targets?.map((target, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={10}>
                  {target}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={() => {
                      setFieldValue(
                        "targets",
                        values.targets.filter((target, i) => index !== i),
                      );
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          {values.type && (
            <Grid container mt={2} spacing={2}>
              <Grid item xs={9}>
                <TextField
                  type="text"
                  id="target"
                  label={
                    values.type === integrationTypes.SMS ||
                    values.type === integrationTypes.VOICE
                      ? "Phone*"
                      : "Email*"
                  }
                  fullWidth
                  name="target"
                  error={Boolean(errors.target && touched.target)}
                  helperText={errors.target && touched.target && errors.target}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.target}
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ paddingTop: "0.7em", paddingBottom: "0.7em" }}
                  onClick={() => {
                    if (!errors.target && values.target.length > 0) {
                      setFieldValue("targets", [
                        ...values.targets,
                        values.target,
                      ]);
                      setFieldValue("target", "");
                      setFieldError("target", null);
                      setFieldError("targets", null);
                    }
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}

          <Box component={"h4"} mb={0}>
            Messege
          </Box>
          <Box component={"p"}>
            Each integration type has a different message which you can
            customize and add keywords
          </Box>
          <Box display={"flex"} justifyContent={"end"} my={2}>
            <KeywordsMenu
              handleInsert={(keyword) => {
                const textVal = insertKeyword(keyword);
                setFieldValue("message", textVal);
              }}
              keywords={keywords}
            />
          </Box>
          <TextField
            id="keyword-textarea"
            multiline
            rows={5}
            fullWidth
            value={values.message}
            onChange={(e) => {
              handleChange(e.target.value);
              setFieldValue("message", e.target.value);
            }}
          />

          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
