import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { deleteSite } from "../../../apis/v1/Sites";
import { showSnackbar } from "../../../utilis/snackbar";
import ConfirmationPopup from "../../ConfirmationPopup";
import { SiteResponse } from "../../../interfaces/Site";

interface DeleteSiteProps {
  site_uuid: string,
  company_uuid: string,
  sites: SiteResponse[],
  setSites: React.Dispatch<SiteResponse[]>
}

export default function DeleteSite({
  site_uuid,
  company_uuid,
  sites,
  setSites,
}: DeleteSiteProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getSitesListWithoutDeleted = () => {
    return sites.filter((site) => site.uuid !== site_uuid);
  };

  const submitDeleteSite = () => {
    deleteSite(site_uuid, company_uuid).then((res) => {
      if (res) {
        const sitesListWithoutDeleted = getSitesListWithoutDeleted();
        setSites(sitesListWithoutDeleted);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Site"
        dialogBody="Are you sure you want to delete this site?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
