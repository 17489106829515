import {
  SubscriptionPayload,
  Subscription,
  SubscriptionHistory,
} from "../../../interfaces/Subscription";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const subscriptionsBaseURL = `payment/v1/subscriptions`;

export const generateSubscriptionURL = (
  company_uuid: string,
  service_uuid: string,
) => {
  return `payment/v1/companies/${company_uuid}/services/${service_uuid}/subscriptions`;
};

export const getSubscriptions = ({
  company_uuid,
  service_uuid,
  active,
}: {
  company_uuid?: string;
  service_uuid?: string;
  active?: boolean;
} = {}): AxiosPromise<Subscription[]> => {
  const params: {
    service_uuid?: string;
    company_uuid?: string;
    active?: boolean;
  } = {
    ...(company_uuid && { company_uuid }),
    ...(service_uuid && { service_uuid }),
    ...(active !== null && { active }),
  };
  return axiosInstance.get(subscriptionsBaseURL, {
    params,
  });
};

export const createSubscription = (
  data: SubscriptionPayload,
  company_uuid: string,
): AxiosPromise<Subscription[]> => {
  const payload = { ...data, company_uuid };
  return axiosInstance.post(
    `${generateSubscriptionURL(company_uuid, data.service_uuid)}/manual`,
    payload,
  );
};

export const cancelSubscription = (
  service_uuid: string,
  subscription_uuid: string,
  company_uuid: string,
): AxiosPromise<Subscription> => {
  return axiosInstance.post(
    `${generateSubscriptionURL(company_uuid, service_uuid)}/${subscription_uuid}/deactivate`,
    {},
  );
};

export const getSubscriptionDetails = (
  service_uuid: string,
  subscription_uuid: string,
  company_uuid: string,
): AxiosPromise<Subscription> => {
  return axiosInstance.get(
    `${generateSubscriptionURL(company_uuid, service_uuid)}/${subscription_uuid}`,
  );
};

export const getSubscriptionHistories = (
  service_uuid: string,
  subscription_uuid: string,
  company_uuid: string,
): AxiosPromise<SubscriptionHistory[]> => {
  return axiosInstance.get(
    `${generateSubscriptionURL(company_uuid, service_uuid)}/${subscription_uuid}/histories`,
  );
};

export const getSubscriptionsHistories = ({
  company_uuid,
  service_uuid,
  type,
}: {
  company_uuid?: string;
  service_uuid?: string;
  type?: string;
} = {}): AxiosPromise<SubscriptionHistory[]> => {
  const params: {
    service_uuid?: string;
    company_uuid?: string;
    type?: string;
  } = {
    ...(company_uuid && { company_uuid }),
    ...(service_uuid && { service_uuid }),
    ...(type && { type }),
  };

  return axiosInstance.get(`${subscriptionsBaseURL}/histories`, {
    params,
  });
};

export const getSubscriptionHistoryActions = () => {
  return axiosInstance.get(
    `${subscriptionsBaseURL}/subscription-history-actions`,
  );
};
