import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AddNodeMenu({ parentNode, state }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const params = useParams();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/parent-node/${parentNode.uuid}/add-child-node`,
              {
                state,
              },
            )
          }
        >
          Child Node
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(
              `/company/${params.company_uuid}/node/${params.root_node_uuid}/site/add?selected_node=${parentNode.uuid}`,
              {
                state,
              },
            );
          }}
        >
          Site Node
        </MenuItem>
      </Menu>
    </>
  );
}
