/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useWindowSize } from "./Hooks/useWindowResize";

export default function DetectionsImage({
  svg,
  children,
  setSvgWidth,
  setSvgHeight,
  handleMouseDownOnSVG,
  handleMouseMoveOnSVG,
  handleMouseUpOnSVG,
  image,
  isDrawingActive,
}: any) {
  const [width] = useWindowSize();
  const [imageDimensions, setImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      setSvgWidth(svg.current.clientWidth);
      setSvgHeight(svg.current.clientHeight);
    }, 10);
    let newImg = new Image();
    newImg.src = image;
    newImg.onload = function () {
      let height = newImg.height;
      let width = newImg.width;
      setImageDimensions({
        height,
        width,
      });
    };
  }, [image, svg, width]);

  return (
    <Box id="wrapper">
      <div
        className="img-overlay-wrap"
        style={{
          display:
            imageDimensions.height >= imageDimensions.width * 1.5
              ? "inline-block"
              : "block",
        }}
      >
        <img src={image} alt="svg_img" />
        <svg
          ref={svg}
          style={{
            cursor: isDrawingActive ? "crosshair" : "auto",
            position: "absolute",
            zIndex: 1000,
            objectFit: "contain",
            maxHeight: "75vh",
            width: "100%",
            height: "100%",
          }}
          onTouchStart={handleMouseDownOnSVG}
          onMouseDown={handleMouseDownOnSVG}
          onMouseMove={handleMouseMoveOnSVG}
          onMouseUp={handleMouseUpOnSVG}
        >
          {children}
        </svg>
      </div>
    </Box>
  );
}
