export const downloadFile = (data: any, name) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
};

export const getFileName = (content: string) => {
  const regex = /filename=(.+)/;
  const match = regex.exec(content);
  return match ? match[1].trim() : null;
};