export const AWAITING_SYNC_MSG = "Site not found, please perform a sync with the detection zone service";
export const IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE =
  "Activated means the template is available to use for making camera connections";
export const IS_HIDDEN_TOOLTIP_TITLE =
  "Hidden flag will be used to control visibility, if true will be hidden and if false will be visible";
export const ADD_USER_COMMENT =
  "The user will be sent email and asked to setup a password and confirm account details. If the email expires, ask the user to select ‘forgot password’ which will generate a new email.";
export const ADD_USER_TO_SITES_NOTE =
"NOTE: A user is not assigned to the organisation by default, meaning they will not see a tree menu. Ensure you link the user.";
export const SLEEP_PERIOD_NOTE =
  "Sleep period is the time to wait after a rule has fired before it can fire again";
export const UPLOAD_SERVICE_IMAGE_NOTE = 'Note: Please upload a landscape image with the following dimensions 445 W × 270 H';
export const SERVICE_COLOR_TOOLTIP = 'This color will be reflected in button and circle color over the background image'
export const ACTIVATED_CONNECTION_TOOLTIP= "Activated means the connection is in use, deactivated means not in use, and only deactivated connections are editable.";
export const PARENT_NODE_NOTE =
  "Selecting a parent node determines the site's placement in the spill dashboards menu. By selecting 'organization root' the site will be added directly under the company";
export const DEACTIVATE_CONNECTION_NOTE = 'Deactivate connection to enable edit.'
export const getURLParams = (queryString, paramName: any): any => {
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

export const validate = (value) => {
  if (!value || value.length === 0) {
    return "Required";
  }
  return "";
};

export const removeWhiteSpace = (str) => {
  return str.replace(/\s/g, "");
};

export const setFieldValueWithoutSpaces = (e, fieldName, setFieldValue) => {
  setFieldValue(fieldName, removeWhiteSpace(e.target.value));
};

export const getQueryParams = (queryString) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(queryString);
};

export const CURRENCIES = ["USD", "GBP", "AUD"];

export const UNLIMITED_LICENSES = 9999

export const stringifyAmountNumber = (value) => parseInt(( value * 100).toString()).toString();