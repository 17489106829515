import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import MultiSelect from "../../../Controls/MultiSelect";
import { Service } from "../../../../interfaces/Service";
import { CreateCameraPayload } from "../../../../interfaces/Camera";

type Props = {
  handleSubmit: (values: CreateCameraPayload) => void;
  services: Service[];
};
export default function AddCameraForm({
  handleSubmit,
  services,
}: Readonly<Props>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  interface FormSchema {
    name: string;
    services_uuid: string;
  }

  const schema: SchemaOf<FormSchema> = yup.object().shape({
    name: yup.string().required("Required"),
    services_uuid: yup.mixed().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        services_uuid: [],
      }}
      validationSchema={schema}
      onSubmit={(values: CreateCameraPayload) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="camera_name"
            size="small"
          />

          <Field name="services_uuid">
            {({ form, field, meta }: any) => (
              <MultiSelect
                label="Services"
                dataTestid={"services"}
                meta={meta}
                form={form}
                field={field}
                list={services}
                uuid="uuid"
                value="name"
                sx={{ mt: 3 }}
              />
            )}
          </Field>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
