/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardContent, CardHeader } from "@mui/material";
import DetailsCard from "../../components/Subscriptions/DetailsCard";

import { getSubscriptionDetails } from "../../apis/v1/Subscriptions/Subscriptions";
import MUIBreadcrumb from "../../components/Breadcrumb";
import CancelSubscription from "../../components/Subscriptions/CancelSubscription";
import { getLicenses } from "../../apis/v1/Licenses";
import SubscriptionHistoriesList from "../../components/Subscriptions/SubscriptionHistoryList";
import CreditsListCard from "../../components/Credits/CreditsListCard";

export default function SubscriptionDetails() {
  const params = useParams();
  const location = useLocation();
  const [subscription, setSubscription] = useState(null);
  const [licenses, setLicenses] = useState([]);

  const getSubscriptionDetailsRequest = () => {
    getSubscriptionDetails(params.service_uuid, params.subscription_uuid , params.company_uuid).then(
      (res) => {
        if (res) {
          setSubscription(res.data);
        }
      },
    );
  };

  const getLicensesRequest = () => {
    getLicenses(params.company_uuid, params.service_uuid).then((res) => {
      setLicenses(res.data);
    });
  };

  useEffect(() => {
    getSubscriptionDetailsRequest();
    getLicensesRequest();
  }, [params.subscription_uuid]);

  const renderBreadcrumbData = () => {
    const is_from_company_location = location.state?.company_name;
    const stackList = is_from_company_location
      ? [
          {
            name: "Companies",
            path: "/companies",
          },

          {
            name: location?.state?.company_name,
            path: location?.state?.prevCompanyLocation,
          },
        ]
      : [
          {
            name: "Subscriptions",
            path: "/subscriptions",
          },
        ];
    return stackList;
  };


  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: renderBreadcrumbData(),
          current: subscription?.service?.name,
        }}
      />
      <Card variant="outlined">
        <CardHeader
          title="Subscription Details"
          subheader="Details about this subscription"
        />

        <CardContent>
          {subscription && (
            <DetailsCard subscription={subscription} licenses={licenses} />
          )}
        </CardContent>
        <CancelSubscription
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </Card>
      <SubscriptionHistoriesList />
      <CreditsListCard is_subscription_active={subscription?.active}/>
    </>
  );
}
