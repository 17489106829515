/* eslint-disable react-hooks/exhaustive-deps */
import { TableCell, TableRow } from "@mui/material";
import MUITable from "../Table";
import { RuleHistory } from "../../interfaces/Notifications";

export default function RuleHistoryTable({
  rulesHistory,
}: {
  rulesHistory: RuleHistory[];
}) {
  const triggerTimeFormat = (date) => {
    const formatedDate = new Date(date).toLocaleString();
    return formatedDate;
  };

  const rule_type = rulesHistory && rulesHistory[0]?.type;
  const conditional_headers_based_on_type =
    rule_type === "rule_incident_created_count_history"
      ? [
        {
          id: "target_incident_count",
          name: "Target Incident Count",
        },
        {
          id: "actual_incident_count",
          name: "Actual Incident Count",
        },
        {
          id: "evaluation_period",
          name: "Evaluation Period (seconds)",
        },
      ]
      : [
        {
          id: "target_open_duration",
          name: "Target Open Duration",
        },
        {
          id: "actual_open_duration",
          name: "Actual Open Duration",
        },
      ];
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "camera_uuid",
          name: "Camera UUID",
        },
        ...conditional_headers_based_on_type,
        {
          id: "sleep_period",
          name: "Sleep Period (seconds)",
        },
        {
          id: "condition",
          name: "Condition",
        },
        {
          id: "incident_uuids",
          name: "Incident UUIDs",
        },
        {
          id: "fired",
          name: "Fired",
        },
        {
          id: "trigger_time",
          name: "Trigger Time",
        },
      ]}
      hasPagination={true}
      data={rulesHistory}
      tableBodyRow={(row: any) => {
        return (
          <TableRow key={row?.uuid}>
            <TableCell>{row?.camera_uuid}</TableCell>
            {rule_type === "rule_incident_created_count_history" && (
              <>
                <TableCell>{row?.target_incident_count}</TableCell>
                <TableCell>{row?.actual_incident_count}</TableCell>
                <TableCell>{row?.evaluation_period}</TableCell>
              </>
            )}
            {rule_type === "rule_incident_open_duration_history" && (
              <>
                <TableCell>{row?.target_open_duration}</TableCell>
                <TableCell>{row?.actual_open_duration}</TableCell>
              </>
            )}
            <TableCell>{row?.sleep_period} </TableCell>
            <TableCell>{row?.condition}</TableCell>
            <TableCell>{row?.incident_uuids.join(" , ")}</TableCell>
            <TableCell>{row?.fired ? "Yes" : "No"}</TableCell>
            <TableCell>
              {row?.trigger_time ? triggerTimeFormat(row?.trigger_time) : ""}
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
