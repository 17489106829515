/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getServices } from "../../apis/v1/Companies";
import { Service } from "../../interfaces/Service";
import { editCamera, getCameraDetails } from "../../apis/v1/Camera/Camera";
import EditCameraForm from "../../components/Cameras/v1/Camera/EditCameraForm";
import { showSnackbar } from "../../utilis/snackbar";

export default function EditCamera() {
  const navigate = useNavigate();
  const params = useParams();
  const [camera, setCamera] = useState(null);
  const [services, setServices] = useState<Array<Service>>([]);

  useEffect(() => {
    getCameraDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
    ).then((res) => {
      if (res) {
        setCamera({
          ...res.data,
          services_id: res.data.services.map((service) => service.uuid),
        });
      }
    });
    getServices().then((res) => {
      res && setServices(res.data);
    });
  }, []);

  const handleSubmit = (values: any) => {
    editCamera(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      values,
    ).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit Camera" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {camera && (
            <EditCameraForm
              camera={camera}
              services={services}
              handleSubmit={handleSubmit}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
