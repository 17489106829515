/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getPermissions } from "../../../../apis/v1/Permissions";
import { useEffect, useState } from "react";

export default function UserPermissions() {
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const fetchPermissions = async () => {
    try {
      const response = await getPermissions(params.user_id);
      setPermissions(response.data);
    } catch (err) {
      console.log("error fetching permissions", err);
    }
  };

  useEffect(() => {
    if (params.user_id) {
      fetchPermissions();
    }
  }, [params.user_id]);

  return (
    <>
      <Box component={"h4"} mb={0}>
        Assigned Permissions
      </Box>
      {permissions?.length > 0 ? (
        <Box component={"ul"} pl={3} my={0}>
          {permissions?.map((permission) => (
            <Box
              component={"li"}
              mb={0}
              data-testid="permission-item"
              key={permission.uuid}
            >
              {permission.name}
            </Box>
          ))}
        </Box>
      ) : (
        <Box data-testid="no-permissions">-</Box>
      )}
      <Button
        variant={"outlined"}
        data-testid="edit-permissions-btn"
        sx={{ my: 1 }}
        onClick={() =>
          navigate(
            `/company/${params.company_uuid}/node/${params.node_uuid}/user/${params.user_id}/update-permissions`,
          )
        }
      >
        Edit
      </Button>
    </>
  );
}
