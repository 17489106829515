import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate } from "react-router-dom";
import { createPolicy } from "../../apis/v1/Policies";
import AddPolicyForm from "../../components/Policies/AddPolicyForm";

export default function AddPolicy() {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("policy_type", values.policy_type);
    formData.append("file", values.file);

    try {
      const response = await createPolicy(formData);
      if (response) {
        showSnackbar("Done successfully", "success");
        navigate("/services");
      }
    } catch (e) {
      console.log("Error during adding policy",e);
    }
  };

  return (
    <>
      <PagesHeader title="Add Policy" />
      <Grid container>
        <Grid item sm={12} md={6} lg={4}>
          <AddPolicyForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
