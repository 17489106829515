import { IconButton, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MUITable from "../Table";
import EditIcon from "@mui/icons-material/Edit";
import { Dispatch } from "react";
import { Reason } from "../../interfaces/Credits";
import DeleteReason from "./DeleteReason";

interface ReasonsListTableProps {
  reasons: Reason[];
  setReasons: Dispatch<Reason[]>;
}
export default function ReasonsListTable({
  reasons,
  setReasons,
}: Readonly<ReasonsListTableProps>) {
  const navigate = useNavigate();

  const renderTableBody = (row: any) => {
    return (
      <TableRow key={row.uuid}>
        <TableCell>{row.reason}</TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            data-testid="edit-btn"
            onClick={() => navigate(`/reasons/${row.uuid}/edit`)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <DeleteReason
            reasonUUID={row.uuid}
            reasons={reasons}
            setReasons={setReasons}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "reason",
          name: "Reason",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={reasons}
      tableBodyRow={(row: Reason) => renderTableBody(row)}
    />
  );
}
