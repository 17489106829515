export const formatDate = (date: string | Date) => {
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  const d = new Date(date);
  const formattedWithTimeZone = new Intl.DateTimeFormat(
    "en-GB",
    options,
  ).format(d);

  const timeZoneAbbreviation = formattedWithTimeZone.split(" ").pop();

  const formattedDate = formattedWithTimeZone.replace(
    ` ${timeZoneAbbreviation}`,
    ` (${timeZoneAbbreviation})`,
  );
  return formattedDate;
};
