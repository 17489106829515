import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import { Reason } from "../../interfaces/Credits";
import { createReason } from "../../apis/v1/Credits/Reasons";
import AddReasonForm from "../../components/Reasons/AddReasonForm";

export default function AddReason() {
  const navigate = useNavigate();

  const handleSubmitForm = async (values: Pick<Reason, "reason">) => {
    try {
      const response = await createReason(values);
      if (response) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    } catch (error) {
      console.log("Error submit reason", error);
    }
  };
  return (
    <>
      <PagesHeader title="Add Credit Reason" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddReasonForm handleSubmitForm={handleSubmitForm} />
        </Grid>
      </Grid>
    </>
  );
}
