import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteGlobalIncidentClass } from "../../apis/v1/DetectionZone/GlobalIncidentClasses";

export default function DeleteGlobalIncidentClass({ incident_class_uuid, incidentClasses, setIncidentClasses }) {
  const [openDialog, setOpenDialog] = useState(false);

  const getIncidentClassesWithoutDeleted = () => {
    return incidentClasses.filter((incidentClass) => incidentClass.uuid !== incident_class_uuid);
  };

  const submitDeleteLabel = () => {
    deleteGlobalIncidentClass(incident_class_uuid).then((res) => {
      if (res) {
        const incidentClassesWithoutDeleted = getIncidentClassesWithoutDeleted();
        setIncidentClasses(incidentClassesWithoutDeleted);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Global Incident Class"
        dialogBody="Are you sure you want to delete this incident class?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteLabel()}
      />
    </>
  );
}
