import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { MouseEventHandler } from "react";

export interface TableHeaderCells {
  id: string;
  name: string;
  tooltip_title?: string;
}

type Order = "asc" | "desc";
interface MUITableHeadProps {
  tableHeaderCells: TableHeaderCells[];
  orderBy: string;
  order: Order;
  createSortHandler: (headCell) => MouseEventHandler<HTMLAnchorElement>;
}

export default function MUITableHead({
  tableHeaderCells,
  orderBy,
  order,
  createSortHandler,
}: MUITableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {tableHeaderCells.map((item: TableHeaderCells, index: number) => {
          return (
            <Tooltip
              key={index}
              title={item.tooltip_title}
              placement="top-start"
            >
              <TableCell key={index}>
                <TableSortLabel
                  active={orderBy === item.id}
                  direction={orderBy === item.id ? order : "asc"}
                  onClick={createSortHandler(item.id)}
                  sx={{ fontWeight: "bold" }}
                >
                  {item.name}
                </TableSortLabel>
              </TableCell>
            </Tooltip>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
