import { Button, Grid, IconButton, TextField } from "@mui/material";
import { FieldArray, Formik, getIn } from "formik";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  createTemplateAttribute,
  deleteAttribute,
} from "../../../../../apis/v1/Camera/OcuconCameraTemplates";
import DeleteIcon from "@mui/icons-material/Delete";
import { template_attributes_types } from "../../../../../utilis/templateAttributesFilter";
import { showSnackbar } from "../../../../../utilis/snackbar";
import { setFieldValueWithoutSpaces } from "../../../../../utilis/shared";
import { Fragment } from "react";
import { Attribute } from "../../../../../interfaces/Camera";

export default function EditOcuconTemplateStep2({
  attributes,
  template,
  activateTemplate,
}) {
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        attributes: attributes,
      }}
      enableReinitialize
      onSubmit={(values) => {}}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleBlur,
        setFieldError,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={4}>
              {template_attributes_types(template.is_http).map((item) => (
                <Box key={item}>
                  <Box component={"h4"}>{item.split("_").join(" ")}</Box>
                  <FieldArray name="attributes">
                    {({ push, remove, replace }) => (
                      <Box>
                        {values.attributes.map(
                          (
                            attr: Omit<Attribute, "value"> & { id: string },
                            index: number,
                          ) => {
                            const key = `attributes[${index}].key`;
                            const keyErr = getIn(errors, key);
                            if (attr.type === item) {
                              return (
                                <Box
                                  key={index}
                                  data-testid="attributes_keys"
                                  display={"flex"}
                                  justifyContent="space-between"
                                  gap={2}
                                  alignItems={"start"}
                                  mb={0}
                                >
                                  {attr.id ? (
                                    <Box>
                                      <Box component={"h6"} my={0}>
                                        Key
                                      </Box>
                                      <Box component={"p"} mt={0} mb={1}>
                                        {attr.key}
                                      </Box>
                                    </Box>
                                  ) : (
                                    <TextField
                                      variant="outlined"
                                      sx={{ width: "49%", mb: 1 }}
                                      data-testid="settingsField"
                                      label={`key`}
                                      name={key}
                                      value={attr.key}
                                      onChange={(e) =>
                                        setFieldValueWithoutSpaces(
                                          e,
                                          key,
                                          setFieldValue,
                                        )
                                      }
                                      onBlur={handleBlur}
                                      size="small"
                                      error={keyErr}
                                      helperText={keyErr && keyErr}
                                    />
                                  )}
                                  {attr.id === "" && (
                                    <Button
                                      type="button"
                                      variant="outlined"
                                      color="success"
                                      onClick={() => {
                                        if (attr?.key?.length > 0) {
                                          createTemplateAttribute(
                                            template.uuid,
                                            {
                                              key: attr.key,
                                              type: attr.type,
                                            } as Attribute,
                                          ).then((res) => {
                                            if (res) {
                                              showSnackbar(
                                                "Saved Successfully",
                                                "success",
                                              );
                                              replace(index, {
                                                ...attr,
                                                id: index.toString(),
                                              });
                                            }
                                          });
                                        } else {
                                          setFieldError(
                                            `attributes[${index}].key`,
                                            "Required",
                                          );
                                        }
                                      }}
                                    >
                                      Save
                                    </Button>
                                  )}
                                  <IconButton
                                    size="small"
                                    aria-label="delete"
                                    sx={{ mt: "0.3em" }}
                                    onClick={() => {
                                      if (attr.id !== "") {
                                        deleteAttribute(template.uuid, {
                                          key: attr.key,
                                          type: attr.type,
                                        })
                                          .then((res) => {
                                            if (res) {
                                              remove(index);
                                              showSnackbar(
                                                "Deleted Successfully",
                                                "success",
                                              );
                                            }
                                          })
                                          .catch((err) => {
                                            err.response.status === "404" &&
                                              remove(index);
                                          });
                                      } else {
                                        remove(index);
                                      }
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              );
                            }
                            return <Fragment key={index}></Fragment>;
                          },
                        )}
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          data-testid={`add-attribute`}
                          sx={{ width: "49%", mt: 2 }}
                          onClick={() => push({ id: "", key: "", type: item })}
                        >
                          Add
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                </Box>
              ))}

              <Grid container spacing={1} mt={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    sx={{ width: "100%" }}
                    onClick={activateTemplate}
                    data-testid="doneBtn"
                  >
                    Done
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={"contained"}
                    onClick={() =>
                      navigate("/connection/ocucon-camera-connection-templates")
                    }
                    color="error"
                    sx={{ width: "100%" }}
                    data-testid="cancelBtn"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
