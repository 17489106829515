import { Box, Grid } from "@mui/material";
import { Service } from "../../interfaces/Service";

export default function ServiceDetailsCard({ service }: { service: Service }) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12} sm={12} md={12}>
        <Box component={"p"} my={0}>
          Name: {service.name}
        </Box>
        <Box component={"p"} mt={0}>
          Description: {service.description}
        </Box>
      </Grid>
    </Grid>
  );
}
