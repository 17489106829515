import { useNavigate } from "react-router-dom";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import DeleteGlobalIncidentClass from "./DeleteGlobalIncidentClass";

export default function GlobalIncidentClassesTable({
  incidentClasses,
  setIncidentClasses,
}) {
  const navigate = useNavigate();
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "colour",
          name: "Colour",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={incidentClasses}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Box display={"flex"} gap={2}>
              <Box
                sx={{
                  backgroundColor: row.colour,
                  height: "20px",
                  width: "20px",
                }}
              />
              <Box> {row.colour}</Box>
            </Box>
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/global-incident-classes/${row.uuid}/edit`)
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteGlobalIncidentClass
              incident_class_uuid={row.uuid}
              incidentClasses={incidentClasses}
              setIncidentClasses={setIncidentClasses}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
