/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import AddRuleForm from "../../components/NotificationsRules/AddRuleForm";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { addRule, getRuleTypes } from "../../apis/v1/Notifications/Rules";
import {
  getFlattenChildrenNodesList,
  resetList,
} from "../../utilis/format-child-nodes";
import { showSnackbar } from "../../utilis/snackbar";
import { period_interval, rule_types } from "../../utilis/enum";
import MUIBreadcrumb from "../../components/Breadcrumb";

export default function AddRule() {
  const navigate = useNavigate();
  const params = useParams();
  const [nodes, setNodes] = useState([]);
  const [ruleTypes, setRuleTypes] = useState([]);
  const location = useLocation();

  useEffect(() => {
    resetList();
    getCompanyRootNodes(params.company_uuid, params.root_node_uuid).then(
      (res) => {
        const nodes = getFlattenChildrenNodesList(res.data);
        setNodes(nodes);
      },
    );

    getRuleTypes().then((res) => {
      setRuleTypes(res.data);
    });

    return () => {
      resetList();
    };
  }, []);

  const calculatePeriodSec = (value, type) => {
    const interval = period_interval[type - 1].value;
    return value * interval;
  };

  const handleSubmitRule = (values) => {
    const data = {
      type: values.type,
      rule_data: {
        node_uuid: values.node_uuid,
        sleep_period_sec: calculatePeriodSec(
          values.sleep_period_sec,
          values.sleep_period_sec_type,
        ),
        condition: values.condition,
        target_incident_count:
          values.type === rule_types.RULE_INCIDENT_CREATED_COUNT
            ? values.target_incident_count
            : null,
        evaluation_period_sec:
          values.type === rule_types.RULE_INCIDENT_CREATED_COUNT
            ? calculatePeriodSec(
              values.evaluation_period_sec,
              values.evaluation_period_sec_type,
            )
            : null,
        target_open_duration_sec:
          values.type === rule_types.RULE_INCIDENT_OPEN_DURATION
            ? calculatePeriodSec(
              values.target_open_duration_sec,
              values.target_open_duration_sec_type,
            )
            : null,
      },
    }
    addRule(params.company_uuid, data).then((res) => {
      if (res) {
        showSnackbar("Rule added successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
            },
          ],
          current: "Add Notification Rule",
        }}
      />
      <PagesHeader title="Add Notification Rule" />
      <p>Note: Some text goes here</p>
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddRuleForm
            ruleTypes={ruleTypes}
            nodesList={nodes}
            handleSubmitRule={handleSubmitRule}
          />
        </Grid>
      </Grid>
    </>
  );
}
