import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppSelector } from "../../../hooks/useStoreHook";
import { AdminPayload } from "../../../interfaces/User";

interface AddAdminFormProps {
  handleSubmit: (values: AdminPayload) => void;
}

export default function AddAdminForm({ handleSubmit }: AddAdminFormProps) {
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const schema = yup.object().shape({
    username: yup.string().required("Required"),
    email: yup.string().required("Required").email("Email format is required"),
  });
  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="username"
            label="username"
            fullWidth
            margin="normal"
            name="username"
            error={Boolean(errors.username && touched.username)}
            helperText={errors.username && touched.username && errors.username}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
          />
          <TextField
            type="email"
            id="email"
            label="email"
            fullWidth
            margin="normal"
            name="email"
            error={Boolean(errors.email && touched.email)}
            helperText={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            size="small"
          />

          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate("/admins")}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
