import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getServices } from "../../apis/v1/Companies";
import { Service } from "../../interfaces/Service";
import AddCameraForm from "../../components/Cameras/v1/Camera/AddCameraForm";
import { createCamera } from "../../apis/v1/Camera/Camera";
import { showSnackbar } from "../../utilis/snackbar";
import { CreateCameraPayload } from "../../interfaces/Camera";

export default function AddCamera() {
  const navigate = useNavigate();
  const params = useParams();
  const [services, setServices] = useState<Array<Service>>([]);

  useEffect(() => {
    getServices().then((res) => {
      res && setServices(res.data);
    });
  }, []);

  const handleSubmit = (values: CreateCameraPayload) => {
    createCamera(params.company_uuid, params.site_uuid, values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Camera" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddCameraForm services={services} handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
