/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import {
  editTier,
  getTierDetails,
} from "../../apis/v1/Services/Tiers";
import EditTierForm from "../../components/Tiers/EditTierForm";
import { getConsumables } from "../../apis/v1/Services/Consumables";
import { getFeatures } from "../../apis/v1/Services/Features";
import { TierResponse } from "../../interfaces/Tier";
import { Consumable } from "../../interfaces/Consumable";
import { Feature } from "../../interfaces/Features";
import { Company } from "../../interfaces/Company";
import { getCompanies } from "../../apis/v1/Companies";
import { Country } from "../../interfaces/Address";
import { getCountries } from "../../apis/v1/Countries";

export default function EditTier() {
  const navigate = useNavigate();
  const params = useParams();
  const [tier, setTier] = useState<TierResponse>();
  const [consumables, setConsumables] = useState<Consumable[]>();
  const [features, setFeatures] = useState<Feature[]>();
  const [companies, setCompanies] = useState<Company[]>();
  const [countries, setCountries] = useState<Country[]>();

  useEffect(() => {
    getCountriesRequest();
    getFeaturesRequest();
    getConsumablesRequest();
    getTierDetailsRequest();
    getCompaniesList()
  }, [params.service_uuid]);

  const getCompaniesList = () => {
    getCompanies().then((res) => {
      setCompanies(res.data);
    });
  };

  const getTierDetailsRequest = () => {
    getTierDetails(params.service_uuid, params.tier_uuid).then((res) => {
      setTier(res.data);
    });
  };

  const getFeaturesRequest = () => {
    getFeatures(params.service_uuid).then((res: any) => {
      setFeatures(res.data);
    });
  };

  const getConsumablesRequest = () => {
    getConsumables(params.service_uuid).then((res: any) => {
      setConsumables(res.data);
    });
  };

  const getCountriesRequest = () => {
    getCountries().then((res) => {
      setCountries(res.data);
    });
  };

  const handleSubmit = (values) => {
    const data = {
      ...values,
      consumables: values.consumables.filter(obj => obj.uuid !== "")
    }
    editTier(params.service_uuid, params.tier_uuid, data).then(() => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Edit Tier" />
      <Grid container mt={3}>
        <Grid item sm={12} md={6} lg={4}>
          {tier && (
            <EditTierForm
              tier={tier}
              handleSubmit={handleSubmit}
              consumables={consumables}
              features={features}
              companies={companies}
              countries={countries}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
