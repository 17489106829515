/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTemplateDetails } from "../../../apis/v1/Camera/CompanyCameraTemplates";
import EditCompanyTemplateStep1 from "../../../components/Cameras/v1/CompanyTemplate/EditTemplateForm/Step1";
import PagesHeader from "../../../components/PagesHeader";
import { getAuthTypes } from "../../../apis/v1/Camera/Camera";

export default function EditCompanyCameraTemplate() {
  const [template, setTemplate] = useState<any>({});
  const [authTypes, setAuthTypes] = useState([]);
  const params = useParams();
  useEffect(() => {
    getAuthTypes().then((res) => res && setAuthTypes(res.data));
    getTemplateDetails(params.company_uuid, params.uuid).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
  }, []);
  return (
    <>
      <PagesHeader title="Edit Company Template" />

      <EditCompanyTemplateStep1 authTypes={authTypes} template={template} />
    </>
  );
}
