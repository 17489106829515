import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import { Consumable } from "../../interfaces/Consumable";

interface EditConsumableFormProps {
  handleSubmit: (values: Omit<Consumable, "uuid">) => void;
  consumable: Consumable;
}

export default function EditConsumableForm({
  handleSubmit,
  consumable,
}: EditConsumableFormProps) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  const schema: yup.SchemaOf<Omit<Consumable, "uuid">> = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().nullable(),
  });

  return (
    <Formik
      initialValues={{
        name: consumable.name || "",
        description: consumable.description || "",
      }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values: Omit<Consumable, "uuid">) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="service_name"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description"
            fullWidth
            margin="normal"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            data-testid="service_description"
            size="small"
            multiline
            rows={5}
          />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                data-testid={"submit-btn"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
