/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import {
  editConsumableAddon,
  getConsumableAddonsDetails,
} from "../../apis/v1/Services/ConsumablesAddons";
import EditConsumableAddonsForm from "../../components/ConsumablesAddons/EditConsumableAddonsForm";
import { getTiers } from "../../apis/v1/Services/Tiers";
import { Country } from "../../interfaces/Address";
import { getCountries } from "../../apis/v1/Countries";

export default function EditConsumableAddons() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumableAddon, setConsumableAddon] = useState<any>();
  const [ tiers , setTiers ] = useState<any>();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    getCountries().then((res) => {
      setCountries(res.data);
    });
    getTiers(params.service_uuid).then((res)=> {
      setTiers(res.data)
    })
    getConsumableAddonsDetails(
      params.service_uuid,
      params.consumable_uuid,
      params.consumable_addon_uuid,
    ).then((res) => {
      setConsumableAddon(res.data);
    });
  }, []);

  const handleSubmit = (values) => {
    editConsumableAddon(
      params.service_uuid,
      params.consumable_uuid,
      params.consumable_addon_uuid,
      values,
    ).then(() => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Edit Consumable" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {consumableAddon && (
            <EditConsumableAddonsForm
              consumableAddon={consumableAddon}
              tiers={tiers}
              handleSubmit={handleSubmit}
              countries={countries}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
