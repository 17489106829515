import React, { useState } from "react";
import { Subscription } from "../../interfaces/Subscription";
import { showSnackbar } from "../../utilis/snackbar";
import { cancelSubscription } from "../../apis/v1/Subscriptions/Subscriptions";
import { Box, Button, Grid } from "@mui/material";
import ConfirmationPopup from "../ConfirmationPopup";
import { useParams } from "react-router-dom";

interface CancelSubscriptionProps {
  subscription: Subscription;
  setSubscription: React.Dispatch<Subscription>;
}

export default function CancelSubscription({
  subscription,
  setSubscription,
}: Readonly<CancelSubscriptionProps>) {
  const params = useParams();
  const submitCancelSubscription = (
    service_uuid: string,
    subscription_uuid: string,
  ) => {
    cancelSubscription(service_uuid, subscription_uuid , params.company_uuid).then((res) => {
      if (res.status === 200) {
        setSubscription({
          ...subscription,
          active:false
        });
        setOpenDialog(false);
        showSnackbar("Subscription canceled successfully", "success");
      }
    });
  };

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      {subscription?.active && (
        <Grid item xs={12} sm={6} m={2}>
          <Button
            variant={"contained"}
            onClick={() => setOpenDialog(true)}
            color="error"
            sx={{ width: "25%" }}
            data-testid="cancel_btn"
          >
            Cancel Subscription
          </Button>
        </Grid>
      )}

      <ConfirmationPopup
        dialogTitle="Cancel Subscription"
        dialogBody={
          <React.Fragment>
            <Box component={'p'}>Are you sure you want to cancel this subscription?</Box>
            <Box my={0}>
              Please note that:
              <ul>
                <li>No refunds are going to get issued.</li>
                <li>Cancellation will happen immediately.</li>
                <li>You can't undo this action.</li>
              </ul>
            </Box>
          </React.Fragment>
        }
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() =>
          submitCancelSubscription(subscription.service.uuid, subscription.uuid)
        }
        cancelButtonText="Keep Subscription"
        confirmButtonText="Cancel Subscription"
      />
    </>
  );
}