import { TableCell, TableRow } from "@mui/material";
import MUITable from "../Table";
import { formatDate } from "../../utilis/formatDate";

export default function CreditsListTable({ credits }) {
  const renderTableBody = (row) => (
    <TableRow key={row.id} data-testid="credit-row">
      <TableCell>{row?.service?.name}</TableCell>
      <TableCell>{row?.consumable?.name}</TableCell>
      <TableCell>{row?.quantity}</TableCell>
      <TableCell>{row?.reason ? row?.reason?.reason : '-'}</TableCell>
      <TableCell>{row?.compensated_by}</TableCell>
      <TableCell>{formatDate(row?.purchased_at_utc)}</TableCell>
    </TableRow>
  );

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "service.name",
          name: "Service",
        },
        {
          id: "consumable.name",
          name: "Consumable",
        },
        {
          id: "quantity",
          name: "Quantity",
        },
        {
          id: "reason.reason",
          name: "Reason",
        },
        {
          id: "compensated_by",
          name: "Compensated by",
        },
        {
          id: "purchased_at_utc",
          name: "Purchased at",
        },
      ]}
      hasPagination={true}
      data={credits}
      tableBodyRow={(row: any) => renderTableBody(row)}
    />
  );
}