import { useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import DeleteTier from "./DeleteTier";
import { TierResponse } from "../../interfaces/Tier";
import {
  IS_HIDDEN_TOOLTIP_TITLE,
  UNLIMITED_LICENSES,
} from "../../utilis/shared";
import { Company } from "../../interfaces/Company";

interface TiersListTableProps {
  tiers: TierResponse[];
  setTiers: (tiers: TierResponse[]) => void;
  companies: Company[];
  handleChangeHiddenStatus: (tier_uuid: string, active: boolean) => void;
}

export default function TiersListTable({
  tiers,
  setTiers,
  companies,
  handleChangeHiddenStatus,
}: Readonly<TiersListTableProps>) {
  const navigate = useNavigate();
  const params = useParams();

  const getCompany = (company_uuid) => {
    return companies?.find((company) => company.uuid === company_uuid);
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "default_price.unit_amount",
          name: "Price",
        },
        {
          id: "license_count",
          name: "License Count",
        },
        {
          id: "hidden",
          name: "Is hidden?",
          tooltip_title:IS_HIDDEN_TOOLTIP_TITLE,
        },
        {
          id: "companies",
          name: "Companies",
          tooltip_title:
            "This tier will be purchasable for the assigned companies only",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={tiers}
      tableBodyRow={(row: TierResponse) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Tooltip title={row?.description} placement="top-start">
              <p className="truncate_text">{row?.description}</p>
            </Tooltip>
          </TableCell>
          <TableCell>
            {row.default_price.unit_amount / 100} {row.default_price.currency.toUpperCase()} /{" "}
            {row.default_price.interval}
          </TableCell>
          <TableCell>
            {row.license_count === UNLIMITED_LICENSES
              ? "Unlimited"
              : row.license_count}
          </TableCell>
          <TableCell>
            {row.hidden ? 'Yes' : "No"}
            <Switch
              checked={row.hidden}
              onChange={() => handleChangeHiddenStatus(row.uuid, row.hidden)}
              aria-label="switch"
              size="small"
            />
          </TableCell>
          <TableCell>
            {row.companies?.length > 0
              ? row?.companies
                .map((company_uuid: string) => getCompany(company_uuid)?.name)
                .join(", ")
              : "-"}
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/service/${params.service_uuid}/tier/${row.uuid}`)
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/tier/${row.uuid}/edit`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteTier
              service_uuid={params.service_uuid}
              tier_uuid={row.uuid}
              tiers={tiers}
              setTiers={setTiers}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
