/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import {
  addRuleIntegration,
  getDefaultMessage,
  getDefaultMessageKeywords,
} from "../../apis/v1/Notifications/Integrations";
import AddIntegrationForm from "../../components/NotificationsRuleIntegrations/AddIntegrationForm";

export default function AddRuleIntegration() {
  const [keywords, setKeywords] = useState([]);
  const [defaultMessage, setDefaultMessage] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getDefaultMessageKeywords(params.rule_type).then((res) => {
      res && setKeywords(res.data);
    });
    getDefaultMessage(params.rule_type).then((res) => {
      res && setDefaultMessage(res.data);
    });
  }, []);

  const handleSubmitRule = (values) => {
    addRuleIntegration(params.company_uuid, params.rule_uuid, {
      type: values.type,
      targets: values.targets,
      message: values.message,
      rule_type: params.rule_type,
    })
      .then((res) => {
        if (res) {
          showSnackbar("Rule added successfully", "success");
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <PagesHeader title="Add Rule Integration" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddIntegrationForm
            handleSubmitRule={handleSubmitRule}
            keywords={keywords}
            defaultMessage={defaultMessage}
          />
        </Grid>
      </Grid>
    </>
  );
}
