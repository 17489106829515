/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import {
  editService,
  getServiceDetails,
  getServiceImage,
} from "../../apis/v1/Services/Services";
import EditServiceForm from "../../components/Services/EditServiceForm";

export default function EditService() {
  const navigate = useNavigate();
  const params = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    getServiceDetails(params.service_uuid).then((res) => {
      getServiceImage(params.service_uuid).then((image_res) => {
        setService({
          ...res.data,
          image: window.URL.createObjectURL(image_res.data),
        });
      });
    });
  }, []);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    values.image && formData.append("image", values.image);
    formData.append("color", values.color);
    editService(params.service_uuid, formData).then(() => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Edit Service" />
      <Grid container mt={3}>
        <Grid item sm={12} md={8} xl={6}>
          {service && (
            <EditServiceForm service={service} handleSubmit={handleSubmit} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
