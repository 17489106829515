export default function IconButtonOverrides() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          paddingTop: 1,
          paddingBottom: 1,
          "&:hover": {
            background: "transparent",
          },
        },
      },
    },
  };
}
