import { Link, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import LabelIcon from "@mui/icons-material/Label";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupIcon from "@mui/icons-material/Group";
import SellIcon from "@mui/icons-material/Sell";
export default function SideMenu({ open }: { open: boolean }) {
  const location = useLocation();
  const sideMenuList = [
    {
      name: "Admins",
      url: "/admins",
      icon: <AdminPanelSettingsIcon />,
    },
    {
      name: "Users",
      url: "/users",
      icon: <GroupIcon />,
    },
    {
      name: "Companies",
      url: "/companies",
      icon: <ApartmentIcon />,
    },
    {
      name: "Ocucon Camera Connection Template",
      url: "/connection/ocucon-camera-connection-templates",
      icon: <VideoFileIcon />,
    },
    {
      name: "Default Camera Settings",
      url: "/settings/camera/default-settings",
      icon: <SettingsIcon />,
    },
    {
      name: "Global Incident Classes",
      url: "/global-incident-classes",
      icon: <LabelIcon />,
    },
    {
      name: "Sync",
      url: "/sync",
      icon: <ElectricalServicesIcon />,
    },
   
    {
      name: "Central Platform Setup",
      url: "/services",
      icon: <ShoppingCartIcon />,
    },
    {
      name: "Manage Subscriptions",
      url: "/subscriptions",
      icon: <SellIcon />,
    },
  ];
  return (
    <List sx={{ py: 0 }}>
      {sideMenuList.map((item, index) => (
        <Link to={item.url} key={index}>
          <Tooltip placement="right-start" title={item.name}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={location.pathname === item.url}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Link>
      ))}
    </List>
  );
}
