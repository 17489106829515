/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getCompanyChildNode } from "../../apis/v1/OrgStructure";
import { useLocation, useParams } from "react-router-dom";
import MUIBreadcrumb from "../../components/Breadcrumb";
import ChildNodesList from "../../components/ChildNodes/ChildNodesList";
import AddNodeMenu from "../../components/ChildNodes/AddNodeMenu";
import { node_types } from "../../utilis/enum";
import { OrgStructureNode } from "../../interfaces/OrgStructure";

export default function ChildNodes() {
  const params = useParams();
  const location = useLocation();
  const [parentNode, setParentNode] = useState<OrgStructureNode>();

  const requestNodes = () => {
    getCompanyChildNode(params.company_uuid, params.node_uuid).then((res) => {
      setParentNode(res.data);
    });
  };

  useEffect(() => {
    requestNodes();
  }, [params.node_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            ...location.state,
          ],
          current: location && parentNode?.name,
        }}
      />
      <PagesHeader title="Node Management" />
      <Card variant="outlined">
        <CardHeader
          title={"Parent Node"}
          subheader="This is the parent node details"
        />
        <CardContent sx={{ mb: 2 }}>
          <Typography> {parentNode?.name}</Typography>
        </CardContent>
      </Card>
      {parentNode?.type !== node_types.SITE_NODE && (
        <Card variant="outlined">
          <CardHeader
            title={"Child Nodes"}
            subheader="This is the child node children list"
          />
          <CardContent>
            <AddNodeMenu
              parentNode={parentNode}
              state={[
                ...location.state,
                {
                  name: parentNode?.name,
                  path: location.pathname,
                  state: location.state,
                },
              ]}
            />
          </CardContent>

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={6}>
                <ChildNodesList
                  childNodes={parentNode?.children}
                  state={[
                    ...location.state,
                    {
                      name: parentNode?.name,
                      path: location.pathname,
                      state: location.state,
                    },
                  ]}
                  updateNodeList={requestNodes}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
