import { AxiosPromise } from "axios";
import { axiosInstance } from "../config/axiosInstance";

const policyBaseURL = `/payment/v1/policies`;
export const createPolicy = (data: any): AxiosPromise<any> => {
  return axiosInstance.post(policyBaseURL, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const getPolicies = (): AxiosPromise<any[]> => {
  return axiosInstance.get(policyBaseURL);
};

export const updatePolicy = (policy_uuid, data) => {
  return axiosInstance.patch(`${policyBaseURL}/${policy_uuid}`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const getPolicy = (policy_uuid: string): AxiosPromise<any> => {
  return axiosInstance.get(`${policyBaseURL}/${policy_uuid}`);
};

export const deletePolicy = (policy_uuid: string): AxiosPromise<void> => {
  return axiosInstance.delete(`${policyBaseURL}/${policy_uuid}`);
};

export const getPolicyFile = (policy_uuid): AxiosPromise<Blob> => {
  return axiosInstance.get(`${policyBaseURL}/${policy_uuid}/file`, {
    responseType: "blob"
  });
};
