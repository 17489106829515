import { BrowserRouter } from "react-router-dom";
import MUISnackbar from "./components/Snackbar";
import Routes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <MUISnackbar />
    </BrowserRouter>
  );
}

export default App;
