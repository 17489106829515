import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../../../Table";
import DeleteDefaultSettings from "./DeleteDefaultSettings";
import { CameraDefaultSettings } from "../../../../interfaces/Settings";

interface ListTableProps {
  defaultSettings: CameraDefaultSettings[];
  setDefaultSettings: React.Dispatch<CameraDefaultSettings[]>;
}

export default function ListTable({ defaultSettings, setDefaultSettings }: ListTableProps) {
  const navigate = useNavigate();
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "data_type",
          name: "Data Type",
        },
        {
          id: "default_value",
          name: "Default Value",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={defaultSettings}
      tableBodyRow={(row: any) => (
        <TableRow key={row.site_uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Tooltip title={row?.description} placement="top-start">
              <p className="truncate_text">{row?.description}</p>
            </Tooltip>
          </TableCell>
          <TableCell>{row.data_type}</TableCell>
          <TableCell>{row?.default_value?.toString()}</TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/settings/camera/default-settings/${row.uuid}`)
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/settings/camera/default-settings/edit/${row.uuid}`)
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteDefaultSettings
              default_settings_uuid={row.uuid}
              defaultSettings={defaultSettings}
              setDefaultSettings={setDefaultSettings}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
