import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteConsumableAddon } from "../../apis/v1/Services/ConsumablesAddons";

interface DeleteConsumableAddonsProps {
  consumable_uuid: string;
  service_uuid: string;
  consumable_addon_uuid: string;
  consumableAddons: any[];
  setConsumableAddons: React.Dispatch<any[]>;
}

export default function DeleteConsumableAddons({
  consumable_uuid,
  service_uuid,
  consumable_addon_uuid,
  consumableAddons,
  setConsumableAddons,
}: DeleteConsumableAddonsProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getConsumablesAddonsListWithoutDeleted = () => {
    return consumableAddons.filter(
      (consumableAddon) => consumableAddon.uuid !== consumable_addon_uuid,
    );
  };

  const submitDeleteSite = () => {
    deleteConsumableAddon(
      service_uuid,
      consumable_uuid,
      consumable_addon_uuid,
    ).then(() => {
      const consumablesAddonsListWithoutDeleted =
        getConsumablesAddonsListWithoutDeleted();
      showSnackbar("Deleted Successfully", "success");
      setConsumableAddons(consumablesAddonsListWithoutDeleted);
      setOpenDialog(false);
    });
  };
  return (
    <>
      <IconButton size="small" onClick={() => setOpenDialog(true)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Consumable Addon"
        dialogBody="Are you sure you want to delete this consumable addon?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
