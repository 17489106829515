import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import { Service } from "../../interfaces/Service";
import DeleteService from "./DeletService";

interface ServicesListTableProps {
  services: Service[];
  setServices: (services : Service[]) => void
}

export default function ServicesListTable({
  services,
  setServices
}: Readonly<ServicesListTableProps>) {
  const navigate = useNavigate();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={services}
      tableBodyRow={(row: Service) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Tooltip title={row?.description} placement="top-start">
              <p className="service_truncate_text">{row?.description}</p>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() => navigate(`/service/${row.uuid}`)}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => navigate(`/service/edit/${row.uuid}`)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteService
              service_uuid={row.uuid}
              services={services}
              setServices={setServices}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
