/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import DetectionsImage from "./DetectionsImage";
import DetectionsDrawer from "./DetectionsDrawer";
import useDrawer from "./Hooks/useDrawer";
import "./style.css";

export default function DetectionsArea({
  image,
  shapes,
  setShapes,
  activeShape,
  setActiveShape,
  handleSubmitShape,
  isDrawingActive,
  setIsDrawingActive,
  isEditable,
}) {
  const svg = useRef<any>();
  const polygon = useRef<any>([]);
  const [mousePos, setMousePos] = useState<any>(null);
  const [mouseInitialPosition, setMouseInitialPosition] = useState<any>(null);
  const [isPointActive, setIsPointActive] = useState<any>(null);
  const [isPointDragging, setIsPointDragging] = useState<any>(null);
  const [svgWidth, setSvgWidth] = useState<any>(null);
  const [svgHeight, setSvgHeight] = useState<any>(null);
  const [activePointIndex, setActivePointIndex] = useState(null);
  const {
    getMousePoisition,
    deletePointHandler,
    dragPointHandler,
    shapeDrawerHandler,
  } = useDrawer();

  function globalMousemove(e) {
    handleMouseMoveOnSVG(e);
  }
  function globalMouseup(_event) {
    document.removeEventListener("mousemove", globalMousemove);
    document.removeEventListener("mouseup", globalMouseup);
    stopDrawerHandler();
  }

  function mousedown(event) {
    document.addEventListener("mousemove", globalMousemove);
    document.addEventListener("mouseup", globalMouseup);
  }

  useEffect(() => {
    svg.current.addEventListener("mousedown", mousedown);
  }, [svg]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setIsDrawingActive(false);
        setShapes(shapes.slice(0, -1));
        setActiveShape(null);
        document.removeEventListener("keydown", escFunction);
      }
    },
    [isDrawingActive],
  );

  useEffect(() => {
    if (isDrawingActive) {
      return document.addEventListener("keydown", escFunction);
    }
  }, [isDrawingActive]);

  const stopDrawerHandler = () => {
    setIsPointActive(false);
    setIsPointDragging(false);
  };

  // Handle Mouse down to detect if inside polygon or not
  const handleMouseDownOnSVG = (e: any) => {
    const { mouseX, mouseY } = getMousePoisition(e, svg, setMousePos);
    setMouseInitialPosition({
      startX: mouseX,
      startY: mouseY,
    });

    const updatedShapes = shapeDrawerHandler(
      e,
      mouseX,
      mouseY,
      isDrawingActive,
      shapes,
      svgWidth,
      svgHeight,
    );
    setShapes(updatedShapes, () => {});
  };

  // Stop dragging shape when mouse is UP
  const handleMouseUpOnSVG = () => {
    // stopDrawerHandler();
  };

  // Start dragging shape if isDragging is true but not dragging point
  const handleMouseMoveOnSVG = (e: any) => {
    const { mouseX, mouseY } = getMousePoisition(e, svg, setMousePos);
    if (isPointDragging) {
      const points = dragPointHandler(
        mouseInitialPosition,
        activeShape,
        svgHeight,
        svgWidth,
        activePointIndex,
        mouseX,
        mouseY,
      );
      handleUpdateShapePoints(points);
    }
  };

  const handleDeletePoint = (activeShapeIndex, activePointIndex) => {
    const updatedShapes = deletePointHandler(
      activeShapeIndex,
      activePointIndex,
      shapes,
    );
    setShapes(updatedShapes, () => {
      setActiveShape({
        ...activeShape,
        ...updatedShapes[activeShapeIndex],
      });

      handleSubmitShape(updatedShapes[activeShapeIndex]);
    });
  };

  const handleUpdateShapePoints = (updatedPoints: any) => {
    setShapes(
      [
        ...shapes.map((shape: any, index: number) => {
          if (index === activeShape.index) {
            return { ...shape, points: updatedPoints };
          } else {
            return shape;
          }
        }),
      ],
      () => {},
    );
  };

  // Handle mouse down to start dragging points from corner
  const handleMouseDownOnCircle = (shapeIndex: number) => {
    setMouseInitialPosition({
      startX: mousePos.mouseX,
      startY: mousePos.mouseY,
    });
    setIsPointActive(true);
    setActiveShape({
      index: shapeIndex,
      ...shapes[shapeIndex],
    });
  };

  // Handle Dragging circle when mouse move on circle
  const handleMouseMoveOnCircle = (e: any) => {
    if (isPointActive && activeShape.uuid) {
      setIsPointDragging(true);
    }
  };

  // Clear and reset all keys to false after release circle shape
  const handleMouseUpOnCircle = () => {
    // stopDrawerHandler();
  };

  const handleCloseShape = () => {
    handleSubmitShape(shapes[activeShape?.index]);
    setActiveShape({
      ...activeShape,
    });
    document.removeEventListener("keydown", escFunction);
  };

  return (
    <Box style={{ position: "relative", height: "100%", width: "100%" }}>
      <DetectionsImage
        svg={svg}
        setSvgWidth={setSvgWidth}
        setSvgHeight={setSvgHeight}
        handleMouseMoveOnSVG={handleMouseMoveOnSVG}
        handleMouseDownOnSVG={handleMouseDownOnSVG}
        handleMouseUpOnSVG={handleMouseUpOnSVG}
        image={image}
        isDrawingActive={isDrawingActive}
      >
        <DetectionsDrawer
          shapes={shapes}
          polygonRef={polygon}
          handleMouseDownOnCircle={handleMouseDownOnCircle}
          handleMouseMoveOnCircle={handleMouseMoveOnCircle}
          handleMouseUpOnCircle={handleMouseUpOnCircle}
          activeShape={activeShape}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
          setActiveShape={setActiveShape}
          handleDeletePoint={handleDeletePoint}
          handleCloseShape={handleCloseShape}
          handleSubmitShape={handleSubmitShape}
          isPointDragging={isPointDragging}
          activePointIndex={activePointIndex}
          setActivePointIndex={setActivePointIndex}
          isEditable={isEditable}
          isDrawingActive={isDrawingActive}
        />
      </DetectionsImage>
    </Box>
  );
}
