/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import {
  editRuleIntegration,
  getDefaultMessageKeywords,
  getRuleIntegration,
} from "../../apis/v1/Notifications/Integrations";
import EditIntegrationForm from "../../components/NotificationsRuleIntegrations/EditIntegrationForm";

export default function EditRuleIntegration() {
  const navigate = useNavigate();
  const params = useParams();
  const [integration, setIntegration] = useState({});
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    getRuleIntegration(
      params.company_uuid,
      params.rule_uuid,
      params.integration_uuid,
    ).then((res) => {
      res && setIntegration(res.data);
    });
    getDefaultMessageKeywords(params.rule_type).then((res) => {
      res && setKeywords(res.data);
    });
  }, []);

  const handleSubmitRule = (values) => {
    editRuleIntegration(
      params.company_uuid,
      params.rule_uuid,
      params.integration_uuid,
      {
        type: values.type,
        targets: values.targets,
        message: values.message,
        rule_type: params.rule_type,
      },
    )
      .then((res) => {
        if (res) {
          showSnackbar("Rule added successfully", "success");
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <PagesHeader title="Edit Rule Integration" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <EditIntegrationForm
            integration={integration}
            handleSubmitRule={handleSubmitRule}
            keywords={keywords}
          />
        </Grid>
      </Grid>
    </>
  );
}
