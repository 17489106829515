import { useNavigate } from "react-router-dom";
import { TableCell, Link, TableRow, Tooltip } from "@mui/material";
import MUITable from "../Table";
import { SubscriptionHistory } from "../../interfaces/Subscription";
import { Company } from "../../interfaces/Company";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import { formatDate } from "../../utilis/formatDate";

interface AllSubscriptionsHistoriesListTableProps {
  subscriptionHistories: SubscriptionHistory[];
  rootNodes: Array<OrgStructureNode>;
  companies: Array<Company>;
}

export default function AllSubscriptionsHistoriesListTable({
  subscriptionHistories,
  rootNodes,
  companies,
}: Readonly<AllSubscriptionsHistoriesListTableProps>) {
  const navigate = useNavigate();
  const getRootNodePerCompany = (company_uuid) => {
    const rootNode = rootNodes?.find(
      (node) => node?.company_uuid === company_uuid,
    );
    return rootNode;
  };
  const getUserCompany = (company_uuid: string) => {
    const company = companies?.find((company) => company.uuid === company_uuid);
    return company;
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "company_uuid",
          name: "Company",
        },
        {
          id: "service.name",
          name: "Service",
        },
        {
          id: "type",
          name: "Action",
        },
        {
          id: "user_email",
          name: "User",
        },
        {
          id: "user_type",
          name: "User Type",
        },
        {
          id: "cancel_reason",
          name: "Cancel Reason",
        },
        {
          id: "created_at_utc",
          name: "Date",
        },
      ]}
      hasPagination={true}
      data={subscriptionHistories}
      tableBodyRow={(row: SubscriptionHistory) => (
        <TableRow key={row.created_at_utc.toString()}>
          <TableCell>
            {
              <Tooltip title={"View company"} placement="top">
                <Link
                  onClick={() =>
                    navigate(
                      `/company/${row?.company_uuid}/root-node/${getRootNodePerCompany(row?.company_uuid)?.uuid}`,
                    )
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {getUserCompany(row.company_uuid)?.name || "-"}
                </Link>
              </Tooltip>
            }
          </TableCell>
          <TableCell>{row.service.name}</TableCell>
          <TableCell>{row.type}</TableCell>
          <TableCell>{row.user_email}</TableCell>
          <TableCell>{row.user_type}</TableCell>
          <TableCell>{row.cancel_reason || " - "}</TableCell>
          <TableCell>{formatDate(row.created_at_utc)}</TableCell>
        </TableRow>
      )}
    />
  );
}
