import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteCameraSettingsOverrides } from "../../../../apis/v1/Settings/CameraOverrides";
import { showSnackbar } from "../../../../utilis/snackbar";
import ConfirmationPopup from "../../../ConfirmationPopup";

interface DeleteSettingsOverridesProps {
  onDelete: () => void;
  overrides_settings_uuid: string;
}

export default function DeleteSettingsOverrides({
  overrides_settings_uuid,
  onDelete,
}: DeleteSettingsOverridesProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const params = useParams();

  const submitDeleteSite = () => {
    deleteCameraSettingsOverrides(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      overrides_settings_uuid,
    ).then((res) => {
      if (res) {
        onDelete();
        showSnackbar("Deleted Successfully", "success");
        setOpenDialog(false);
      }
    });
  };
  return (
    <>
      <IconButton size="small" onClick={() => setOpenDialog(true)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete camera override settings"
        dialogBody="Are you sure you want to delete this camera override settings?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteSite()}
      />
    </>
  );
}
