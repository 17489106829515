/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateOcuconTemplate,
  getCompanyTemplateAttributesByUUID,
  getTemplateDetails,
} from "../../../apis/v1/Camera/CompanyCameraTemplates";
import EditCompanyTemplateStep2 from "../../../components/Cameras/v1/CompanyTemplate/EditTemplateForm/Step2";
import PagesHeader from "../../../components/PagesHeader";

export default function EditCompanyCameraTemplateAttributes() {
  const [template, setTemplate] = useState<any>([]);
  const [attributes, setAttributes] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const activateTemplate = () => {
    activateOcuconTemplate(params.company_uuid, params.uuid).then((res) => {
      res && navigate(-2);
    });
  };

  useEffect(() => {
    getTemplateDetails(params.company_uuid, params.uuid).then((res) => {
      if (res) {
        setTemplate(res.data);
      }
    });
    getCompanyTemplateAttributesByUUID(params.company_uuid, params.uuid).then(
      (res) => {
        if (res) {
          setAttributes(
            res.data.attributes.map((attr, index) => {
              return {
                ...attr,
                id: index.toString(),
              };
            }),
          );
        }
      },
    );
  }, []);
  return (
    <>
      <PagesHeader title="Edit Company Template Attributes" />

      <EditCompanyTemplateStep2
        activateTemplate={activateTemplate}
        attributes={attributes}
        template={template}
      />
    </>
  );
}
