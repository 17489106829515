import { Grid } from "@mui/material";
import { Customer } from "../../../../interfaces/User";
import { Company } from "../../../../interfaces/Company";
import UserDetails from "./UserDetails";
import UserPermissions from "./UserPermissions";
import UserLicenses from "./UserLicenses";

interface UserDetailsCardProps {
  customer: Customer;
  company: Company;
}

export default function UserDetailsCard({
  customer,
  company,
}: Readonly<UserDetailsCardProps>) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12} sm={12} md={12}>
        <UserDetails customer={customer} company={company} />
        <UserPermissions />
        <UserLicenses />
      </Grid>
    </Grid>
  );
}
