import PagesHeader from "../../components/PagesHeader";
import { useNavigate } from "react-router-dom";
import AddAdminForm from "../../components/Admins/v1/AddAdminForm";
import { addAdmin } from "../../apis/v1/UserManagement";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { ADD_USER_COMMENT } from "../../utilis/shared";

export default function AddAdmin() {
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    addAdmin({
      ...values,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL_ADMIN_DASHBOARD,
    }).then((res) => {
      if (res) {
        navigate("/admins");
        showSnackbar("Admin added successfully", "success");
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Admin" />
      <p>{ADD_USER_COMMENT}</p>
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddAdminForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
