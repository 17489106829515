/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import DetailsCard from "../../components/Companies/v1/DetailsCard";
import MUIBreadcrumb from "../../components/Breadcrumb";
import SitesList from "../../components/CompanyDetails/SitesList";
import UsersList from "../../components/CompanyDetails/UsersList";
import CameraConnectionTemplatesList from "../../components/CompanyDetails/CameraConnectionTemplatesList";
import CameraSettingsOverridesList from "../../components/CompanyDetails/CameraSettingsOverridesList";
import OrgStructure from "../../components/CompanyDetails/OrgStructure";
import CompanyNotificationRulesList from "../../components/CompanyDetails/CompanyNotificationRulesList";
import { getCompaniesDetails } from "../../apis/v1/Companies";
import { SiteResponse } from "../../interfaces/Site";
import { Company } from "../../interfaces/Company";
import { RouterHistoryState } from "../../interfaces/RouterHistoryState";
import IncidentCausesList from "../../components/CompanyDetails/IncidentCausesList";
import CompanyIncidentClassList from "../../components/CompanyDetails/CompanyIncidentClassList";
import SubscriptionsList from "../../components/CompanyDetails/SubscriptionsList";

export default function CompanyDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [company, setCompany] = useState<Company>();
  const [sites, setSites] = useState<SiteResponse[]>();
  const [templates, setTemplates] = useState(null);
  const [currentRouteState, setCurrentRouteState] =
    useState<RouterHistoryState>();

  const getCompanyDetailsRequest = () => {
    getCompaniesDetails(params.company_uuid).then((res) => {
      if (res) {
        setCompany(res.data);
        setSites(res.data.sites);
        setTemplates(res.data.company_camera_templates);
        setCurrentRouteState({
          company_name: res.data.name,
          prevCompanyLocation: location.pathname,
        });
      }
    });
  };

  useEffect(() => {
    getCompanyDetailsRequest();
  }, [params.company_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
          ],
          current: company?.name,
        }}
      />
      {company && (
        <>
          <Card variant="outlined">
            <CardHeader
              title="Company Details"
              subheader="Details about this company"
            />
            <CardContent>
              <Button
                variant={"outlined"}
                onClick={() => navigate(`/company/edit/${params.company_uuid}`)}
              >
                Edit
              </Button>
            </CardContent>
            <CardContent>
              <DetailsCard company={company} />
            </CardContent>
          </Card>
          <SubscriptionsList currentRouteState={currentRouteState} />
          <SitesList
            sites={sites}
            setSites={setSites}
            currentRouteState={currentRouteState}
          />
          <UsersList currentRouteState={currentRouteState} />
          <CameraConnectionTemplatesList
            currentRouteState={currentRouteState}
            templates={templates}
            setTemplates={setTemplates}
            getCompanyDetailsRequest={getCompanyDetailsRequest}
          />
          <CameraSettingsOverridesList />
          <OrgStructure currentRouteState={currentRouteState} />
          <CompanyIncidentClassList />
          <CompanyNotificationRulesList currentRouteState={currentRouteState} />
          <IncidentCausesList />
        </>
      )}
    </>
  );
}
