/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { IconButton, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import DeleteRuleIntegration from "./DeleteRuleIntegration";

export default function NotificationsRulesIntegrationsTable({
  company_uuid,
  integrations,
  setIntegrations,
  rule_type,
}) {
  const navigate = useNavigate();
  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "type",
          name: "Type",
        },
        {
          id: "targets",
          name: "Targets",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={integrations}
      tableBodyRow={(row: any) => {
        return (
          <TableRow key={row.uuid}>
            <TableCell>{row.type}</TableCell>
            <TableCell>{row.targets.join(" , ")}</TableCell>
            <TableCell align="right">
              <IconButton
                size="small"
                onClick={() =>
                  navigate(
                    `/company/${company_uuid}/notifications/rule/${row.rule_uuid}/type/${rule_type}/integration/${row.uuid}/edit`,
                  )
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <DeleteRuleIntegration
                integration_uuid={row.uuid}
                rule_uuid={row.rule_uuid}
                company_uuid={company_uuid}
                integrations={integrations}
                setIntegrations={setIntegrations}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
