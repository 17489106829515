/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CameraDefaultSettingsDetailsCard from "../../../components/Settings/v1/CameraDefaultSettings/DetailsCard";
import { getSingleCameraDefaultSettings } from "../../../apis/v1/Settings/CameraDefaults";
import { CameraDefaultSettings } from "../../../interfaces/Settings";

export default function CameraDefaultSettingsDetails() {
  const [defaultSettingsDetails, setDefaultSettingsDetails] =
    useState<CameraDefaultSettings>();
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    getSingleCameraDefaultSettings(params.uuid).then((res) => {
      if (res) {
        setDefaultSettingsDetails(res.data);
      }
    });
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        title="Camera Defaults Details"
        subheader="This is the details page for camera default settings"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/settings/camera/default-settings/edit/${params.uuid}`)
          }
        >
          Edit
        </Button>
      </CardContent>
      <CardContent>
        {defaultSettingsDetails && (
          <CameraDefaultSettingsDetailsCard
            defaultSettingsDetails={defaultSettingsDetails}
          />
        )}
      </CardContent>
    </Card>
  );
}
