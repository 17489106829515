import { Box, Grid, Tooltip } from "@mui/material";
import AttributesTable from "../AttributesTable";
import { template_attributes_types } from "../../../../utilis/templateAttributesFilter";
import { IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE } from "../../../../utilis/shared";

export default function OcuconCameraConnectionTemplateCard({
  template,
  attributes,
}) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="ocuconTemplate-details" item xs={12}>
        <Box component={"h4"} mb={2}>
          Template Details
        </Box>
      </Grid>
      <Grid data-testid="ocuconTemplate-name" item xs={12} sm={6} md={4}>
        <Box component={"h6"} my={0}>
          Name
        </Box>
        <Box component={"p"} mt={0} mb={1}>
          {template.name}
        </Box>
      </Grid>
      <Grid data-testid="ocuconTemplate-isHttp" item xs={12} sm={6} md={4}>
        <Box component={"h6"} my={0}>
          Is Http ?
        </Box>
        <Box component={"p"} mt={0} mb={1}>
          {template.is_http ? "Yes" : "No"}
        </Box>
      </Grid>
      <Grid data-testid="ocuconTemplate-isActive" item xs={12} sm={6} md={4}>
        <Tooltip
          title={IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE}
          placement={"top-start"}
        >
          <Box component={"h6"} my={0}>
            Is Active?
          </Box>
        </Tooltip>
        <Box component={"p"} mt={0} mb={1}>
          {template.is_active ? "Yes" : "No"}
        </Box>
      </Grid>

      <Grid data-testid="ocuconTemplate" item xs={12}></Grid>
      {template.is_http && (
        <>
          <Grid data-testid="ocuconTemplate-httpDetails" item xs={12}>
            <Box component={"h4"} mb={2}>
              HTTP Details
            </Box>
          </Grid>
          <Grid data-testid="ocuconTemplate-templateURL" item xs={12}>
            <Box component={"h6"} my={0}>
              URL Template
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {template.url_template}
            </Box>
          </Grid>
          <Grid data-testid="ocuconTemplate-templateURL" item xs={12}>
            <Box component={"h6"} my={0}>
              Auth Type
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {template.auth_type ? template.auth_type : "None"}
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12}>
        <Box component={"h4"} mb={2}>
          Attributes
        </Box>
      </Grid>
      {template_attributes_types(template.is_http).map((attr_type: string) => (
        <Grid item xs={12} sm={12} key={attr_type} data-testid="ocuconTemplate-attributes">
          <Box component={"h5"} sx={{ textTransform: "capitalize" }} mt={0}>
            {attr_type.split("_").join(" ")}
          </Box>
          <AttributesTable attributes={attributes} attribute_type={attr_type} />
        </Grid>
      ))}
    </Grid>
  );
}
