import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { deleteCustomer } from "../../../apis/v1/UserManagement";
import { User } from "../../../interfaces/User";
import { showSnackbar } from "../../../utilis/snackbar";
import ConfirmationPopup from "../../ConfirmationPopup";

export default function DeleteUser({
  id,
  customers,
  setCustomers,
}: {
  id: string;
  customers: User[];
  setCustomers: React.Dispatch<User[]>;
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const getUsersListWithoutDeleted = () => {
    return customers.filter((customer: User) => customer.id !== id);
  };

  const getUserToDelete = () => {
    return customers.find((customer: User) => customer.id === id);
  };
  const submitDeleteUser = () => {
    deleteCustomer(id).then(() => {
      const adminsListWithoutDeleted = getUsersListWithoutDeleted();
      setCustomers(adminsListWithoutDeleted);
      showSnackbar("User Deleted Successfully", "success");
      setOpenDialog(false);
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Customer"
        dialogBody={`Are you sure you want to delete ${getUserToDelete().username}?`}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteUser()}
      />
    </>
  );
}
