import { useNavigate, useParams } from "react-router-dom";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import { Service } from "../../interfaces/Service";
import DeleteFeature from "./DeletFeature";
import { Feature } from "../../interfaces/Features";

interface FeaturesListTableProps {
  features: Feature[];
  setFeatures: (features : Feature[]) => void
}

export default function FeaturesListTable({
  features,
  setFeatures
}: FeaturesListTableProps) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={features}
      tableBodyRow={(row: Service) => (
        <TableRow key={row.uuid}>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Tooltip title={row?.description} placement="top-start">
              <p className="truncate_text">{row?.description}</p>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() => navigate(`/service/${params.service_uuid}/feature/${row.uuid}/edit`)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteFeature
              service_uuid={params.service_uuid}
              feature_uuid={row.uuid}
              features={features}
              setFeatures={setFeatures}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
