import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, ListSubheader } from "@mui/material";
import { Box } from "@mui/system";

export default function GroupedTemplatesSelect({
  error,
  value,
  label,
  labelId,
  id,
  name,
  handleChange,
  handleBlur,
  keyItem,
  keyValue,
  list,
  required,
  styles,
  dataTestid,
  disabled,
  margin,
}: any) {
  const groupList = (templates) => {
    return templates?.map((item: any) => {
      return (
        <MenuItem key={item[keyItem]} value={item[keyItem]}>
          {item[keyValue]}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl
      size="small"
      disabled={disabled}
      fullWidth
      error={Boolean(error)}
      margin={margin}
      sx={styles}
    >
      <InputLabel id={labelId}>{`${label}${required ? "*" : ""}`}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={`${label}${required ? "*" : ""}`}
        fullWidth
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || ""}
        data-testid={dataTestid}
        size="small"
      >
        <ListSubheader>Ocucon Templates:</ListSubheader>
        {groupList(list.ocucon_templates)}
        <ListSubheader>Company Templates:</ListSubheader>
        {groupList(list.company_templates)}
        {list.length <= 0 && <Box textAlign="center">No options</Box>}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
