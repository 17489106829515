import { Box, Grid } from "@mui/material";
import { Subscription } from "../../interfaces/Subscription";
import { formatDate } from "../../utilis/formatDate";
import { UNLIMITED_LICENSES } from "../../utilis/shared";

export default function DetailsCard({
  subscription,
  licenses,
}: Readonly<{
  subscription: Subscription;
  licenses: any[];
}>) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="subscription-details-title" item xs={12}>
        <Box component={"h4"} m={0}>
          Subscription Details
        </Box>
      </Grid>
      <Grid data-testid="subscription-details" item xs={12} sm={6} md={4}>
        <Box component={"p"} data-testid="service_name" my={0}>
          Service: {subscription?.service.name}
        </Box>
        <Box component={"p"} data-testid="tier_name" my={0}>
          Tier : {subscription?.tier.name}
        </Box>
        <Box component={"p"} data-testid="subscription_active" my={0}>
          Active : {subscription?.active ? "Yes" : "No"}
        </Box>
        <Box component={"p"} data-testid="auto_renew" my={0}>
          Auto renew enabled : {subscription?.auto_renew ? "Yes" : "No"}
        </Box>
        <Box component={"p"} data-testid="subscription_activated_at" my={0}>
          Activated at : {formatDate(subscription.activated_at_utc)}
        </Box>
        <Box component={"p"} data-testid="subscription_expires_at" my={0}>
          Expires at :{" "}
          {subscription.expires_at_utc
            ? formatDate(subscription.expires_at_utc)
            : "No expiration date"}
        </Box>

        <Box component={"p"} data-testid="subscription_liceneces" my={0}>
          Licences Used : {licenses.length} {" / "}
          {subscription.tier.license_count === UNLIMITED_LICENSES
            ? "Unlimited"
            : subscription.tier.license_count}
        </Box>
      </Grid>
      <Grid data-testid="subscription-tier-details" item xs={12}>
        <Box component={"h4"} mb={0}>
          Tier Details
        </Box>
      </Grid>
      <Grid data-testid="subscription-tier-name" item xs={12} sm={6} md={4}>
        <Box component={"p"} data-testid="subscription_tier_name_1" my={0}>
          Name: {subscription?.tier?.name}
        </Box>
        <Box component={"p"} my={0}>
          Default Price: {subscription.tier.default_price.unit_amount / 100}{" "}
          {subscription.tier.default_price.currency} /{" "}
          {subscription.tier.default_price.interval}
        </Box>

        <Box my={0}>
          Features:
          <Box component={"ul"} my={0}>
            {subscription.tier.features.map((feature) => (
              <li key={feature.name}>{feature.name}</li>
            ))}
          </Box>
        </Box>
        <Box my={0} mb={2}>
          Consumables:
          {subscription.tier.consumables.length > 0 ? (
            <Box component={"ul"} my={0}>
              {subscription.tier.consumables.map((consumable) => (
                <li key={consumable.name}>
                  Name: {consumable.name} , Amount: {consumable.amount}
                </li>
              ))}{" "}
            </Box>
          ) : (
            ` - `
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
