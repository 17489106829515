import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Formik } from "formik";
import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { Reason } from "../../interfaces/Credits";

interface EditReasonFormProps {
  handleSubmitForm: (values: Pick<Reason, "reason">) => void;
  reason: Reason;
}

export default function EditReasonForm({
  handleSubmitForm,
  reason
}: Readonly<EditReasonFormProps>) {

  const navigate = useNavigate();
  const schema: SchemaOf<Pick<Reason, "reason">> = yup.object().shape({
    reason: yup.string().required("Required"),
  });
  const initialValues = {
    reason: reason?.reason ?? "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values: any) => handleSubmitForm(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="reason"
            label={"Reason*"}
            fullWidth
            name="reason"
            error={Boolean(errors.reason && touched.reason)}
            helperText={errors.reason && touched.reason && errors.reason}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.reason}
            size="small"
            data-testid="reason-input"
          />

          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
