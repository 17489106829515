import { Box, Divider } from "@mui/material";

export default function NoDataFound({
  data,
  showFetchingDataProblemText,
}: any) {
  const isNoDataFound = () => {
    return !data || (data && data.length === 0);
  };
  return isNoDataFound() ? (
    <>
      <Box component="p" textAlign="center">
        {showFetchingDataProblemText
          ? "Problem fetching data - please try later"
          : "No data found"}
      </Box>
      <Divider />
    </>
  ) : (
    <></>
  );
}
