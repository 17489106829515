import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../../components/PagesHeader";
import { addCameraDefaultSettings } from "../../../apis/v1/Settings/CameraDefaults";
import AddDefaultSettingsForm from "../../../components/Settings/v1/CameraDefaultSettings/AddDefaultSettingsForm";
import { parseOverrideValue, showSnackbar } from "../../../utilis/snackbar";

export default function AddCameraDefaultSettings() {
  const navigate = useNavigate();

  const handleSubmit = (values: any) => {
    const default_value = parseOverrideValue(values.default_value);
    addCameraDefaultSettings({
      ...values,
      default_value,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Camera Default Settings" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddDefaultSettingsForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
