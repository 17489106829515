import { Button, Card, CardContent, CardHeader } from "@mui/material";
import SitesListTable from "../Sites/v1/SitesListTable";
import { useNavigate, useParams } from "react-router-dom";
import { SiteResponse } from "../../interfaces/Site";
import { RouterHistoryState } from "../../interfaces/RouterHistoryState";

interface SitesListProps {
  sites: SiteResponse[];
  setSites: (sites: SiteResponse[]) => void;
  currentRouteState: RouterHistoryState;
}

export default function SitesList({
  sites,
  setSites,
  currentRouteState,
}: SitesListProps) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Card variant="outlined">
      <CardHeader title="Sites" subheader="A list of sites" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/node/${params.root_node_uuid}/site/add`,
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <SitesListTable
          company_uuid={params.company_uuid}
          currentRouteState={currentRouteState}
          root_node_uuid={params.root_node_uuid}
          sites={sites}
          setSites={setSites}
        />
      </CardContent>
    </Card>
  );
}
