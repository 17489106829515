/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import EditConsumableForm from "../../components/Consumables/EditConsumableForm";
import { showSnackbar } from "../../utilis/snackbar";
import {
  editConsumable,
  getConsumableDetails,
} from "../../apis/v1/Services/Consumables";
import { Consumable } from "../../interfaces/Consumable";

export default function EditConsumable() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumable, setConsumables] = useState<Consumable>();

  useEffect(() => {
    getConsumableDetails(params.service_uuid, params.consumable_uuid).then(
      (res) => {
        setConsumables(res.data);
      },
    );
  }, []);

  const handleSubmit = (values) => {
    editConsumable(params.service_uuid, params.consumable_uuid, values).then(
      () => {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      },
    );
  };

  return (
    <>
      <PagesHeader title="Edit Consumable" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          {consumable && (
            <EditConsumableForm
              consumable={consumable}
              handleSubmit={handleSubmit}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
