import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alertSlice";
import authSlice from "./slices/authSlice";
import loaderSlice from "./slices/loaderSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    alert: alertSlice,
    loader: loaderSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
