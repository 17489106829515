import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../apis/v1/Auth";
import Loader from "../components/Loader";

export default function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = localStorage.getItem("access_token");
    if (!isAuth) {
      checkAuth();
    } else {
      navigate("/companies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader
      content={
        window.location.search.length > 0
          ? "logging out"
          : "You are requested to login"
      }
      showLogo={true}
    />
  );
}
