import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { PermissionsResponse } from "../../../../interfaces/Permissions";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../hooks/useStoreHook";

interface AssignPermissionProps {
  permissions: PermissionsResponse[];
  handleUpdatePermissions: (permission_uuid: string) => void;
  hasPermission: (permission_uuid: string) => boolean;
  handleSubmit: () => void;
}

const PermissionItem = ({
  permission,
  checked,
  onChange,
}: {
  permission: PermissionsResponse;
  checked: boolean;
  onChange: () => void;
}) => (
  <Grid item xs={12} sm={6} md={4} lg={4}>
    <FormControlLabel
      sx={{ alignItems: "flex-start" }}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": `controlled-${permission.uuid}` }}
          sx={{ alignItems: "flex-start" }}
        />
      }
      label={
        <Box>
          <Typography variant="subtitle1">{permission.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {permission.description}
          </Typography>
        </Box>
      }
    />
  </Grid>
);

const AssignPermission: React.FC<AssignPermissionProps> = ({
  permissions,
  handleUpdatePermissions,
  hasPermission,
  handleSubmit
}) => {
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  return (
    <Box my={2}>
      <Typography component="h4" variant="h6">
        Permissions Assignments
      </Typography>
      <Grid container spacing={3} py={3}>
        {permissions?.map((permission) => (
          <PermissionItem
            key={permission.uuid}
            permission={permission}
            checked={hasPermission(permission.uuid)}
            onChange={() => handleUpdatePermissions(permission.uuid)}
          />
        ))}
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} sm={6} md={3}>
          <LoadingButton
            loading={isLoading}
            variant={"contained"}
            sx={{ width: "100%" }}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant={"contained"}
            onClick={() => navigate(-1)}
            color="error"
            sx={{ width: "100%" }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssignPermission;
