import { Feature } from "../../../interfaces/Features";
import { axiosInstance } from "../../config/axiosInstance";
import { AxiosPromise } from "axios";

const generateFeatureURL = (service_uuid: string) => {
  return `payment/v1/services/${service_uuid}/features`;
};

export const createFeature = (
  service_uuid: string,
  data: Omit<Feature, "uuid">,
): AxiosPromise<Feature[]> => {
  return axiosInstance.post(generateFeatureURL(service_uuid), data);
};

export const getFeatures = (service_uuid: string): AxiosPromise<Feature[]> => {
  return axiosInstance.get(generateFeatureURL(service_uuid));
};

export const getFeatureDetails = (
  service_uuid: string,
  feature_uuid: string,
): AxiosPromise<Feature> => {
  return axiosInstance.get(
    `${generateFeatureURL(service_uuid)}/${feature_uuid}`,
  );
};

export const editFeature = (
  service_uuid: string,
  feature_uuid: string,
  data: Omit<Feature, "uuid">,
): AxiosPromise<Feature> => {
  return axiosInstance.patch(
    `${generateFeatureURL(service_uuid)}/${feature_uuid}`,
    data,
  );
};

export const deleteFeature = (
  service_uuid: string,
  feature_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateFeatureURL(service_uuid)}/${feature_uuid}`,
  );
};
