/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, CardHeader } from "@mui/material";
import CompanyCameraOverrides from "../Settings/v1/CompanyOverrides/ListTable";
import { useEffect, useState } from "react";
import {
  editCompanyCameraSettingsOverrides,
  getCompanyCameraSettingsOverrides,
} from "../../apis/v1/Settings/CameraCompanyOverrides";
import { useParams } from "react-router-dom";
import { getCameraDefaultSettings } from "../../apis/v1/Settings/CameraDefaults";
import { parseOverrideValue } from "../../utilis/snackbar";

export default function CameraSettingsOverridesList() {
  const [companyCameraOverrides, setCompanyCameraOverrides] = useState(null);
  const [defaultCameraSettings, setDefaultCameraSettings] = useState(null);
  const params = useParams();

  useEffect(() => {
    getCameraDefaultSettings().then((res) => {
        setDefaultCameraSettings(res.data);
    });
    getCompanyOverridesRequest();
  }, [params.company_uuid]);

  const getCompanyOverridesRequest = () => {
    getCompanyCameraSettingsOverrides(params.company_uuid).then((res) => {
        setCompanyCameraOverrides(res.data);
    });
  };

  const handleEditOverride = (company_override_uuid, value) => {
    const override_value = parseOverrideValue(value);
    editCompanyCameraSettingsOverrides(
      params.company_uuid,
      company_override_uuid,
      {
        value: override_value,
      },
    ).then(() => {
      getCompanyOverridesRequest();
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Camera Setting Overrides"
        subheader="A company camera setting override, overrides the default camera settings at a company level"
      />

      <CardContent>
        <CompanyCameraOverrides
          getCompanyOverridesRequest={getCompanyOverridesRequest}
          defaultCameraSettings={defaultCameraSettings}
          companyCameraOverrides={companyCameraOverrides}
          handleEditOverride={handleEditOverride}
        />
      </CardContent>
    </Card>
  );
}
