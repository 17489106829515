import { Button, Grid, TextField } from "@mui/material";
import { integrationTypes, node_types } from "../../utilis/enum";
import MUISelect from "../Controls/Select";
import { useState } from "react";
import { NotificationRule, RuleFilters } from "../../interfaces/Notifications";

interface RuleFiltersProps {
  filters: RuleFilters,
  setFilters: React.Dispatch<RuleFilters>,
  setRenderedRules: React.Dispatch<NotificationRule[]>,
  originalRules: NotificationRule[];
  ruleTypes: string[];
}

export default function RulesFilters({
  filters,
  setFilters,
  setRenderedRules,
  originalRules,
  ruleTypes,
}: RuleFiltersProps) {
  const [showReset, setShowReset] = useState(false);
  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    setShowReset(true);
    let filteredRules = originalRules.filter((rule) => {
      if (filters.node_name) {
        return rule.node.name
          .toLowerCase()
          .includes(filters.node_name.toLowerCase());
      }
      return rule;
    });
    filteredRules = filteredRules.filter((rule) => {
      if (filters.node_type) {
        return rule.node.type === filters.node_type;
      }
      return rule;
    });
    filteredRules = filteredRules.filter((rule) => {
      if (filters.integration_type) {
        return rule.integrations.types.includes(filters.integration_type);
      }
      return rule;
    });
    filteredRules = filteredRules.filter((rule) => {
      if (filters.rule_type) {
        return rule.type === filters.rule_type;
      }
      return rule;
    });
    setRenderedRules(filteredRules);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Node Name"
          id="node_name"
          name="node_name"
          onChange={handleChange}
          size="small"
          fullWidth
          margin="normal"
          value={filters?.node_name || ""}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <MUISelect
          label="Node Types"
          labelId="node_type"
          id="node_type"
          name="node_type"
          handleChange={handleChange}
          list={Object.values(node_types)}
          margin="normal"
          value={filters?.node_type || ""}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <MUISelect
          label="Rule Type"
          labelId="rule_type"
          id="rule_type"
          name="rule_type"
          handleChange={handleChange}
          list={ruleTypes}
          margin="normal"
          value={filters?.rule_type || ""}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <MUISelect
          label="Integration Types"
          labelId="integration_type"
          id="integration_type"
          name="integration_type"
          handleChange={handleChange}
          list={Object.values(integrationTypes)}
          margin="normal"
          value={filters?.integration_type || ""}
        />
      </Grid>
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={4}
      >
        <Button variant="outlined" onClick={handleFilter}>
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setFilters(null);
              setRenderedRules(originalRules);
              setShowReset(false);
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
