import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteIncidentCause } from "../../apis/v1/IncidentCause";
import { IncidentCause } from "../../interfaces/IncidentCause";

interface PropsType {
  incident_cause_uuid: string;
  incident_causes: IncidentCause[];
  set_incident_causes: (incident_causes: IncidentCause[]) => void;
  company_uuid: string;
}

export default function DeleteIncidentCause({
  incident_cause_uuid,
  incident_causes,
  set_incident_causes,
  company_uuid,
}: Readonly<PropsType>) {
  const [openDialog, setOpenDialog] = useState(false);

  const getIncidentCausesWithoutDeleted = (): IncidentCause[] => {
    return incident_causes.filter(
      (incident_cause: IncidentCause) =>
        incident_cause.uuid !== incident_cause_uuid,
    );
  };

  const submitDeleteIncidentCause = () => {
    deleteIncidentCause(company_uuid, incident_cause_uuid).then((res) => {
      if (res) {
        const labelsListWithoutDeleted: IncidentCause[] =
          getIncidentCausesWithoutDeleted();
        set_incident_causes(labelsListWithoutDeleted);
        setOpenDialog(false);
        showSnackbar("Deleted Successfully", "success");
      }
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Company Incident Cause"
        dialogBody="Are you sure you want to delete this incident cause?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteIncidentCause()}
      />
    </>
  );
}
