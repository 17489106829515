export default function DividerOverrides() {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#ededed",
        },
      },
    },
  };
}
