/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  createTemplate,
  getTemplateList,
} from "../../apis/v1/Camera/CompanyCameraTemplates";
import { getOcuconTemplateList } from "../../apis/v1/Camera/OcuconCameraTemplates";

import AddCompanyTemplateForm from "../../components/Cameras/v1/CompanyTemplate/AddTemplateForm";
import PagesHeader from "../../components/PagesHeader";
import { useNavigate, useParams } from "react-router-dom";
import { showSnackbar } from "../../utilis/snackbar";
import { getAuthTypes } from "../../apis/v1/Camera/Camera";
import { removeWhiteSpace } from "../../utilis/shared";

export default function AddCompanyCameraTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const [base_templates, set_base_templates] = useState({});
  const [authTypes, setAuthTypes] = useState([]);

  useEffect(() => {
    const is_active = true;
    getAuthTypes().then((res) => res && setAuthTypes(res.data));
    getTemplateList(params.company_uuid, is_active).then((res) => {
      res &&
        getOcuconTemplateList(is_active).then((resp) => {
          resp &&
            set_base_templates({
              ocucon_templates: [
                ...resp.data.map((ocucon_temp) => {
                  return {
                    ...ocucon_temp,
                    type: "ocucon_template",
                  };
                }),
              ],
              company_templates: [
                ...res.data.map((comp_temp) => {
                  return {
                    ...comp_temp,
                    type: "company_template",
                  };
                }),
              ],
            });
        });
    });
  }, []);

  const handleSubmitForm = (values) => {
    const company_uuid = params.company_uuid;
    createTemplate({
      ...values,
      username: values.username ? removeWhiteSpace(values.username) : null,
      password: values.password ? removeWhiteSpace(values.password) : null,
      company_uuid,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Add Company Camera Connection Template" />

      <AddCompanyTemplateForm
        authTypes={authTypes}
        handleSubmitForm={handleSubmitForm}
        base_templates={base_templates}
      />
    </>
  );
}
