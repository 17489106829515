import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PagesHeader from "../../components/PagesHeader";
import EditIncidentCauseForm from "../../components/IncidentCauses/EditIncidentCauseForm";
import {
  editIncidentCause,
  getIncidentCause,
} from "../../apis/v1/IncidentCause";
import { showSnackbar } from "../../utilis/snackbar";
import { IncidentCause } from "../../interfaces/IncidentCause";
import { getCompanyIncidentClasses } from "../../apis/v1/DetectionZone/CompanyIncidentClasses";
import { GIncidentClass } from "../../interfaces/IncidentClass";

export default function EditIncidentCause() {
  const [incidentCause, setIncidentCause] = useState<IncidentCause>();
  const [incidentClasses, setIncidentClasses] = useState<GIncidentClass[]>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(params.company_uuid){
      getIncidentCause(params.company_uuid, params.incident_cause_uuid).then(
        (res) => {
          setIncidentCause(res.data);
        },
      );
      getCompanyIncidentClasses(params.company_uuid).then((res) => {
        setIncidentClasses(res?.data);
      });
    }
  },[params.company_uuid]);

  const handleSubmitForm = (values) => {
    const edited_data = {
      text: values.text,
      order: values.order,
      is_hidden: values.is_hidden,
    };
    editIncidentCause(
      params.company_uuid,
      params.incident_cause_uuid,
      edited_data,
    ).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };
  return (
    <>
      <PagesHeader title="Edit Incident Cause" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <EditIncidentCauseForm
            incident_cause={incidentCause}
            handleSubmitForm={handleSubmitForm}
            incident_classes={incidentClasses}
          />
        </Grid>
      </Grid>
    </>
  );
}
