import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import OcuconCameraConnectionTemplates from "../../components/Cameras/v1/OcuconCameraConnectionTemplate/OcuconCameraConnectionTemplates";
import {
  activateOcuconTemplate,
  deactivateOcuconTemplate,
  getOcuconTemplateList,
} from "../../apis/v1/Camera/OcuconCameraTemplates";

export default function OcuconTemplates() {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const getTemplatesRequest = () => {
    getOcuconTemplateList().then((res) => {
      if (res) {
        setTemplates(res.data);
      }
    });
  };

  useEffect(() => {
    getTemplatesRequest();
  }, []);

  const handleChangeStatus = (uuid, is_active) => {
    if (is_active) {
      deactivateOcuconTemplate(uuid).then((res) => {
        if (res) {
          getTemplatesRequest();
        }
      });
    } else {
      activateOcuconTemplate(uuid).then((res) => {
        if (res) {
          getTemplatesRequest();
        }
      });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Ocucon Camera Connection Templates"
        subheader="List of ocucon camera connection templates. These templates can be used on all companies cameras as a starting point when creating a connection"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate("/connection/ocucon-camera-connection-template/add")
          }
          data-testid="addTemplate"
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <OcuconCameraConnectionTemplates
          templates={templates}
          setTemplates={setTemplates}
          handleChangeStatus={handleChangeStatus}
        />
      </CardContent>
    </Card>
  );
}
