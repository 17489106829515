import PagesHeader from "../../components/PagesHeader";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddChildNodeForm from "../../components/ChildNodes/AddChildNodeForm";
import { Grid } from "@mui/material";
import { createCompanyChildNode } from "../../apis/v1/OrgStructure";
import { showSnackbar } from "../../utilis/snackbar";
import { OrgStructureNodePayload } from "../../interfaces/OrgStructure";

export default function AddChildNode() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const handleSubmit = (values: Omit<OrgStructureNodePayload, 'parent_node_uuid'>) => {
    createCompanyChildNode(params.company_uuid, {
      ...values,
      parent_node_uuid: params.node_uuid,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };
  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            ...location.state,
          ],
          current: "Add child node",
        }}
      />
      <PagesHeader title="Add Child Node" />
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <AddChildNodeForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
