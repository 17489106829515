import { Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import AllUsersTable from "../../components/Users/v1/AllUsers/AllUsersListTable";
import AllUsersFilter from "../../components/Users/v1/AllUsers/AllUsersFilters";
import { getCustomers } from "../../apis/v1/UserManagement";
import { getCompanies } from "../../apis/v1/Companies";
import { getAllRootNodes } from "../../apis/v1/OrgStructure";
import Loader from "../../components/Loader";

const fetchAllData = async (setUsers, setRootNodes, setCompanies, filters) => {
  try {
    const [usersResponse, rootNodesResponse, companiesResponse] = await Promise.all([
      getCustomers(filters),
      getAllRootNodes(),
      getCompanies(),
    ]);
    setUsers(usersResponse.data);
    setRootNodes(rootNodesResponse.data);
    setCompanies(companiesResponse.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default function AllUsersList() {
  const [users, setUsers] = useState([]);
  const [rootNodes, setRootNodes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    email: null,
    first_name: null,
    last_name: null,
  });
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      await fetchAllData(setUsers, setRootNodes, setCompanies, filters);
    } catch (err) {
      console.log("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  return (
    <Card variant="outlined">
      <CardHeader title="Users List" subheader="A list of all users" />
      <CardContent>
        <AllUsersFilter setFilters={setFilters} />
      </CardContent>
      <CardContent>
        {loading && <Loader height="30vh" content="Loading users, please wait" /> }
        {users && companies && !loading && (
          <AllUsersTable
            users={users}
            rootNodes={rootNodes}
            companies={companies}
          />
        )}
      </CardContent>
    </Card>
  );
}