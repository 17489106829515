/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import DetailsCard from "../../components/Sites/v1/DetailsCard";
import SiteCameraOverrides from "../../components/Settings/v1/SiteOverrides/ListTable";
import { getCompanyCameraSettingsOverrides } from "../../apis/v1/Settings/CameraCompanyOverrides";
import { getCameraDefaultSettings } from "../../apis/v1/Settings/CameraDefaults";
import { getSiteCameraSettingsOverrides } from "../../apis/v1/Settings/CameraSiteOverrides";
import { getSiteDetails } from "../../apis/v1/Sites";
import MUIBreadcrumb from "../../components/Breadcrumb";
import CamerasListCard from "../../components/Cameras/v1/Camera/CamerasListCard";

export default function SiteDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [cameras, setCameras] = useState([]);
  const [site, setSite] = useState(null);
  const [siteCameraOverrides, setSiteCameraOverrides] = useState([]);
  const [companyCameraOverrides, setCompanyCameraOverrides] = useState(null);
  const [defaultCameraSettings, setDefaultCameraSettings] = useState(null);

  const getCompanyOverridesRequest = () => {
    getCompanyCameraSettingsOverrides(params.company_uuid).then((res) => {
      if (res) {
        setCompanyCameraOverrides(res.data);
      }
    });
  };

  const getSiteOverridesRequest = () => {
    getSiteCameraSettingsOverrides(params.company_uuid, params.site_uuid).then(
      (res) => {
        if (res) {
          setSiteCameraOverrides(res.data);
        }
      },
    );
  };

  const getSiteDetailsRequest = () => {
    getSiteDetails(params.site_uuid, params.company_uuid).then((res) => {
      if (res) {
        setSite(res.data);
        setCameras(res.data.cameras);
      }
    });
  };

  useEffect(() => {
    getSiteDetailsRequest();
    getCameraDefaultSettings().then((res) => {
      if (res) {
        setDefaultCameraSettings(res.data);
      }
    });
    getCompanyOverridesRequest();
    getSiteOverridesRequest();
  }, [params.site_uuid]);



  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
            },
          ],
          current: site?.name,
        }}
      />
      <Card variant="outlined">
        <CardHeader title="Site Details" subheader="Details about this site" />
        <CardContent>
          <Button
            variant={"outlined"}
            onClick={() =>
              navigate(
                `/company/${params.company_uuid}/node/${params.node_uuid}/site/${params.site_uuid}/edit`,
              )
            }
          >
            Edit
          </Button>
        </CardContent>
        <CardContent>{site && <DetailsCard site={site} />}</CardContent>
      </Card>
      <CamerasListCard getSiteDetailsRequest={getSiteDetailsRequest} cameras={cameras} site={site}/>
      <Card variant="outlined">
        <CardHeader
          title="Camera Site Settings Overrides"
          subheader="A list of site overrides"
        />
        <CardContent>
          {defaultCameraSettings && (
            <SiteCameraOverrides
              overrides={siteCameraOverrides}
              defaultCameraSettings={defaultCameraSettings}
              companyCameraOverrides={companyCameraOverrides}
              getSiteOverridesRequest={getSiteOverridesRequest}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}
