import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";

export default function AttributesTable({ attributes, attribute_type }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TableContainer sx={{ borderTop: "1px solid #e7e7e7", mb: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", width: "50%" }}>
                  Key
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "50%" }}>
                  Value
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes.some((attr) => attr.type === attribute_type) ? (
                attributes.map((attr, index) => {
                  if (attr.type === attribute_type) {
                    return (
                      <TableRow key={index} data-testid="cameraConnection-row">
                        <TableCell>{attr.key}</TableCell>
                        <TableCell>{attr.value ? attr.value : "-"}</TableCell>
                      </TableRow>
                    );
                  }
                  return <Fragment key={index}></Fragment>;
                })
              ) : (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
