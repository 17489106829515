import { useParams } from "react-router-dom";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { EditText } from "react-edit-text";
import MUITable from "../../../Table";
import DeleteSettingsOverrides from "./DeleteSettingsOverrides";
import {
  addSiteCameraSettingsOverrides,
  editSiteCameraSettingsOverrides,
} from "../../../../apis/v1/Settings/CameraSiteOverrides";
import "react-edit-text/dist/index.css";
import { parseOverrideValue } from "../../../../utilis/snackbar";

export default function SiteCameraOverrides({
  overrides,
  getSiteOverridesRequest,
  defaultCameraSettings,
  companyCameraOverrides,
}) {
  const params = useParams();

  const getSiteOverride = (default_uuid) => {
    const site_override = overrides.find(
      (override) => override.default_camera_setting_uuid === default_uuid,
    );
    return {
      site_override_value: site_override?.value?.toString(),
      site_override_uuid: site_override?.uuid,
      has_site_override: site_override ? true : false,
    };
  };

  const handleOverridesChange = (row_uuid, value, override_uuid) => {
    const override_value = parseOverrideValue(value);
    if (override_uuid) {
      editSiteCameraSettingsOverrides(
        params.company_uuid,
        params.site_uuid,
        override_uuid,
        {
          value: override_value,
        },
      ).then((res) => {
        res && getSiteOverridesRequest();
      });
    } else {
      addSiteCameraSettingsOverrides(params.company_uuid, params.site_uuid, {
        value: override_value,
        default_camera_setting_uuid: row_uuid,
      }).then((res) => {
        res && getSiteOverridesRequest();
      });
    }
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "description",
          name: "Description",
        },
        {
          id: "default_value",
          name: "Default",
        },
        {
          id: "company_overrides",
          name: "Company override",
        },
        {
          id: "site_overrides",
          name: "Site override",
        },
      ]}
      hasPagination={true}
      data={defaultCameraSettings}
      tableBodyRow={(row: any) => {
        const site_has_override = getSiteOverride(row.uuid).has_site_override;
        const site_override_value =
          getSiteOverride(row.uuid).site_override_value || "";
        const site_override_uuid = getSiteOverride(row.uuid).site_override_uuid;
        return (
          <TableRow key={row.uuid}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <Tooltip title={row?.description} placement="top-start">
                <p className="truncate_text">{row?.description}</p>
              </Tooltip>
            </TableCell>
            <TableCell>{row?.default_value?.toString()}</TableCell>
            <TableCell>
              {companyCameraOverrides &&
                companyCameraOverrides
                  .find(
                    (company_override) =>
                      company_override.default_camera_setting_uuid === row.uuid,
                  )
                  ?.value?.toString()}
            </TableCell>
            <TableCell>
              <Box display={"flex"} alignItems="center" height={24}>
                {/* {row.data_type === 'FLOAT' || row.data_type === 'INT' ?
                  <EditText
                    style={{ width: "150px" }}
                    type={"number"}
                    data-testid={"override_value"}
                    name="override_value"
                    defaultValue={site_override_value}
                    onSave={({ value }) => handleOverridesChange(row.uuid, value, site_override_uuid, true)} />
                  :
                  <Box width="150px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ py: 0 }}
                          onChange={(e) => { handleOverridesChange(row.uuid, e.target.checked, site_override_uuid, false) }}
                          checked={site_has_override ? JSON.parse(site_override_value) : false} />}
                      label={site_override_value}
                    />
                  </Box>
                } */}
                <EditText
                  style={{ width: "150px" }}
                  data-testid={"override_value"}
                  name="override_value"
                  defaultValue={site_override_value}
                  onSave={({ value }) =>
                    handleOverridesChange(row.uuid, value, site_override_uuid)
                  }
                />
                {site_has_override && (
                  <Box display={"flex"} alignItems="center">
                    <DeleteSettingsOverrides
                      overrides_settings_uuid={site_override_uuid}
                      onDelete={() => getSiteOverridesRequest()}
                    />
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
