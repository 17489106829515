import { useNavigate } from "react-router-dom";
import { Field, FieldArray, Formik, getIn } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import {
  ConsumableTierPayload,
  DefaultPrice,
  TierPayload,
  TierResponse,
} from "../../interfaces/Tier";
import MultiSelect from "../Controls/MultiSelect";
import MUISelect from "../Controls/Select";
import { Consumable } from "../../interfaces/Consumable";
import { Feature } from "../../interfaces/Features";
import DeleteIcon from "@mui/icons-material/Delete";
import { stringifyAmountNumber, UNLIMITED_LICENSES } from "../../utilis/shared";
import { Company } from "../../interfaces/Company";
import PricesForm from "./PricesForm";
import { Country } from "../../interfaces/Address";

interface EditTierFormProps {
  handleSubmit: (values: TierPayload) => void;
  tier: TierResponse;
  consumables: Consumable[];
  features: Feature[];
  companies: Company[];
  countries: Country[];
}

export default function EditTierForm({
  handleSubmit,
  tier,
  consumables,
  features,
  companies,
  countries,
}: Readonly<EditTierFormProps>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const initialValue: TierPayload = {
    name: tier?.name || "",
    default_price: {
      unit_amount: tier?.default_price.unit_amount / 100 || 0,
      currency: tier?.default_price.currency || "",
      interval: tier?.default_price.interval || "",
      // currency_options: dictToList(tier?.default_price.currency_options || []),
      unit_price_overrides:
        tier?.default_price?.unit_price_overrides.map((override: any) => {
          const amount = override.unit_price?.amount / 100;
          return {
            ...override,
            unit_price: {
              ...override.unit_price,
              amount,
            },
          };
        }) || [],
    },
    license_count: tier?.license_count || 0,
    unlimited_licences: tier?.license_count === UNLIMITED_LICENSES, // if license count is UNLIMITED_LICENSES, then it's unlimited
    description: tier?.description || "",
    features: tier?.features?.map((feature) => feature.uuid) || [],
    consumables:
      tier?.consumables.length > 0
        ? tier?.consumables
        : [
            {
              uuid: "",
              amount: 0,
            },
          ],
    custom_tier: tier?.companies?.length > 0,
    companies: tier?.companies || [],
  };
  const currencyOptionItemSchema = yup.object().shape({
    currency: yup.string(),
    unit_amount: yup.number().min(0, "Must be positive"),
  });

  const unitPriceOverridesSchema = yup.object().shape({
    country_codes: yup.array().of(yup.string()),
    unit_price: yup.object().shape({
      amount: yup.number().min(0, "Must be positive"),
      currency_code: yup.string(),
    }),
  });

  const defaultPriceSchema: yup.SchemaOf<DefaultPrice> = yup.object().shape({
    unit_amount: yup.number().required("Required").min(0, "Must be positive"),
    currency: yup.string().required("Required"),
    interval: yup.string().required("Required").oneOf(["month", "year"]),
    currency_options: yup.array().of(currencyOptionItemSchema).nullable(),
    unit_price_overrides: yup.array().of(unitPriceOverridesSchema),
  });

  const consumablesSchema: yup.SchemaOf<ConsumableTierPayload> = yup
    .object()
    .shape({
      uuid: yup.string().nullable(),
      amount: yup.number().when("uuid", {
        is: (uuid: string | null) => !!uuid,
        then: yup.number().positive("Must be positive"),
        otherwise: yup.number().nullable(),
      }),
    });

  const licenseCountSchema: yup.SchemaOf<number> = yup
    .number()
    .when("unlimited_licences", {
      is: false,
      then: yup
        .number()
        .required("Required")
        .positive("Must be positive")
        .min(1, "Must be greater than or equal 1"),
      otherwise: yup.number().nullable(),
    });

  const companiesSchema = yup
    .array()
    .of(yup.string().required("Required"))
    .when("custom_tier", {
      is: true, // When custom_tier is true
      then: yup
        .array()
        .of(yup.string().required("Required"))
        .min(1, "At least one company should be selected")
        .required("Required"),
      otherwise: yup.array().notRequired(),
    });

  const schema: yup.SchemaOf<any> = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    license_count: licenseCountSchema,
    unlimited_licences: yup.boolean(),
    features: yup.array(yup.string()).nullable(),
    consumables: yup.array().of(consumablesSchema),
    default_price: defaultPriceSchema.required(),
    custom_tier: yup.boolean().required(),
    companies: companiesSchema,
  });

  const updateLicenseCountValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: number) => void,
  ) => {
    e.target.checked
      ? setFieldValue("license_count", UNLIMITED_LICENSES)
      : setFieldValue("license_count", 0);
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={(values: TierPayload) =>
        handleSubmit({
          ...values,
          default_price: {
            ...values.default_price,
            unit_amount: values.default_price.unit_amount * 100,
            unit_price_overrides: values.default_price.unit_price_overrides.map(
              (unit_price_override: any) => {
                return {
                  ...unit_price_override,
                  unit_price: {
                    ...unit_price_override.unit_price,
                    amount: stringifyAmountNumber(unit_price_override.unit_price.amount),
                  },
                };
              },
            ),
          },
        })
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="tier_name"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description*"
            fullWidth
            margin="normal"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description && errors.description
            }
            data-testid="tier_description"
            size="small"
            multiline
            rows={5}
          />
          <TextField
            type={values.unlimited_licences ? "text" : "number"}
            id="license_count"
            label="License count*"
            fullWidth
            margin="normal"
            name="license_count"
            disabled={Boolean(values.unlimited_licences)}
            error={
              values.unlimited_licences === false
                ? Boolean(errors.license_count && touched.license_count)
                : null
            }
            helperText={
              !values.unlimited_licences &&
              errors.license_count &&
              touched.license_count &&
              errors.license_count
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              values.unlimited_licences ? "Unlimited" : values.license_count
            }
            data-testid="license_count"
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="unlimited_licences"
                data-testid="unlimited_licences"
                onChange={(e) => {
                  handleChange(e);
                  updateLicenseCountValue(e, setFieldValue);
                }}
                onBlur={handleBlur}
                checked={values.unlimited_licences}
              />
            }
            label="Unlimited licences"
          />
          <Field name="features">
            {({ form, field, meta }) => (
              <MultiSelect
                label="Features"
                dataTestid={"features"}
                meta={meta}
                form={form}
                field={field}
                list={features}
                uuid="uuid"
                value="name"
                sx={{ mt: 2 }}
              />
            )}
          </Field>
          <FieldArray name="consumables">
            {({ push, remove }) => {
              return values?.consumables.map((item, index) => {
                const consumable_uuid = `consumables[${index}].uuid`;
                const consumable_amount = `consumables[${index}].amount`;
                const consumable_uuid_value = getIn(values, consumable_uuid);
                const consumable_amount_value = getIn(
                  values,
                  consumable_amount,
                );
                const consumable_amount_error = getIn(
                  errors,
                  consumable_amount,
                );
                return (
                  <Grid
                    container
                    alignItems={"start"}
                    spacing={2}
                    sx={{ pt: 2, pb: 1 }}
                    key={consumable_uuid_value}
                  >
                    <Grid item xs={5}>
                      <MUISelect
                        value={consumable_uuid_value}
                        label={"Consumable"}
                        dataTestid={"consumable"}
                        name={`consumables[${index}].uuid`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        keyItem="uuid"
                        keyValue="name"
                        list={consumables}
                        labelId={"label-consumable"}
                        id={"select-consumable"}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="number"
                        id="consumable_amount"
                        label="Amount"
                        fullWidth
                        name={`consumables[${index}].amount`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={consumable_amount_value}
                        size="small"
                        error={Boolean(consumable_amount_error)}
                        helperText={consumable_amount_error}
                      />
                    </Grid>
                    <Grid item xs={2} display={"flex"} alignItems={"center"}>
                      {(index > 0 || values.consumables.length > 1) && (
                        <IconButton
                          size="small"
                          data-testid="deleteSettings"
                          aria-label="delete"
                          sx={{ mt: "0.3em" }}
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => push({ uuid: "", amount: "" })}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                );
              });
            }}
          </FieldArray>
          <Box component={"h4"} mb={0}>
            Price
          </Box>
          <small> Note: this price will be the default for any purchase.</small>
          <Grid
            container
            spacing={2}
            alignItems={"flex-start"}
            sx={{ pt: 2, pb: 1 }}
          >
            <Grid item xs={4}>
              <MUISelect
                error={
                  errors?.default_price?.currency &&
                  touched?.default_price?.currency &&
                  errors?.default_price?.currency
                }
                value={values?.default_price?.currency.toUpperCase()}
                label={"Currency"}
                dataTestid={"currency"}
                required
                name="default_price.currency"
                handleChange={handleChange}
                handleBlur={handleBlur}
                list={countries?.map((country) => country.currency)}
                labelId={"label-currency"}
                id={"select-currency"}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                id="unit_amount"
                label="Unit Amount*"
                fullWidth
                name="default_price.unit_amount"
                error={Boolean(
                  errors?.default_price?.unit_amount &&
                    touched?.default_price?.unit_amount,
                )}
                helperText={
                  Boolean(
                    errors?.default_price?.unit_amount &&
                      touched?.default_price?.unit_amount,
                  ) && errors?.default_price?.unit_amount
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.default_price.unit_amount}
                size="small"
              />
            </Grid>

            <Grid item xs={4}>
              <MUISelect
                error={
                  errors?.default_price?.interval &&
                  touched?.default_price?.interval &&
                  errors?.default_price?.interval
                }
                value={values.default_price?.interval}
                label={"Interval"}
                dataTestid={"intrval"}
                required
                name="default_price.interval"
                handleChange={handleChange}
                handleBlur={handleBlur}
                list={["month", "year"]}
                labelId={"label-interval"}
                id={"select-interval"}
              />
            </Grid>
          </Grid>
          <Box component={"h4"} my={1}>
            Additional Currency options
          </Box>
          <small>
            {" "}
            Note: Any additinal currencies will be added to help the customer
            pay in his local currency.
          </small>
          <PricesForm
            values={values}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            countries={countries}
            setFieldValue={setFieldValue}
          />

          <Box component={"h4"} mb={0}>
            Custom Tier
          </Box>
          <small>
            {" "}
            Note: Checking this box will hide this tier from the tiers select
            screen and will only be visiable to the companies that it is
            attached to.
          </small>
          <FormControlLabel
            control={
              <Checkbox
                name="custom_tier"
                data-testid="custom_tier"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.custom_tier}
              />
            }
            sx={{ display: "block" }}
            label="Custom tier"
          />

          {values.custom_tier && (
            <Field name="companies">
              {({ form, field, meta }: any) => (
                <MultiSelect
                  label="Companies"
                  dataTestid={"companies"}
                  meta={meta}
                  form={form}
                  field={field}
                  list={companies}
                  uuid="uuid"
                  value="name"
                  sx={{ mt: 2 }}
                />
              )}
            </Field>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
