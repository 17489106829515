/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getCustomers } from "../../apis/v1/UserManagement";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { Card, CardContent, CardHeader } from "@mui/material";
import UserDetailsCard from "../../components/Users/v1/UserDetails/UserDetailsCard";
import { getCompanyNodes } from "../../apis/v1/OrgStructure";
import { Customer } from "../../interfaces/User";
import { getCompaniesDetails } from "../../apis/v1/Companies";
import { Company } from "../../interfaces/Company";

export default function UserDetails() {
  const params = useParams();
  const location = useLocation();
  const [customer, setCustomer] = useState<Customer>();
  const [company, setCompany] = useState<Company>();

  const getUserDetails = () => {
    let org_structure_assignments = [];
    getCustomers({ company_uuid: params.company_uuid }).then((res) => {
      if (res) {
        const customerDetails = res.data.find(
          (customer) => customer.id === params.user_id,
        );
        if (customerDetails.org_structure_assignments?.length > 0) {
          customerDetails.org_structure_assignments.map((org_uuid) => {
            return getCompanyNodes(params.company_uuid, org_uuid).then(
              (res) => {
                org_structure_assignments.push({
                  uuid: res.data.uuid,
                  name: res.data.name,
                });
                setCustomer({
                  ...customerDetails,
                  org_structure_assignments,
                });
              },
            );
          });
        } else {
          setCustomer({
            ...customerDetails,
          });
        }
      }
    });
  };

  const fetchCompany = async () => {
    try {
      const response = await getCompaniesDetails(params.company_uuid);
      setCompany(response.data);
    } catch (err) {
      console.log("Error while fetching company in user details", err);
    }
  };

  useEffect(() => {
    getUserDetails();
    fetchCompany();
  }, []);

  const renderBreadcrumbData = () => {
    const is_from_company_location = location.state?.company_name;
    const stackList = is_from_company_location
      ? [
          {
            name: "Companies",
            path: "/companies",
          },

          {
            name: location.state.company_name,
            path: location.state?.prevCompanyLocation,
          },
        ]
      : [
          {
            name: "Users",
            path: "/users",
          },
        ];
    return stackList;
  };

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: renderBreadcrumbData(),
          current: customer && customer.username,
        }}
      />
      {customer && (
        <Card variant="outlined">
          <CardHeader
            title="User Details"
            subheader="Details about this user and assigned organizations"
          />
          <CardContent>
            <UserDetailsCard customer={customer} company={company} />
          </CardContent>
        </Card>
      )}
    </>
  );
}
