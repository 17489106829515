import { axiosInstance } from "../config/axiosInstance";
import { Company, CreateCompanyData } from "../../interfaces/Company";
import { Service } from "../../interfaces/Service";
import { AxiosPromise } from "axios";

const COMPANY = "/organisation-management/v1/companies";

export const createCompany = (
  data: CreateCompanyData,
): AxiosPromise<Company> => {
  return axiosInstance.post(COMPANY, data);
};

interface CompanyFilters {
  account_number?: number;
}

export const getCompanies = ({account_number} : CompanyFilters = {}): AxiosPromise<Company[]> => {
  return axiosInstance.get(COMPANY , {
    params: {
      ...(account_number && {account_number})
    }
  });
};

export const getCompaniesDetails = (
  company_uuid: string,
): AxiosPromise<Company> => {
  return axiosInstance.get(`${COMPANY}/${company_uuid}`);
};

export const editCompany = ({
  data,
  company_uuid,
}: {
  data: CreateCompanyData;
  company_uuid: string;
}): AxiosPromise<Company> => {
  return axiosInstance.patch(`${COMPANY}/${company_uuid}`, data);
};

export const deleteCompany = (company_uuid: string) => {
  return axiosInstance.delete(`${COMPANY}/${company_uuid}`);
};

export const getServices = (): AxiosPromise<Service[]> => {
  return axiosInstance.get(`/organisation-management/v1/service`);
};
