export default function ButtonOverrides(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // shared button CSS
          borderRadius: "5px",
          lineHeight: 1.2,
          fontWeight: "500",
          textTransform: "capitalize",
          boxShadow: "none",
          "&:disabled": {
            background: "#E0E0E0",
            borderColor: "#E0E0E0",
            color: "rgba(0, 0, 0, 0.4)",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "white",
            border: "1px solid transparent",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            "&:hover": {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            },
          },
        },
        {
          props: { variant: "contained", color: "error" },
          style: {
            "&:hover": {
              borderColor: theme.palette.error.main,
              color: theme.palette.error.main,
            },
          },
        },
        {
          props: { variant: "contained", color: "success" },
          style: {
            "&:hover": {
              borderColor: theme.palette.success.main,
              color: theme.palette.success.main,
            },
          },
        },
      ],
    },
  };
}
