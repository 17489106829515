import { axiosInstance } from "../config/axiosInstance";
import { AxiosPromise, AxiosResponse } from "axios";
import {
  IncidentCause,
  IncidentCauseCreateArgs,
  IncidentCauseUpdateArgs,
  IncidentClass,
} from "../../interfaces/IncidentCause";

const INCIDENT_SERVICE_BASE = "/incident/v1";
const INCIDENT_SERVICE_BASE_v2 = "/incident/v2";
const INCIDENT_CAUSE_BASE = `${INCIDENT_SERVICE_BASE}/companies`;

export const createIncidentCause = (
  company_uuid: string,
  data: IncidentCauseCreateArgs,
): AxiosPromise<IncidentCause> => {
  return axiosInstance.post(
    `${INCIDENT_CAUSE_BASE}/${company_uuid}/incident-cause`,
    data,
  );
};

export const getIncidentCauseList = (
  company_uuid: string,
): AxiosPromise<IncidentCause[]> => {
  return axiosInstance.get(
    `${INCIDENT_CAUSE_BASE}/${company_uuid}/incident-cause`,
  );
};

export const getIncidentCause = (
  company_uuid: string,
  incident_cause_uuid: string,
): AxiosPromise<IncidentCause> => {
  return axiosInstance.get(
    `${INCIDENT_CAUSE_BASE}/${company_uuid}/incident-cause/${incident_cause_uuid}`,
  );
};

export const editIncidentCause = (
  company_uuid: string,
  incident_cause_uuid: string,
  data: IncidentCauseUpdateArgs,
): AxiosPromise<IncidentCause> => {
  return axiosInstance.patch(
    `${INCIDENT_CAUSE_BASE}/${company_uuid}/incident-cause/${incident_cause_uuid}`,
    data,
  );
};

export const deleteIncidentCause = (
  company_uuid: string,
  incident_cause_uuid: string,
) => {
  return axiosInstance.delete(
    `${INCIDENT_CAUSE_BASE}/${company_uuid}/incident-cause/${incident_cause_uuid}`,
  );
};

export const getIncidentClasses = (): Promise<
  AxiosResponse<{ incident_classes: IncidentClass[] }>
> => {
  return axiosInstance.get(`${INCIDENT_SERVICE_BASE_v2}/classes`);
};
