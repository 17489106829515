import PagesHeader from "../../components/PagesHeader";
import { Grid } from "@mui/material";
import { showSnackbar } from "../../utilis/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import AddConsumableForm from "../../components/Consumables/AddConsumableForm";
import { createConsumable } from "../../apis/v1/Services/Consumables";

export default function AddConsumable() {
  const navigate = useNavigate();
  const params = useParams();

  const handleSubmit = (values) => {
    createConsumable(params.service_uuid, values).then((res) => {
      showSnackbar("Done successfully", "success");
      navigate(-1);
    });
  };

  return (
    <>
      <PagesHeader title="Add Consumable" />
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AddConsumableForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
