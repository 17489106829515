import {
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import MUITable from "../../Table";
import DeleteUser from "./DeleteUser";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import { Customer } from "../../../interfaces/User";
import ResendInviteEmail from "./ResendInviteEmail";

interface UsersListTableProps {
  customers: Array<Customer>;
  setCustomers: (customers: Customer[]) => void;
  hideEdit?: boolean;
  hideDelete?: boolean;
  currentRouteState: {
    company_name: string;
    prevCompanyLocation: string;
  };
  root_node_uuid: string;
}

export default function UsersListTable({
  customers,
  setCustomers,
  hideEdit,
  hideDelete,
  currentRouteState,
  root_node_uuid,
}: UsersListTableProps) {
  const navigate = useNavigate();
  const params = useParams();


  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "username",
          name: "Username",
        },
        {
          id: "email",
          name: "Email",
        },
        {
          id: "emailVerified",
          name: "Verified email",
        },
        {
          id: "assignedWithinOrganisation",
          name: "Assigned within organisation",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={customers}
      tableBodyRow={(row: any) => (
        <TableRow key={row.id} data-testid="user-row">
          <TableCell>{row.username}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.emailVerified ? "Yes" : "No"}</TableCell>
          <TableCell>
            {row.org_structure_assignments.length > 0 ? "Yes" : "No"}
          </TableCell>
          <TableCell align="right">
            {row.emailVerified === false && (
              <ResendInviteEmail userId={row.id}/>
            )}
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/node/${root_node_uuid}/user/${row.id}`,
                  {
                    state: currentRouteState,
                  },
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            {!hideEdit && (
              <IconButton
                size="small"
                onClick={() =>
                  navigate(
                    `/company/${params.company_uuid}/node/${root_node_uuid}/edit-user/${row.id}`,
                  )
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {!hideDelete && (
              <DeleteUser
                id={row.id}
                customers={customers}
                setCustomers={setCustomers}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    />
  );
}
