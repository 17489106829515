/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { Card, CardContent, Grid } from "@mui/material";
import DetectionsSideMenu from "../../components/DetectionsArea/DetectionsSideMenu";
import DetectionsArea from "../../components/DetectionsArea";
import { getCameraImage } from "../../apis/v1/DetectionZone/CameraImage";
import {
  deleteDetectionZone,
  getDetectionZones,
  upsertDetectionZone,
} from "../../apis/v1/DetectionZone/DetectionZones";
import { showSnackbar } from "../../utilis/snackbar";
import { IShape } from "./Interface/Shape";
import { AxiosResponse } from "axios";

interface IDetections {
  isEditable: boolean;
}

export default function Detections({ isEditable }: Readonly<IDetections>) {
  const { company_uuid, camera_uuid, image_uuid } = useParams();
  const [image, setImage] = useState<string>(null);
  const [shapes, setShapes] = useStateWithCallbackLazy<IShape[]>([]);
  const [activeShape, setActiveShape] = useState<any>(null);
  const [activeShapeIndex, setActiveShapeIndex] = useState(null);
  const [isDrawingActive, setIsDrawingActive] = useState<boolean>(null);

  useEffect(() => {
    setActiveShapeIndex(null);
    getDetectionZonesRequest();
  }, []);

  const getDetectionZonesRequest = () => {
    getDetectionZones(company_uuid, camera_uuid, image_uuid).then((res) => {
      setShapes(
        res.data.map((shape) => {
          // REMOVE LAST POINT RETURNED FROM THE API
          const shapePoints = shape.points.slice(0, -1);

          return {
            uuid: shape.uuid,
            incident_class: {
              ...shape.incident_class,
            },
            points: shapePoints.map((point) => {
              return {
                x: point[0],
                y: point[1],
              };
            }),
            zone_number: shape.zone_number
          };
        }),
        () => { },
      );
    });
  }

  useEffect(() => {
    if (activeShape) {
      setActiveShapeIndex(activeShape?.index);
    }
  }, [activeShape]);

  useEffect(() => {
    getCameraImage(company_uuid, camera_uuid, image_uuid).then(
      (res: AxiosResponse<Blob>) => {
        if (res) {
          setImage(window.URL.createObjectURL(res.data));
        }
      },
    );
  }, [company_uuid, camera_uuid, image_uuid]);

  const handleUpdateShapeAfterDelete = (updatedShapes) => {
    setShapes(
      updatedShapes.map((shape: IShape) => {
        return {
          ...shape,
        };
      }),
      () => {
        setActiveShape(null);
        setActiveShapeIndex(null);
      },
    );
  };

  const handleDeleteShape = () => {
    const deletedShapeUUID = shapes[activeShapeIndex].uuid;
    const updatedShapes = shapes.filter(
      (shape: any) => shape.uuid !== activeShape.uuid,
    );
    if (deletedShapeUUID) {
      deleteDetectionZone(
        company_uuid,
        camera_uuid,
        image_uuid,
        deletedShapeUUID,
      ).then((res) => {
        if (res) {
          handleUpdateShapeAfterDelete(updatedShapes);
        }
      });
    } else {
      handleUpdateShapeAfterDelete(updatedShapes);
    }
  };

  const handleSubmitShape = (shape) => {
    const formatedShape = {
      incident_class_uuid: shape.incident_class.uuid,
      points: shape.points.map((point) => [point.x, point.y]),
      ...(shape.uuid && { uuid: shape.uuid }),
    };

    upsertDetectionZone(
      company_uuid,
      camera_uuid,
      image_uuid,
      formatedShape,
    ).then((res) => {
      setIsDrawingActive(false);
      if (res) {
        if (!shape.uuid) {
          showSnackbar("Added Successfully", "success");
          getDetectionZonesRequest();
          const shapeToBeUpdated = shapes.find((shape) => !shape.uuid);
          shapeToBeUpdated["uuid"] = res.data.uuid;
          setShapes([...shapes], () => {
            setActiveShape({
              ...activeShape,
              uuid: res.data.uuid,
            });
          });
        }
      }
    });
  };

  const addNewShape = (incident_class) => {
    setIsDrawingActive(true);
    const savedShapes = shapes.filter((shape) => shape.uuid);
    setShapes(
      [
        ...savedShapes.map((shape: IShape) => shape),
        {
          points: [],
          incident_class,
        },
      ],
      (currentShapes: IShape[]) => {
        setActiveShape({
          index: currentShapes.length - 1,
          ...currentShapes[currentShapes.length - 1],
        });
      },
    );
  };

  return (
    <Grid
      container
      display={"flex"}
      alignItems={"stretch"}
      spacing={2}
      sx={{ minHeight: "calc(100vh - 130px)" }}
    >
      <Grid item xs={12} sm={12} lg={3} xl={2}>
        <DetectionsSideMenu
          shapes={shapes}
          addNewShape={addNewShape}
          setActiveShape={setActiveShape}
          handleDeleteShape={handleDeleteShape}
          activeShapeIndex={activeShapeIndex}
          setActiveShapeIndex={setActiveShapeIndex}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={9} xl={10} sx={{ textAlign: "center" }}>
        <Card sx={{ height: "100%" }} variant="outlined">
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%",
            }}
          >
            {image && (
              <DetectionsArea
                image={image}
                shapes={shapes}
                setShapes={setShapes}
                activeShape={activeShape}
                setActiveShape={setActiveShape}
                handleSubmitShape={handleSubmitShape}
                isDrawingActive={isDrawingActive}
                setIsDrawingActive={setIsDrawingActive}
                isEditable={isEditable}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
