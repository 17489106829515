/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCameraConnectionAttributes,
  getCameraConnectionDetails,
} from "../../apis/v1/Camera/CameraConnection";
import DetailsCard from "../../components/Cameras/v1/CameraConnection/DetailsCard";
import { Attribute, CameraConnectionResponse } from "../../interfaces/Camera";
import MUIBreadcrumb from "../../components/Breadcrumb";

export default function CameraConnectionDetails() {
  const [cameraConnection, setCameraConnection] =
    useState<CameraConnectionResponse>();
  const [attributes, setAttributes] = useState<Array<Attribute>>();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getCameraConnectionDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setCameraConnection(res.data);
      }
    });
    getCameraConnectionAttributes(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setAttributes(res.data.attributes);
      }
    });
  }, []);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state?.company_name,
              path: location.state?.prevCompanyLocation,
              state: location.state,
            },
            {
              name: location.state?.site_name,
              path: location.state?.prevSiteLocation,
              state: location.state,
            },
            {
              name: location.state?.camera_name,
              path: location.state?.prevCameraLocation,
              state: location.state,
            },
          ],
          current: cameraConnection && cameraConnection?.name,
        }}
      />
      <Card variant="outlined">
        <CardHeader
          title="Camera Connections Details"
          subheader="This is a camera connection details page"
        />
        <CardContent>
          {cameraConnection && !cameraConnection.is_active && (
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/connection/${params.connection_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          )}
        </CardContent>
        <CardContent>
          {cameraConnection && attributes && (
            <DetailsCard
              cameraConnection={cameraConnection}
              attributes={attributes}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}
