import { IconButton, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";

interface PoliciesListTableProps {
  policies: any[];
  downloadPolicy: (uuid:string) => void;
}

export default function PoliciesListTable({
  policies,
  downloadPolicy
}: Readonly<PoliciesListTableProps>) {
  const navigate = useNavigate();

  const renderTableBody = (row) => {
    return (
      <TableRow key={row.uuid}>
        <TableCell>{row.type}</TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            onClick={() => navigate(`/edit-policy/${row.uuid}`)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => downloadPolicy(row.uuid)}
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "type",
          name: "Type",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={policies}
      tableBodyRow={(row: any) => renderTableBody(row)}
    />
  );
}
