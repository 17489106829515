import { AxiosPromise } from "axios";
import { Credit, CreditPayload } from "../../../interfaces/Credits";
import { axiosInstance } from "../../config/axiosInstance";

const paymentServiceURL = `payment/v1`;

export const createCredit = (
  service_uuid: string,
  consumable_uuid: string,
  data: CreditPayload,
): AxiosPromise<Credit> => {
  return axiosInstance.post(
    `${paymentServiceURL}/companies/${data.company_uuid}/services/${service_uuid}/consumables/${consumable_uuid}/credits`,
    data,
  );
};

export const getCredits = (
  service_uuid?: string,
  company_uuid?: string,
): AxiosPromise<Credit[]> => {
  return axiosInstance.get(`${paymentServiceURL}/credits`, {
    params: {
      ...(service_uuid && { service_uuid }),
      ...(company_uuid && { company_uuid }),
    },
  });
};
