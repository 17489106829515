import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import {
  Subscription,
  SubscriptionPayload,
} from "../../interfaces/Subscription";
import { Service } from "../../interfaces/Service";
import MUISelect from "../Controls/Select";
import { TierResponse } from "../../interfaces/Tier";
import MUIDateTimePicker from "../Controls/DatePicker";
import GroupedSelect from "../Controls/GroupedSelect";
import { useState } from "react";

interface AddSubscriptionFormProps {
  handleSubmit: (values: Omit<Subscription, "uuid">) => void;
  services: Service[];
  customTiers: TierResponse[];
  tiers: TierResponse[];
}

export default function AddSubscriptionForm({
  handleSubmit,
  services,
  customTiers,
  tiers,
}: Readonly<AddSubscriptionFormProps>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();
  const [filteredTier, setFilteredTier] = useState(tiers);
  const [filteredCustomTier, setFilteredCustomTier] = useState(customTiers);

  const initialValue: SubscriptionPayload = {
    service_uuid: "",
    tier_uuid: "",
    expires_at_utc: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1),
    ),
  };

  const schema: yup.SchemaOf<SubscriptionPayload> = yup.object().shape({
    service_uuid: yup.string().required("Required"),
    tier_uuid: yup.string().required("Required"),
    expires_at_utc: yup.date().required("Required").typeError("Invalid date"),
  });

  const renderServiceValue = (values) => {
    const service = services.find(
      (service) => service.uuid === values.service_uuid,
    );
    return <>{service.name}</>;
  };

  const generateTiersGroup = () => {
    return [
      {
        label: "Custom Tiers",
        list: filteredCustomTier,
      },
      {
        label: "Standard Tiers",
        list: filteredTier,
      },
    ];
  };

  const filterTiers = (service_uuid: string) => {
    setFilteredTier(tiers.filter((tier) => tier.service.uuid === service_uuid));
    setFilteredCustomTier(
      customTiers.filter(
        (customeTier) => customeTier.service.uuid === service_uuid,
      ),
    );
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <MUISelect
            error={
              errors.service_uuid && touched.service_uuid && errors.service_uuid
            }
            value={values.service_uuid}
            label={"Service"}
            required
            name="service_uuid"
            dataTestid="service"
            handleChange={(event) => {
              handleChange(event);
              filterTiers(event.target.value);
            }}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            list={services}
            labelId={"label-service_uuid"}
            id={"select-service_uuid"}
            styles={{ mt: 2 }}
            renderValue={() => renderServiceValue(values)}
          />
          <GroupedSelect
            error={errors.tier_uuid && touched.tier_uuid && errors.tier_uuid}
            value={values.tier_uuid}
            label={"Tier"}
            required
            name="tier_uuid"
            dataTestid="tier"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            groupOptions={generateTiersGroup()}
            labelId={"label-tier_uuid"}
            id={"select-tier_uuid"}
            styles={{ my: 2 }}
          />
          <MUIDateTimePicker
            value={values?.expires_at_utc}
            setValue={(date: any) => {
              setFieldValue("expires_at_utc", date || "");
            }}
            label={"Expires at"}
            required
            errors={touched?.expires_at_utc && errors?.expires_at_utc}
          />
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
