/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getRule, getRuleHistory } from "../../apis/v1/Notifications/Rules";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCompanyNodes } from "../../apis/v1/OrgStructure";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import RuleDetailsCard from "../../components/NotificationsRules/RuleDetailsCard";
import { getRuleIntegrations } from "../../apis/v1/Notifications/Integrations";
import NotificationsRulesIntegrationsTable from "../../components/NotificationsRuleIntegrations";
import RuleHistoryTable from "../../components/NotificationsRules/RuleHistoryTable";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import { RuleHistory } from "../../interfaces/Notifications";

export default function RuleDetails() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [rule, setRule] = useState<any>({});
  const [node, setNode] = useState<OrgStructureNode>();
  const [ruleIntegrations, setRuleIntegrations] = useState([]);
  const [rulesHistory, setRulesHistory] = useState<RuleHistory[]>();

  useEffect(() => {
    getRule(params.company_uuid, params.rule_uuid).then((res) => {
      if (res) {
        setRule(res.data);
        getCompanyNodes(params.company_uuid, res.data.node_uuid).then((res) => {
          setNode(res.data);
        });
      }
    });

    getRuleIntegrations(params.company_uuid, params.rule_uuid).then((res) => {
      if (res) {
        setRuleIntegrations(res.data);
      }
    });

    getRuleHistory(params.company_uuid, params.rule_uuid).then((res) => {
      res && setRulesHistory(res.data);
    });
  }, []);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
            },
          ],
          current: "Rule details",
        }}
      />
      {rule && (
        <Card variant="outlined">
          <CardHeader
            title="Rule Details"
            subheader="Details about this company rule"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/root_node/${params.root_node_uuid}/notifications/rule/${params.rule_uuid}/edit`,
                  {
                    state: {
                      ...location?.state,
                    },
                  },
                )
              }
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <RuleDetailsCard rule={rule} node={node} />
          </CardContent>
        </Card>
      )}
      {ruleIntegrations && (
        <Card variant="outlined">
          <CardHeader
            title="Rule Integrations"
            subheader="list of this rule integrations"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/notifications/rule/${params.rule_uuid}/type/${rule.type}/integration/add`,
                )
              }
            >
              Add
            </Button>
          </CardContent>
          <CardContent>
            <NotificationsRulesIntegrationsTable
              company_uuid={params.company_uuid}
              integrations={ruleIntegrations}
              setIntegrations={setRuleIntegrations}
              rule_type={rule.type}
            />
          </CardContent>
        </Card>
      )}
      {rulesHistory && (
        <Card variant="outlined">
          <CardHeader
            title="Rules History"
            subheader="A record including the outcome, each time the rule was evaluated"
          />
          <CardContent>
            <RuleHistoryTable rulesHistory={rulesHistory} />
          </CardContent>
        </Card>
      )}
    </>
  );
}
