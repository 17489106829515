/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Subscription } from "../../../../interfaces/Subscription";
import { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../hooks/useStoreHook";

interface AssignLicenseProps {
  subscriptions: Subscription[];
  handleUpdateLicenses: (service_uuid: string) => void;
  hasLicense: (service_uuid: string) => boolean;
  getLicensesCount: (service_uuid: string) => Promise<number>;
  handleSubmit: () => void;
}
export default function AssignLicense({
  subscriptions,
  handleUpdateLicenses,
  hasLicense,
  getLicensesCount,
  handleSubmit,
}: Readonly<AssignLicenseProps>) {
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const [licenseCounts, setLicenseCounts] = useState<{ [key: string]: number }>(
    {},
  );

  const fetchLicensesCount = useCallback(async () => {
    const counts = await Promise.all(
      subscriptions?.map(async (subscription) => {
        const count = await getLicensesCount(subscription.service.uuid);
        return { [subscription.service.uuid]: count };
      }),
    );
    setLicenseCounts(Object.assign({}, ...counts));
  }, [subscriptions]);

  useEffect(() => {
    fetchLicensesCount();
  }, [subscriptions]);


  const getAvailableLicense = (subscription: Subscription): string => {
    const assignedLicenses = licenseCounts[subscription.service.uuid] ?? 0;
    const totalLicenses = subscription?.tier?.license_count ?? 0;
    return `${totalLicenses - assignedLicenses} / ${totalLicenses}`;
  };

  return (
    <Box my={2}>
      <Box component="h4">License Assignments</Box>
      <Grid container spacing={3} py={2}>
        {subscriptions?.map((subscription) => (
          <Grid key={subscription.uuid} item xs={12} sm={6} md={4} lg={4}>
            <FormControlLabel
              sx={{ alignItems: "flex-start" }}
              control={
                <Checkbox
                  checked={hasLicense(subscription.service.uuid)}
                  onChange={() =>
                    handleUpdateLicenses(subscription.service.uuid)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ alignItems: "flex-start" }}
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle1">
                    {subscription.service.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Available Licenses: {getAvailableLicense(subscription)}
                  </Typography>
                </Box>
              }
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} sm={6} md={3}>
          <LoadingButton
            loading={isLoading}
            variant={"contained"}
            sx={{ width: "100%" }}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant={"contained"}
            onClick={() => navigate(-1)}
            color="error"
            sx={{ width: "100%" }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
