import { Box, Grid } from "@mui/material";
import { CameraDefaultSettings } from "../../../../interfaces/Settings";

interface CameraDefaultSettingsDetailsCardProps {
  defaultSettingsDetails: CameraDefaultSettings;
}

export default function CameraDefaultSettingsDetailsCard({
  defaultSettingsDetails,
}: CameraDefaultSettingsDetailsCardProps) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="settings-name" item xs={12} sm={6}>
        <Box component={"h6"} mb={0}>
          Name
        </Box>
        <Box component={"p"} mt={1} mb={0}>
          {defaultSettingsDetails.name}
        </Box>
      </Grid>
      <Grid data-testid="settings-default-value" item xs={12} sm={6}>
        <Box component={"h6"} mb={0}>
          Default Value
        </Box>
        <Box component={"p"} mt={1} mb={0}>
          {JSON.stringify(defaultSettingsDetails.default_value)}
        </Box>
      </Grid>
      <Grid data-testid="settings-data-type" item xs={12} sm={6}>
        <Box component={"h6"} mb={0}>
          Data Type
        </Box>
        <Box component={"p"} mt={1}>
          {defaultSettingsDetails.data_type}
        </Box>
      </Grid>
      <Grid data-testid="settings-description" item xs={12} sm={6}>
        <Box component={"h6"} mb={0}>
          Description
        </Box>
        <Box component={"p"} mt={1}>
          {defaultSettingsDetails.description}
        </Box>
      </Grid>
    </Grid>
  );
}
