import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { showSnackbar } from "../../utilis/snackbar";
import ConfirmationPopup from "../ConfirmationPopup";
import { deleteTier } from "../../apis/v1/Services/Tiers";
import { TierResponse } from "../../interfaces/Tier";

interface DeleteTierProps {
  service_uuid: string;
  tier_uuid:string;
  tiers: TierResponse[];
  setTiers: React.Dispatch<TierResponse[]>;
}

export default function DeleteTier({
  service_uuid,
  tier_uuid,
  tiers,
  setTiers,
}: DeleteTierProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getTiersListWithoutDeleted = () => {
    return tiers.filter((tier) => tier.uuid !== tier_uuid);
  };

  const submitDeleteTier = () => {
    deleteTier(service_uuid , tier_uuid).then(() => {
      const tiersListWithoutDeleted = getTiersListWithoutDeleted();
      showSnackbar("Deleted Successfully", "success");
      setTiers(tiersListWithoutDeleted);
      setOpenDialog(false);
    });
  };
  return (
    <>
      <IconButton size="small" onClick={() => setOpenDialog(true)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Tier"
        dialogBody="Are you sure you want to delete this tier?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteTier()}
      />
    </>
  );
}
