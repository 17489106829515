import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function MUIBreadcrumb({ data }) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
      {data.stackList.map((item, index) => (
        <Link key={index} color="inherit" to={item.path} state={item.state}>
          {item?.name}
        </Link>
      ))}
      <Typography color="primary">{data?.current}</Typography>
    </Breadcrumbs>
  );
}
