import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";

export default function AllUsersFilter({ setFilters }) {
  const [showReset, setShowReset] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(null);
  const [firstName, setFirstName] = useState<string>(null);
  const [lastName, setLastName] = useState<string>(null);

  const handleFilter = () => {
    setShowReset(true);
    setFilters({
      email,
      first_name: firstName,
      last_name: lastName,
    });
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Email"
          id="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          size="small"
          fullWidth
          margin="normal"
          value={email || ''} 
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="First Name"
          id="first_name"
          name="first_name"
          onChange={(e) => setFirstName(e.target.value)}
          size="small"
          fullWidth
          margin="normal"
          value={firstName || ''}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Last Name"
          id="last_name"
          name="last_name"
          onChange={(e) => setLastName(e.target.value)}
          size="small"
          fullWidth
          margin="normal"
          value={lastName || ''}
        />
      </Grid>
      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={6}
        md={3}
      >
        <Button variant="outlined" data-testid="filter-btn" onClick={handleFilter}>
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setFilters({
                email: null,
                first_name: null,
                last_name: null,
              });
              setShowReset(false);
              setEmail(null);
              setFirstName(null);
              setLastName(null);
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}