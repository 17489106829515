/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { Consumable } from "../../interfaces/Consumable";
import { getConsumableDetails } from "../../apis/v1/Services/Consumables";
import ConsumableDetailsCard from "../../components/Consumables/ConsumableDetailsCard";
import ConsumablesAddonsList from "../ConsumablesAddons/ConsumableAddonsList";

export default function ConsumableDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumable, setConsumable] = useState<Consumable>();

  useEffect(() => {
    getConsumableDetails(params.service_uuid, params.consumable_uuid).then(
      (res) => setConsumable(res.data),
    );
  }, []);

  return (
    <>
      {consumable && (
        <Card variant="outlined">
          <CardHeader
            title="Consumable Details"
            subheader="Details about this consumable"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${params.consumable_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <ConsumableDetailsCard consumable={consumable} />
          </CardContent>
        </Card>
      )}
      <ConsumablesAddonsList />
    </>
  );
}
