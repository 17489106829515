import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Formik } from "formik";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import MUISelect from "../Controls/Select";
import { resolution_types } from "../../utilis/enum";
import {
  IncidentCauseCreateArgs,
} from "../../interfaces/IncidentCause";
import { GIncidentClass } from "../../interfaces/IncidentClass";

interface AddIncidentCauseFormProps {
  handleSubmitForm: (values: IncidentCauseCreateArgs) => void;
  incident_classes: GIncidentClass[];
}

export default function AddIncidentCauseForm({
  handleSubmitForm,
  incident_classes,
}: Readonly<AddIncidentCauseFormProps>) {
  const navigate = useNavigate();
  const schema: SchemaOf<IncidentCauseCreateArgs> = yup.object().shape({
    text: yup.string().required("Required"),
    incident_class: yup.string().required("Required"),
    resolution_type_id: yup.number().required("Required"),
    order: yup.number().required("Required"),
    is_hidden: yup.boolean(),
  });
  const initialValues = {
    text: "",
    resolution_type_id: "",
    incident_class: "",
    order:  "",
    is_hidden: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitForm(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="text"
            label={"Name*"}
            fullWidth
            name="text"
            error={Boolean(errors.text && touched.text)}
            helperText={errors.text && touched.text && errors.text}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.text}
            size="small"
          />

          <MUISelect
            error={
              errors.incident_class &&
              touched.incident_class &&
              errors.incident_class
            }
            value={values.incident_class}
            label={"Incident class"}
            required
            name="incident_class"
            dataTestid={"incident_class"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            list={incident_classes}
            keyItem="name"
            keyValue="name"
            labelId={"incident_class"}
            id={"incident_class"}
            styles={{ mt: 3, mb: 1 }}
            renderValue={() =>
              incident_classes?.find(
                (incident_class : any) =>
                  incident_class.name === values.incident_class,
              )?.name
            }
          />

          <MUISelect
            error={
              errors.resolution_type_id &&
              touched.resolution_type_id &&
              errors.resolution_type_id
            }
            value={values.resolution_type_id}
            label={"Resolution type"}
            required
            name="resolution_type_id"
            dataTestid={"resolution_type_id"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            list={resolution_types}
            keyItem="id"
            keyValue="name"
            labelId={"resolution_type_id"}
            id={"resolution_type_id"}
            styles={{ mt: 2, mb: 1 }}
            renderValue={() =>
              resolution_types.find(
                (type) => type.id === values.resolution_type_id,
              )?.name
            }
          />

          <TextField
            type="number"
            id="order"
            label={"Order*"}
            fullWidth
            name="order"
            error={Boolean(errors.order && touched.order)}
            helperText={errors.order && touched.order && errors.order}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.order}
            size="small"
            sx={{ my: 2 }}
            inputProps={{
              min: 0,
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={values.is_hidden}
                name="is_hidden"
                onChange={handleChange}
              />
            }
            label="is hidden?"
          />

          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
