import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { deleteAdmin } from "../../../apis/v1/UserManagement";
import { User } from "../../../interfaces/User";
import { showSnackbar } from "../../../utilis/snackbar";
import ConfirmationPopup from "../../ConfirmationPopup";

interface DeteleAdminProps {
  id: string;
  admins: User[];
  setAdmins: React.Dispatch<User[]>;
}

export default function DeleteAdmin({
  id,
  admins,
  setAdmins,
}: DeteleAdminProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const getAdminsListWithoutDeleted = () => {
    return admins.filter((admin: User) => admin.id !== id);
  };

  const submitDeleteAdmin = () => {
    deleteAdmin(id).then(() => {
      const adminsListWithoutDeleted = getAdminsListWithoutDeleted();
      setAdmins(adminsListWithoutDeleted);
      setOpenDialog(false);
      showSnackbar("Deleted Successfully", "success");
    });
  };
  return (
    <>
      <IconButton
        size="small"
        data-testid="deleteBtn"
        onClick={() => setOpenDialog(true)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationPopup
        dialogTitle="Delete Admin"
        dialogBody="Are you sure you want to delete this admin?"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => submitDeleteAdmin()}
      />
    </>
  );
}
