import { Box, Grid, Tooltip } from "@mui/material";
import { template_attributes_types } from "../../../../utilis/templateAttributesFilter";
import AttributesTable from "../AttributesTable";
import { IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE } from "../../../../utilis/shared";
import {
  Attribute,
  CameraConnectionResponse,
} from "../../../../interfaces/Camera";

type Props = {
  cameraConnection: CameraConnectionResponse;
  attributes: Attribute[];
};
export default function DetailsCard({
  cameraConnection,
  attributes,
}: Readonly<Props>) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="cameraConnection-details" item xs={12}>
        <Box component={"h4"} mb={2}>
          Connection Details
        </Box>
      </Grid>
      <Grid data-testid="cameraConnection-name" item xs={12} sm={6} md={4}>
        <Box component={"h6"} my={0}>
          Name
        </Box>
        <Box component={"p"} mt={0} mb={1}>
          {cameraConnection.name}
        </Box>
      </Grid>
      <Grid data-testid="cameraConnection-isHttp" item xs={12} sm={6} md={4}>
        <Box component={"h6"} my={0}>
          Is Http ?
        </Box>
        <Box component={"p"} mt={0} mb={1}>
          {cameraConnection.is_http ? "Yes" : "No"}
        </Box>
      </Grid>
      <Grid data-testid="cameraConnection-isActive" item xs={12} sm={6} md={4}>
        <Tooltip
          title={IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE}
          placement={"top-start"}
        >
          <Box component={"h6"} my={0}>
            Is Active?
          </Box>
        </Tooltip>
        <Box component={"p"} mt={0} mb={1}>
          {cameraConnection.is_active ? "Yes" : "No"}
        </Box>
      </Grid>

      {cameraConnection.is_http && (
        <>
          <Grid data-testid="cameraConnection-httpDetails" item xs={12}>
            <Box component={"h4"} mb={2}>
              HTTP Details
            </Box>
          </Grid>

          <Grid data-testid="cameraConnection-templateURL" item xs={12}>
            <Box component={"h6"} my={0}>
              URL Template
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {cameraConnection.url_template}
            </Box>
          </Grid>
          <Grid data-testid="cameraConnection-templateURL" item xs={12}>
            <Box component={"h6"} my={0}>
              URL
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {cameraConnection.url ? cameraConnection.url : "-"}
            </Box>
          </Grid>
          <Grid
            data-testid="cameraConnection-authType"
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box component={"h6"} my={0}>
              Auth Type
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {cameraConnection.auth_type ? cameraConnection.auth_type : "None"}
            </Box>
          </Grid>
          <Grid
            data-testid="cameraConnection-username"
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box component={"h6"} my={0}>
              Username
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {cameraConnection.username || "-"}
            </Box>
          </Grid>
          <Grid
            data-testid="cameraConnection-password"
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box component={"h6"} my={0}>
              Password
            </Box>
            <Box component={"p"} mt={0} mb={1}>
              {cameraConnection.password || "-"}
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12}>
        <Box component={"h4"} mb={2}>
          Attributes
        </Box>
      </Grid>
      {template_attributes_types(cameraConnection.is_http).map(
        (attr_type: string, index) => (
          <Grid item xs={12} sm={12} key={index}>
            <Box component={"h5"} sx={{ textTransform: "capitalize" }} mt={0}>
              {attr_type.split("_").join(" ")}
            </Box>
            <AttributesTable
              attributes={attributes}
              attribute_type={attr_type}
            />
          </Grid>
        ),
      )}
    </Grid>
  );
}
