import {
  OrgStructureNode,
  OrgStructureNodePayload,
} from "../../interfaces/OrgStructure";
import { axiosInstance } from "../config/axiosInstance";
import { AxiosPromise } from "axios";

 const orgStructureBaseUrl = `/organisation-management/v1/companies`
function generateCompanyNodeURL(
  company_uuid: string,
  node_uuid?: string,
  node?: string,
): string {
 const companyOrgBaseURL = `${orgStructureBaseUrl}/${company_uuid}/org-structure`;
  if (node_uuid) {
    return `${companyOrgBaseURL}/${node}/${node_uuid}`;
  }
  return `${companyOrgBaseURL}/child-nodes`;
}
export const createCompanyChildNode = (
  company_uuid: string,
  data: OrgStructureNodePayload,
): AxiosPromise<OrgStructureNode> => {
  return axiosInstance.post(generateCompanyNodeURL(company_uuid), data);
};

export const getCompanyChildNode = (
  company_uuid: string,
  node_uuid: string,
): AxiosPromise<OrgStructureNode> => {
  return axiosInstance.get(
    generateCompanyNodeURL(company_uuid, node_uuid, "child-nodes"),
  );
};

export const editCompanyChildNode = (
  company_uuid: string,
  node_uuid: string,
  data: OrgStructureNodePayload,
): AxiosPromise<OrgStructureNode> => {
  return axiosInstance.patch(
    generateCompanyNodeURL(company_uuid, node_uuid, "child-nodes"),
    data,
  );
};

export const deleteCompanyChildNode = (
  company_uuid: string,
  node_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    generateCompanyNodeURL(company_uuid, node_uuid, "child-nodes"),
  );
};

export const getCompanyRootNodes = (
  company_uuid: string,
  node_uuid: string,
): AxiosPromise<OrgStructureNode> => {
  return axiosInstance.get(
    generateCompanyNodeURL(company_uuid, node_uuid, "root-nodes"),
  );
};

export const getCompanyNodes = (
  company_uuid: string,
  node_uuid: string,
): AxiosPromise<OrgStructureNode> => {
  return axiosInstance.get(
    generateCompanyNodeURL(company_uuid, node_uuid, "nodes"),
  );
};

export const getAllRootNodes = (): AxiosPromise<OrgStructureNode[]> => {
  return axiosInstance.get(`${orgStructureBaseUrl}/org-structure/root-nodes`);
};
