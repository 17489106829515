import { axiosInstance } from "../../config/axiosInstance";
import {
  DefaultMessage,
  RuleIntegration,
  RuleIntegrationPayload,
} from "../../../interfaces/Notifications";
import { AxiosPromise } from "axios";

const notificationsBase = `/notification/v1`;

const generateRuleIntegrationUrl = (company_uuid: string, rule_uuid: string) =>
  `${notificationsBase}/companies/${company_uuid}/rules/${rule_uuid}/integrations`;

export const getRuleIntegrations = (
  company_uuid: string,
  rule_uuid: string,
): AxiosPromise<RuleIntegration[]> => {
  return axiosInstance.get(generateRuleIntegrationUrl(company_uuid, rule_uuid));
};

export const getRuleIntegration = (
  company_uuid: string,
  rule_uuid: string,
  integration_uuid: string,
): AxiosPromise<RuleIntegration> => {
  return axiosInstance.get(
    `${generateRuleIntegrationUrl(company_uuid, rule_uuid)}/${integration_uuid}`,
  );
};

export const addRuleIntegration = (
  company_uuid: string,
  rule_uuid: string,
  data: RuleIntegrationPayload,
): AxiosPromise<RuleIntegration> => {
  return axiosInstance.post(
    generateRuleIntegrationUrl(company_uuid, rule_uuid),
    data,
  );
};

export const editRuleIntegration = (
  company_uuid: string,
  rule_uuid: string,
  integration_uuid: string,
  data: RuleIntegrationPayload,
): AxiosPromise<RuleIntegration> => {
  return axiosInstance.patch(
    `${generateRuleIntegrationUrl(company_uuid, rule_uuid)}/${integration_uuid}`,
    data,
  );
};

export const deleteRuleIntegration = (
  company_uuid: string,
  rule_uuid: string,
  integration_uuid: string,
): AxiosPromise<void> => {
  return axiosInstance.delete(
    `${generateRuleIntegrationUrl(company_uuid, rule_uuid)}/${integration_uuid}`,
  );
};

export const getDefaultMessageKeywords = (
  rule_type: string,
): AxiosPromise<string[]> => {
  return axiosInstance.get(`${notificationsBase}/default-message-keywords`, {
    params: {
      rule_type,
    },
  });
};

export const getDefaultMessage = (
  rule_type: string,
): AxiosPromise<DefaultMessage> => {
  return axiosInstance.get(`${notificationsBase}/default-messages`, {
    params: {
      rule_type,
    },
  });
};
